import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {getJsonResponse} from "./fetchUtils";
import {ResponseDTO, UserDTO} from "../api/swagger/api";
import {RootState} from "../store/Store";

// TODO: use taskStatuses

export interface ProfileState {
    profile: UserDTO | null;
    loading: boolean,
    hasErrors: boolean,
}

const initialState: ProfileState = {
    profile: null,
    loading: false,
    hasErrors: false,
};

const endPoint = EndPoints.users;

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setGetProfileResult: (state, action: PayloadAction<UserDTO | null>) => {
            state.profile = action.payload;
            state.loading = false
            state.hasErrors = false
        },
        getProfileLoading: state => {
            state.loading = true
            state.hasErrors = false
        },
        getProfileFailure: state => {
            state.loading = false
            state.hasErrors = true
        },
    },
});

export const {
    setGetProfileResult,
    getProfileLoading,
    getProfileFailure,
} = profileSlice.actions;

export const getProfileAction =
    () => async (dispatch: any) => {
        dispatch(getProfileLoading())
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem("userId")
        fetch(`${endPoint}/${userId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then(response => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                dispatch(setGetProfileResult(response.resultValue as UserDTO));
            })
            .catch(err => {
                dispatch(getProfileFailure())
            });
    };

export const profileSelector = (state: RootState) => state.profile.profile;

export const profileLoading = (state: RootState) => state.profile.loading;

export default profileSlice.reducer;
