import {logoutAction} from "./authSlice";

export const getJsonResponse = (response: any, dispatch: any) => {
    if (response.status === 401 || response.status === 403) {
        dispatch(logoutAction());
    }
    return response.json();
};

export const handleApiError = (err: any, setisLoading: Function) => {
    if (err.toString().includes('timeout')) {
        alert('İşlem süresi aşıldı. Lütfen tekrar deneyiniz.');
    }
    console.warn('Fetch operation failed', JSON.stringify(err));
    setisLoading(false);
};

export const showFetchErrorAlert = (setisLoading: Function) => {
    setisLoading(false);
    alert('İşlem süresi aşıldı. Lütfen tekrar deneyiniz.');
};
