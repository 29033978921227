import React, {useEffect, useRef, useState} from 'react';
import {classNames} from 'primereact/utils';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Toast} from 'primereact/toast';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {IUserViewModel} from "../../api/ViewModels/UserViewModel";
import {useDispatch, useSelector} from "react-redux";
import {getAllUsersAction, pageUserObjectSelector} from "../../slices/userSlice";
import {registerTaskStatusSelector, signupAction} from "../../slices/authSlice";
import {CircularProgress} from "@mui/material";
import {SignupRequestDTO, UserDTO} from "../../api/swagger/api";
import {getProfileAction, profileSelector} from "../../slices/profileSlice";
import {NewUserDialog} from './NewUserDialog';
import {Ripple} from "primereact/ripple";
import {
    PaginatorCurrentPageReportOptions,
    PaginatorNextPageLinkOptions,
    PaginatorPageLinksOptions,
    PaginatorPrevPageLinkOptions,
    PaginatorTemplate
} from "primereact/paginator";
import {AsyncTaskStatusType} from "../../shared/dtos";
import default_man from "../../assets/default_man.png";
import {EditUserDialog} from "./EditUserDialog";
import {LockUnlockUserDialog} from "./LockUnlockUserDialog";
import {ChangePasswordDialog} from "./ChangePasswordDialog";

const MASTER_ADMIN_USERNAME = "AfiderAdmin";

export const UserManagement = () => {
    const dispatch: any = useDispatch();
    const pageUserObject = useSelector(pageUserObjectSelector);
    const [newUserDialog, setNewUserDialog] = useState(false);
    const [editedUser, setEditedUser] = useState<UserDTO | null>(null);
    const [lockedUnlockedUser, setLockedUnlockedUser] = useState<UserDTO | null>(null);
    const toast = useRef<Toast>(null);
    const dt = useRef<DataTable<any>>(null);
    const registerLoading = useSelector(registerTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const self = useSelector(profileSelector);
    const [changedPasswordUser, setChangedPasswordUser] = useState<UserDTO | null>(null);
    const defaultPageNumber = 1;
    const [first, setFirst] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState('Bu sayfaya gitmek için \'Enter\' tuşuna basın.');

    useEffect(() => {
        dispatch(getProfileAction())
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllUsersAction(defaultPageNumber));
    }, [dispatch]);

    const openChangePasswordDialog = (user: UserDTO) => {
        setChangedPasswordUser(user);
    }

    const hideChangePasswordDialog = () => {
        setChangedPasswordUser(null);
    }

    const hideNewUserDialog = () => {
        setNewUserDialog(false);
    }

    const hideEditUserDialog = () => setEditedUser(null);

    const hideLockUserDialog = () => setLockedUnlockedUser(null);

    const getAllUserCallback = (pageNumber?: number) => {
        if (pageNumber) {
            dispatch(getAllUsersAction(pageNumber))
        } else {
            dispatch(getAllUsersAction(currentPage));
        }
    }

    const onSubmit = (data: SignupRequestDTO) => {
        dispatch(signupAction(data, (message, id) => {
            if (id) {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Başarılı',
                    detail: message,
                    life: 3000
                });
                hideNewUserDialog();
            } else {
                toast.current?.show({
                    severity: 'error',
                    summary: 'Hata',
                    detail: message,
                    life: 3000
                });
            }

            getAllUserCallback();
        }));
    };

    const openNewUserDialog = () => {
        setNewUserDialog(true);
    }

    const openEditUserDialog = (user: UserDTO) => {
        setEditedUser(user);
    }

    const exportCSV = () => {
        dt.current?.exportCSV({selectionOnly: false});
    }

    const imageBodyTemplate = (rowData: IUserViewModel) => {
        return <img alt="default man" src={rowData.image || default_man} width={50}/>
    }

    const actionBodyTemplate = (rowData: UserDTO) => {
        const isAfider = rowData.username === MASTER_ADMIN_USERNAME;
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2"
                        onClick={() => openEditUserDialog(rowData)}
                        disabled={(self?.id !== rowData?.id) && isAfider}/>
                <Button icon="pi pi-lock" className="p-button-rounded p-button-danger mr-2"
                        disabled={(self?.id === rowData?.id) || isAfider}
                        onClick={() => setLockedUnlockedUser(rowData)}/>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-warning"
                        onClick={() => openChangePasswordDialog(rowData)}
                        disabled={(self?.id === rowData?.id) || isAfider}/>
            </React.Fragment>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-end">
            <div className="mt-3 md:mt-0 flex justify-content-end">
                <Button icon="pi pi-plus" className="mr-2 p-button-rounded" onClick={openNewUserDialog} tooltip="New"
                        tooltipOptions={{position: 'bottom'}}/>
                <Button icon="pi pi-upload" className="p-button-help p-button-rounded" onClick={exportCSV}
                        tooltip="Export" tooltipOptions={{position: 'bottom'}}/>
            </div>
        </div>
    );

    const paginatorTemplate: PaginatorTemplate | any = {
        layout: 'PrevPageLink PageLinks NextPageLink CurrentPageReport',
        'PrevPageLink': (options: PaginatorPrevPageLinkOptions) => {
            return (
                <button type="button" {...options}>
                    <span className="p-3">Önceki</span>
                    <Ripple/>
                </button>
            )
        },
        'NextPageLink': (options: PaginatorNextPageLinkOptions) => {
            return (
                <button type="button" {...options}>
                    <span className="p-3">Sonraki</span>
                    <Ripple/>
                </button>
            )
        },
        'PageLinks': (options: PaginatorPageLinksOptions) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0) || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, {'p-disabled': true});

                return <span className={className} style={{userSelect: 'none'}}>...</span>;
            }

            return (
                <button type="button" className={options.className} onClick={options.onClick}>
                    {options.page + 1}
                    <Ripple/>
                </button>
            )
        },
        'CurrentPageReport': (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span className="mx-3" style={{color: 'var(--text-color)', userSelect: 'none'}}>
                    Git <InputText size={pageUserObject?.totalNumberPages} className="ml-1"
                                   value={currentPage.toString()}
                                   tooltip={pageInputTooltip}
                                   onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange}/>
                </span>
            )
        }
    };

    const onCustomPage = (event: any) => {
        setFirst(event.first);
        setCurrentPage(event.page + 1);
        getAllUserCallback(event.page + 1)
    }

    const onPageInputChange = (event: any) => {
        setCurrentPage(event.target.value);
    }

    const onPageInputKeyDown = (event: any, options: any) => {
        if (event.key === 'Enter') {
            const pageNumber = currentPage;
            if (pageNumber < 1 || pageNumber > options.totalPages) {
                setPageInputTooltip(`Değer 1 ile ${options.totalPages} arasında olmalıdır.`);
            } else {
                const first = currentPage ? options.rows * (pageNumber - 1) : 0;
                setFirst(first);
                setPageInputTooltip('Bu sayfaya gitmek için \'Enter\' tuşuna basın.');
                getAllUserCallback(pageNumber);
            }
        }
    }

    function renderDataTable() {
        return (
            <DataTable ref={dt}
                       value={pageUserObject?.entity}
                       paginator
                       paginatorTemplate={paginatorTemplate}
                       first={first}
                       rows={6}
                       lazy={true}
                       totalRecords={pageUserObject?.totalElements}
                       onPage={onCustomPage}
                       dataKey="id"
                       currentPageReportTemplate=" {totalRecords} kayıttan {first} - {last} arası gösteriliyor"
                       header={header}
                       emptyMessage='Şablonlar bulunamadı.'>
                <Column field="firstName" header="Ad" style={{minWidth: '16rem'}}/>
                <Column field="surname" header="Soyad" style={{minWidth: '16rem'}}/>
                <Column field="username" header="Kullanıcı adı" style={{minWidth: '16rem'}}/>
                <Column field="email" header="E-Posta" style={{minWidth: '16rem'}}/>
                <Column field="image" header="Image" body={imageBodyTemplate}/>
                <Column field="role" header="Role" style={{minWidth: '10rem'}}/>
                <Column body={actionBodyTemplate} exportable={false} style={{minWidth: '8rem'}}/>
            </DataTable>
        );
    }

    const renderPopups = () => {
        if (editedUser) {
            return (
                <EditUserDialog currentPage={currentPage}
                                user={editedUser}
                                hideEditUserDialog={hideEditUserDialog}/>
            );
        }
        if (newUserDialog) {
            return (
                <NewUserDialog hideNewUserDialog={hideNewUserDialog}
                               onSubmit={onSubmit}/>
            );
        }
        if (changedPasswordUser) {
            return (
                <ChangePasswordDialog userId={changedPasswordUser.id}
                                      hideChangePasswordDialog={hideChangePasswordDialog}/>
            );
        }
        if (lockedUnlockedUser) {
            return (
                <LockUnlockUserDialog lockedUnlockedUser={lockedUnlockedUser}
                                      hideLockUserDialog={hideLockUserDialog}/>
            )
        }
    }

    return (
        <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
            {registerLoading && <CircularProgress color="success"/>}
            <Toast ref={toast}/>
            <Container style={{justifyContent: 'center'}}>
                <Box pb={5} style={{background: 'white'}}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Kullanıcı Yönetimi
                    </Typography>
                    <Divider/>
                </Box>
            </Container>
            {renderDataTable()}
            {renderPopups()}
        </div>
    );
}
