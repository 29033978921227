import React from 'react';
import {useNavigate} from 'react-router-dom';

export const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirect = () => navigate("/");

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>404 - Sayfa Bulunamadı</h2>
            <p style={styles.message}>Aradığınız sayfa maalesef bulunamadı.</p>
            <button style={styles.button} onClick={handleRedirect}>Anasayfa'ya Git</button>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center' as 'center',
        marginTop: '50px',
    },
    title: {
        fontSize: '24px',
        color: '#FF5733',
    },
    message: {
        fontSize: '16px',
        margin: '10px 0',
    },
    button: {
        padding: '10px 20px',
        fontSize: '18px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
};
