import React from 'react';

interface PrivacyProps {
    lang: string;
}

export const Privacy = ({lang}: PrivacyProps) => {
    const appName = "AFİDER";
    return lang === "tr"
        ? (
            <div className="aydinlatma-metni-container">
                <h1>{appName} MOBİL UYGULAMASI</h1>
                <h2>KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ</h2>
                <p>
                    ANKARA FENERBAHÇELİ İŞ İNSANLARI DERNEĞİ (“{appName}”) olarak {appName} mobil uygulaması faaliyetinde
                    kişisel verilerinizin korunmasına ve güvenliğine azami özen göstermekteyiz. Bu kapsamda, başta özel
                    hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verilerin
                    korunması amacıyla düzenlenen 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun”) ve ilgili
                    mevzuat uyarınca kişisel verilerinizin toplanma şekilleri, işlenme amaçları, işlemenin hukuki
                    nedenleri ve haklarınız konularında Veri Sorumlusu sıfatıyla sizleri en şeffaf şekilde aydınlatmak
                    ve bilgilendirmek isteriz.
                </p>
                <h3>I. Kişisel Verilerinizin Toplanma Yöntemleri</h3>
                <p>
                    Kişisel verileriniz, {appName} tarafından verilen {appName} mobil uygulamasında kullanılmak üzere
                    değişkenlik gösterebilmekle; otomatik yöntemle, dernek merkezi, sosyal medya mecraları, mobil
                    uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanmaktadır. Kişisel
                    verileriniz, {appName} olarak faaliyetlerimizi icra ederken; {appName} tarafından farklı kanallarla
                    mevzuata, politikalarımıza ve üstlenilen yükümlülüklere eksiksiz bir şekilde uyum sağlanabilmesi
                    amacıyla toplanmaktadır.
                </p>
                <p>
                    Kişisel verileriniz, {appName} tarafından verilen {appName} mobil uygulamasında kullanılmak üzere
                    değişkenlik gösterebilmekle; otomatik yöntemle, dernek merkezi, sosyal medya mecraları, mobil
                    uygulamalar ve benzeri vasıtalarla sözlü, yazılı ya da elektronik olarak toplanmaktadır.
                </p>
                <p>
                    Kişisel verileriniz, {appName} olarak faaliyetlerimizi icra ederken; {appName} tarafından farklı
                    kanallarla mevzuata, politikalarımıza ve üstlenilen yükümlülüklere eksiksiz bir şekilde uyum
                    sağlanabilmesi amacıyla toplanmaktadır.
                </p>
                <h3>II. Kişisel Verilerinizin İşlenmesi, İşlenme Amacı ve Hukuki Sebebi</h3>
                <p>
                    {appName}’in sunmuş olduğu {appName} mobil uygulamasından faydalanmanız sırasında aşağıda detaylı şekilde
                    açıklanan kişisel verileriniz, Kanunun 4. maddesinde öngörülen temel ilkelere uygun olarak,
                    faaliyetlerimizin yürütülebilmesi ve hukuki yükümlülüklerin yerine getirilmesi başta olmak üzere
                    Kanun’un 5. ve 6. maddelerinde belirtilen kişisel veri işleme şartları ve öngörülen düzenlemeler
                    kapsamında işlenebilmekte ve aktarılabilmektedir.
                </p>
                <h4>Genel Nitelikli Kişisel Veriler, Amaçları ve Hukuki Sebepleri:</h4>
                <p>
                    {appName} mobil uygulamamızda aşağıdaki kişisel verileriniz üye kaydınızın oluşturulması, uygulamayı
                    kullanabilmeniz, uygulamayı kullanırken yaşanabilecek sorunlara karşı çözüm üretilebilmesi,
                    uygulamanın sağlıklı ve amacına uygun çalışabilmesi ve gerektiğinde yetkili kurum ve kuruluşlara
                    bilgi verilebilmesi için işlenmektedir:
                </p>
                <p>
                    Bu alanda yer alan kişisel verileriniz tamamı bir sözleşmenin kurulması veya ifasıyla doğrudan
                    doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli
                    olması, Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması, İlgili
                    kişinin kendisi tarafından alenileştirilmiş olması, Bir hakkın tesisi, kullanılması veya korunması
                    için veri işlemenin zorunlu olması,İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                    kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki
                    sebeplerine dayanılarak işlenmektedir.
                </p>
                <h4>Özel Nitelikli Kişisel Veriler, Amaçları ve Hukuki Sebepleri:</h4>
                <p>
                    {appName} mobil uygulamamızda kan grubu özel nitelikli kişisel veriniz uygulamamızı kullanırken
                    uygulama panelinde yer alan ve yalnızca üyelerin birbirleri için kan ihtiyacı bulunması halinde
                    üyelerin birbirleriyle etkileşimini sağlayarak dayanışma faaliyetinin yürütülebilmesi amacıyla
                    işlenmektedir. Kan grubu özel nitelikli kişisel veriniz açık rızaya tabi olarak işlenmektedir. Bu
                    nedenle açık rıza vermediğiniz takdirde kan grubu veriniz işlenmeyecektir.
                </p>
                <h3>III. Kişisel Verilerinizin Aktarılması</h3>
                <p>
                    İşlenen kişisel verileriniz; Kanun tarafından öngörülen temel ilkelere uygun olarak ve Kanun’un 8.
                    ve 9. maddelerinde belirtilen şart ve amaçlar dâhilinde, mevzuat ve politikalarımıza uygun olarak
                    faaliyetlerimizi yürütmek, {appName} tarafından sunulan ürün ve hizmetlerden sizleri faydalandırmak
                    için gerekli çalışmaların yapılması, {appName} ile iş ilişkisi içerisinde olan kişilerin güvenliğinin
                    temini, Derneğimiz yönetim kurulunca oluşturulacak komite, mobil uygulamanın sağlayıcısı olarak
                    yazılım şirketi, özel hukuk gerçek ve tüzel kişileri, gerekli olması halinde kamu kurum ve
                    kuruluşları, hukuki işlerin takibi amacıyla avukatlar veya avukatlık ortaklıklarına ve ayrıca yurt
                    dışında bulunan sunucu sağlayıcısına aktarılabilecektir. Kişisel veriler , bir sözleşmenin kurulması
                    veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                    işlenmesinin gerekli olması, Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için
                    zorunlu olması, İlgili kişinin kendisi tarafından alenileştirilmiş olması, Bir hakkın tesisi,
                    kullanılması veya korunması için veri işlemenin zorunlu olması,İlgili kişinin temel hak ve
                    özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
                    zorunlu olması hukuki sebeplerine dayanılarak aktarılmaktadır. Kişisel verilerinizin yurt dışına
                    aktarılabilmesi için açık rıza vermeniz gerekmektedir. Bunların dışında rıza göstermeniz halinde kan
                    grubu verilerinizin işlenmesi durumunda bu veriniz de yukarıda sayılan aktarım gruplarına
                    aktarılabilecektir.
                </p>
                <h3>IV. Kişisel Verilerin İşlenme Süresi ve Güvenliği</h3>
                <p>
                    {appName}, kişisel verileri işbu Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni’nde belirtilen
                    amaçların sona ermesine kadar saklayacaktır. Buna ek olarak, {appName}, veri sahibi ile arasında
                    doğabilecek herhangi bir uyuşmazlık durumunda, uyuşmazlık kapsamında gerekli savunmaların
                    gerçekleştirilebilmesi amacıyla sınırlı olmak üzere ve ilgili mevzuat uyarınca belirlenen zamanaşımı
                    süreleri boyunca kişisel verileri saklayabilecektir.
                </p>
                <p>
                    Kişisel verileriniz, amaçla sınırlılık ilkesinin bir gereği olarak işbu Aydınlatma Metninde
                    açıklanan amaçların yerine getirilmesi ve her halükârda Derneğimizin mobil uygulaması uyarınca
                    işlenmesini gerektiren süre ile sınırlı olarak işlenmekte, sürelerin dolması ardından ise
                    silinmekte, yok edilmekte veya anonim hale getirilmektedir. İmha işlemleri ise tutanak altına
                    alınmaktadır.
                </p>
                <p>
                    {appName}, kişisel verilerin hukuka aykırı olarak işlenmemesini, kişisel verilere hukuka aykırı olarak
                    erişilmemesini ve kişisel verilerin muhafazasını sağlamak amacıyla uygun güvenlik düzeyini temin
                    etmeye yönelik gerekli teknik ve idari tedbirleri almayı, gerekli denetimleri yaptırmayı taahhüt
                    eder. {appName} mobil uygulama ile elde ettiği kişisel verileri işbu Kişisel Verilerin İşlenmesine
                    İlişkin Aydınlatma Metni ile ilgili yasal mevzuata aykırı olarak başkasına açıklamayacak ve işleme
                    amacı dışında
                </p>
                <p>
                    {appName} mobil uygulamasına üye olmak için derneğimize başvurduğunuzda tarafınıza tek kullanımlık bir
                    şifre verilmektedir. Mobil uygulamaya giriş yaptığınızda bu şifreyi değiştirmeyi unutmamalısınız.
                    {appName} mobil uygulamasında oluşturduğunuz şifre kendinize özel olup hiç kimse ile paylaşmamalısınız.
                </p>
                <p>
                    Verilerinizin işlendiği {appName} mobil uygulamasında şifreleme yöntemi dışında log kayıtları
                    tutulmakta, Ağ güvenliği ve uygulama güvenliği sağlanmakta ve kişisel veri güvenliğinin takibi
                    yapılarak kişisel veri güvenliği sorunları hızlı bir şekilde raporlanmaktadır. {appName} mobil
                    uygulamasında veri güvenliğinin artırılması amacıyla düzenli kontroller yapılmakta olduğunu
                    belirtmek isteriz.
                </p>
                <h3>V. Veri Sahibi Olarak Kanun Kapsamındaki Haklarınız</h3>
                <p>
                    Kanunun 11. maddesi uyarınca kişisel verilerinizin işlenip işlenmediğini öğrenme, kişisel
                    verileriniz işlenmişse buna ilişkin bilgi talep etme, kişisel verilerinizin işlenme amacını ve
                    bunların amacına uygun kullanılıp kullanılmadığını öğrenme, yurt içinde veya yurt dışında kişisel
                    verilerin aktarıldığı üçüncü kişileri bilme, kişisel verilerin eksik veya yanlış işlenmiş olması
                    halinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
                    üçüncü kişilere bildirilmesini isteme, kanun ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
                    olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması halinde kişisel verilerin
                    silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı
                    üçüncü kişilere bildirilmesini isteme, işlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                    analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme, kişisel
                    verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini
                    talep etme haklarına sahipsiniz.
                </p>
                <p>
                    Söz konusu haklarınıza ilişkin taleplerinizi, kişisel veri sahipleri olarak veri sahibi başvuru
                    formumuzu doldurarak veya yazılı bir şekilde “Turan Güneş Bulvarı No: 102/5 Çankaya / ANKARA”
                    adresine yazılı olarak, fb@afider.org.tr adresine güvenli elektronik imza, mobil imza ya da varsa
                    {appName}’e daha önceden bildirdiğiniz ve {appName} mobil uygulamasında kayıtlı bulunan elektronik posta
                    adresi aracılığı ile fb@afider.org.tr adresine iletilmesi halinde 30 (otuz) gün içerisinde
                    değerlendirilerek sonuçlandırılacaktır. Veri Sahibi tarafından iletilen taleplerde, veri sahibinin
                    adı, soyadı, başvuru yazılı ise imzası, TC kimlik numarası ya da yabancı ise uyruğu, pasaport
                    numarası veya varsa kimlik numarası, tebligata esas yerleşim yeri veya iş adresi, varsa bildirime
                    esas elektronik posta adresi, telefon ve faks numarası ve talep konusu bulunması zorludur. Başvuruya
                    verilecek yanıtın CD, flash bellek gibi bir kayıt ortamında verilmesi halinde ise {appName}, talepte
                    bulunan Veri Sahibi’nden kayıt ortamının maliyeti tutarında ücret talep edebilir.
                </p>
                <p className="contact">
                    ANKARA FENERBAHÇELİ İŞ İNSANLARI DERNEĞİ
                    <br/>
                    Turan Güneş Bulvarı No: 102/5 Çankaya / ANKARA
                    <br/>
                    0312 312 1907
                    <br/>
                    fb@afider.org.tr
                    <br/>
                    <a href="http://www.afider.org.tr" target="_blank" rel="noopener noreferrer">
                        www.afider.org.tr
                    </a>
                </p>
            </div>
        )
        : (
            <div className="aydinlatma-metni-container">
                <h1>{appName} MOBILE APPLICATION</h1>
                <h2>PRIVACY NOTICE REGARDING THE PROTECTION OF PERSONAL DATA</h2>
                <p>
                    As ANKARA FENERBAHÇE BUSINESSMEN ASSOCIATION ("{appName}"), we take utmost care for the protection and
                    security of your personal data in our {appName} mobile application activities. In this context, we
                    would like to inform and enlighten you transparently about the collection methods, processing
                    purposes, legal grounds for processing, and your rights regarding your personal data in accordance
                    with the Law on Protection of Personal Data No. 6698 ("Law") and related regulations, aiming to
                    protect fundamental rights and freedoms, especially the privacy of private life.
                </p>
                <h3>I. Methods of Collecting Your Personal Data</h3>
                <p>
                    Your personal data may vary in the {appName} mobile application provided by {appName}, and it is collected
                    automatically, verbally, in writing, or electronically through channels such as the association
                    center, social media platforms, mobile applications, and similar tools. Your personal data is
                    collected by {appName} through different channels to ensure full compliance with the legislation, our
                    policies, and the obligations undertaken, allowing us to carry out our activities effectively.
                </p>
                <p>
                    Your personal data, which may vary, is collected for use in the {appName} mobile application provided
                    by {appName}; through automatic methods, the association center, social media platforms, mobile
                    applications, and similar means, either verbally, in writing, or electronically.
                </p>
                <p>
                    Your personal data is collected by {appName} through various channels while carrying out our
                    activities; to ensure full compliance with legislation, our policies, and the obligations undertaken
                    by {appName}.
                </p>
                <h3>II. Processing of Your Personal Data, Purpose of Processing, and Legal Basis</h3>
                <p>
                    Your detailed personal data, as described below, may be processed and transferred in accordance with
                    the fundamental principles stipulated in Article 4 of the Law, and the conditions for processing
                    personal data and the regulations specified in Articles 5 and 6 of the Law, especially for the
                    execution of our activities and the fulfillment of legal obligations, during your use of the {appName}
                    mobile application provided by {appName}.
                </p>
                <h4>General Qualified Personal Data, Purposes, and Legal Reasons:</h4>
                <p>
                    The following personal data in our {appName} mobile application is processed for the creation of your
                    membership record, enabling you to use the application, providing solutions to issues that may arise
                    while using the application, ensuring the healthy and purposeful operation of the application, and,
                    if necessary, providing information to authorized institutions and organizations:
                </p>
                <p>
                    All personal data in this field is processed based on legal reasons such as the necessity of
                    processing personal data belonging to the parties of a contract directly related to the
                    establishment or performance of a contract, the obligation of the Data Controller to fulfill legal
                    obligations, the data subject having made the data public, the necessity of data processing for the
                    establishment, exercise, or protection of a right, and the necessity of data processing for the
                    legitimate interests of the Data Controller, provided that it does not harm the fundamental rights
                    and freedoms of the data subject.
                </p>
                <h4>Special Categories of Personal Data, Purposes, and Legal Reasons:</h4>
                <p>
                    Your blood type, which is a special category of personal data, is processed in our {appName} mobile
                    application with the aim of facilitating the interaction between members for mutual support
                    activities, only when there is a need for blood among members and is accessible through the
                    application panel. The processing of your blood type, which is considered sensitive personal data,
                    is subject to explicit consent. Therefore, if you do not provide explicit consent, your blood type
                    data will not be processed.
                </p>
                <h3>III. Transfer of Your Personal Data</h3>
                <p>
                    Your processed personal data may be transferred, in accordance with the fundamental principles
                    stipulated by the Law and the conditions and purposes specified in Articles 8 and 9 of the Law, for
                    the conduct of our activities in compliance with legislation and our policies, conducting necessary
                    studies to provide you with the products and services offered by {appName}, ensuring the security of
                    individuals in a business relationship with {appName}, forming a committee by the board of directors of
                    our association, the software company as the provider of the mobile application, private legal
                    entities, and individuals, if necessary, to public institutions and organizations, and to lawyers or
                    law partnerships for the pursuit of legal affairs. Additionally, it may be transferred to a server
                    provider located abroad. Personal data is transferred based on legal reasons such as the necessity
                    of processing personal data belonging to the parties of a contract directly related to the
                    establishment or performance of a contract, the obligation of the Data Controller to fulfill legal
                    obligations, the data subject having made the data public, the necessity of data processing for the
                    establishment, exercise, or protection of a right, and the necessity of data processing for the
                    legitimate interests of the Data Controller, provided that it does not harm the fundamental rights
                    and freedoms of the data subject. Your explicit consent is required for the transfer of your
                    personal data abroad. In addition, if you provide consent, your blood type data may be transferred
                    to the aforementioned transfer groups in the case of processing.
                </p>
                <h3>IV. Processing Period and Security of Personal Data</h3>
                <p>
                    {appName} will store personal data until the purposes specified in this Information Text on the
                    Processing of Personal Data are fulfilled. In addition, in the event of any dispute that may arise
                    between {appName} and the data subject, personal data may be stored for a limited time and within the
                    statute of limitations period determined by the relevant legislation, solely for the purpose of
                    conducting necessary defenses within the scope of the dispute.
                </p>
                <p>
                    Your personal data is processed for the period required to fulfill the purposes stated in this
                    Information Text and, in any case, within the limited time frame required for processing by the
                    {appName} mobile application, in accordance with the principle of purpose limitation. After the
                    expiration of these periods, the data is deleted, destroyed, or anonymized. Destruction processes
                    are recorded.
                </p>
                <p>
                    {appName} undertakes to take the necessary technical and administrative measures and to carry out the
                    necessary audits to ensure an appropriate level of security to prevent the unlawful processing of
                    personal data, unauthorized access to personal data, and the preservation of personal data. {appName}
                    commits not to disclose the personal data obtained through the mobile application to others in
                    violation of the relevant legal regulations and for purposes other than processing.
                </p>
                <p>
                    When you apply to become a member of the {appName} mobile application, you are provided with a one-time
                    password. Remember to change this password when you log in to the mobile application. The password
                    you create in the {appName} mobile application is personal to you, and you should not share it with
                    anyone.
                </p>
                <p>
                    In the {appName} mobile application, where your data is processed, log records are kept in addition to
                    encryption methods, network security, and application security are ensured, and the monitoring of
                    personal data security is conducted to promptly report any personal data security issues. We would
                    like to emphasize that regular checks are carried out to enhance data security in the {appName} mobile
                    application.
                </p>
                <h3>V. Your Rights as a Data Subject under the Law</h3>
                <p>
                    According to Article 11 of the Law, you have the right to learn whether your personal data is
                    processed, request information if your personal data is processed, learn the purpose of processing
                    your personal data and whether they are used in accordance with this purpose, know the third parties
                    to whom your personal data is transferred domestically or abroad, request correction if your
                    personal data is incomplete or incorrectly processed, request the deletion or destruction of your
                    personal data in case the reasons requiring processing cease to exist, object to the occurrence of a
                    result against you through the analysis of your personal data solely by automatic systems, and
                    demand compensation if you suffer damage due to the illegal processing of personal data.
                </p>
                <p>
                    You can submit your requests related to these rights by filling out our Data Subject Application
                    Form or in writing to "Turan Güneş Boulevard No: 102/5 Çankaya / ANKARA" or securely via electronic
                    signature to fb@afider.org.tr, or via the electronic mail address registered in the {appName} mobile
                    application if previously notified to {appName}. Requests submitted by the Data Subject must include
                    the name, surname, signature if the application is in writing, Turkish ID number, or nationality if
                    foreign, passport number or ID number if available, the main residence or business address for
                    notification, the electronic mail address used for notification if available, and phone and fax
                    numbers. If the response to the application is to be provided on a recording medium such as a CD or
                    flash drive, {appName} may request a fee from the Data Subject equal to the cost of the recording
                    medium.
                </p>
                <p className="contact">
                    ANKARA FENERBAHÇE BUSINESSMEN ASSOCIATION
                    <br/>
                    Turan Güneş Boulevard No: 102/5 Çankaya / ANKARA
                    <br/>
                    0312 312 1907
                    <br/>
                    fb@afider.org.tr
                    <br/>
                    <a href="http://www.afider.org.tr" target="_blank" rel="noopener noreferrer">
                        www.afider.org.tr
                    </a>
                </p>
            </div>
        );
}
