import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProfileAction, profileLoading, profileSelector} from "../slices/profileSlice";
import {Box, CircularProgress, Divider, Grid, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import {yellow} from "@mui/material/colors";

export const Profile = () => {
    const dispatch: any = useDispatch();
    const profile = useSelector(profileSelector)
    const isLoading = useSelector(profileLoading)

    useEffect(() => {
        dispatch(getProfileAction())
    }, []);

    return (
        <>
            {isLoading ? (<CircularProgress color="success"/>) : (
                <>
                    <Container style={{justifyContent: 'center', marginTop: "10px"}}>
                        <Box p={3} style={{
                            background: 'white',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex'
                        }}>
                            <Avatar sx={{bgcolor: yellow[500]}} aria-label="recipe"
                                    src={profile?.image}>
                            </Avatar>
                            <Typography variant="h4" component="h2" gutterBottom p={3} mt={2}>
                                {profile?.firstName + " " + profile?.surname}
                            </Typography>
                        </Box>
                        <Divider/>
                    </Container>
                    <Container style={{justifyContent: 'center'}}>
                        <Grid container spacing={3} pt={5}>
                            <Grid item xs>
                                E-Posta : {profile?.email}
                            </Grid>
                            <Grid item xs={6}>
                                Instagram: {profile?.instagramLink}
                            </Grid>
                            <Grid item xs>
                                Twitter: {profile?.twitterLink}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} pt={5}>
                            <Grid item xs>
                                Telefon : {profile?.phoneNumber}
                            </Grid>
                            <Grid item xs={6}>
                                Kullanıcı Adı: {profile?.username}
                            </Grid>
                            <Grid item xs>
                                Website: {profile?.websiteLink}
                            </Grid>
                        </Grid>
                    </Container>
                </>
            )}
        </>
    )
}
