import React from 'react';

interface EulaProps {
    lang: string;
}

export const Eula = ({lang}: EulaProps) => {
    const appName = "AFİDER";
    return lang === "tr"
        ? (
            <div className="aydinlatma-metni-container">
                <h1>{appName} MOBİL UYGULAMASI</h1>
                <h2>ANKARA FENERBAHÇELİ İŞ İNSANLARI DERNEĞİ MOBİL UYGULAMA KULLANICI LİSANS ANLAŞMASI (EULA)</h2>
                <p>
                    Bu Ankara Fenerbahçeli İş İnsanları Derneği Mobil Uygulama Kullanıcı Lisans Anlaşması ("Anlaşma"),
                    Ankara Fenerbahçeli İş İnsanları Derneği ("Dernek") tarafından sunulan mobil uygulamanın
                    ("Uygulama") kullanım şartlarını düzenlemektedir. Lütfen bu Anlaşma'yı dikkatlice okuyun ve
                    anladığınızdan emin olduktan sonra Uygulamayı kullanmaya devam edin.
                </p>
                <h3>I. Lisans Verilmesi:</h3>
                <p>
                    Bu program ücretsiz yazılım lisansıyla yayınlanmaktadır.
                </p>

                <h3>II. Kullanım Koşulları:</h3>
                <p>
                    Kötüye kullanım, yasa dışı faaliyetler ve diğer kullanıcıların huzurunu bozacak davranışlar
                    yasaktır.
                </p>

                <h3>III. Fikri Mülkiyet Hakları:</h3>
                <p>
                    Uygulama ve içeriği üzerindeki tüm fikri mülkiyet hakları Dernek'e aittir.
                    Uygulamayı kullanarak Dernek'in fikri mülkiyet haklarına saygı göstermelisiniz.
                </p>
                <h3>IV. Sorumluluk Sınırlamaları:</h3>
                <p>
                    Dernek, Uygulama'nın kullanımı veya kullanılamamasından kaynaklanan herhangi bir zarardan sorumlu
                    değildir.
                    Uygulama'nın kullanımından doğan risklerin tamamen size ait olduğunu kabul edersiniz.
                </p>

                <h3>V. Gizlilik ve Veri Kullanımı:</h3>
                <p>
                    Uygulamanın kullanımıyla ilgili detaylı bilgiler için lütfen Dernek'in
                    <a href="/gizlilik" target="_blank" rel="noopener noreferrer"> Gizlilik Politikası</a>nı inceleyin.
                </p>

                <h3>VI. Değişiklikler ve Güncellemeler:</h3>
                <p>
                    Dernek, bu Anlaşma'yı güncelleme hakkına sahiptir ve güncellemeler hakkında sizi bilgilendirecektir.
                </p>
                <p>
                    Bu Anlaşma'nın tam metni ve Dernek'in diğer politikaları için lütfen Dernek'in resmi web sitesini
                    ziyaret edin.
                </p>
                <p>
                    Bu Anlaşma'yı kabul etmek için Uygulama'yı kullanmaya devam ettiğinizde, bu Anlaşma'nın şartlarını
                    kabul etmiş olursunuz.
                </p>
                <p className="contact">
                    ANKARA FENERBAHÇELİ İŞ İNSANLARI DERNEĞİ
                    <br/>
                    Turan Güneş Bulvarı No: 102/5 Çankaya / ANKARA
                    <br/>
                    0312 312 1907
                    <br/>
                    fb@afider.org.tr
                    <br/>
                    <a href="http://www.afider.org.tr" target="_blank" rel="noopener noreferrer">
                        www.afider.org.tr
                    </a>
                </p>
            </div>
        )
        : (
            <div className="aydinlatma-metni-container">
                <h1>{appName} MOBILE APPLICATION</h1>
                <h2>ANKARA FENERBAHÇE BUSINESSMEN ASSOCIATION MOBILE APPLICATION END USER LICENSE AGREEMENT (EULA)</h2>
                <p>
                    This End User License Agreement ("Agreement") governs the terms of use of the mobile application
                    ("Application") provided by Ankara Fenerbahçe Businessmen Association ("Association"). Please read
                    this
                    Agreement carefully and continue to use the Application only if you understand and agree to its
                    terms.
                </p>
                <h3>I. Grant of License:</h3>
                <p>
                    This program is released under a free software license.
                </p>
                <h3>II. Conditions of Use:</h3>
                <p>
                    Misuse, illegal activities, and disruptive behavior towards other users are prohibited.
                </p>
                <h3>III. Intellectual Property Rights:</h3>
                <p>
                    All intellectual property rights in the Application and its content belong to the Association. You
                    agree to
                    respect the intellectual property rights of the Association while using the Application.
                </p>
                <h3>IV. Limitation of Liability:</h3>
                <p>
                    The Association is not liable for any damages arising from the use or inability to use the
                    Application. You
                    acknowledge that the risks associated with the use of the Application are entirely your own.
                </p>
                <h3>V. Privacy and Data Usage:</h3>
                <p>
                    For detailed information about the use of the Application, please review the Association's <a
                    href="/privacy" target="_blank" rel="noopener noreferrer">
                    Privacy Policy
                </a>.
                </p>
                <h3>VI. Changes and Updates:</h3>
                <p>
                    The Association reserves the right to update this Agreement, and you will be notified of any
                    updates.
                </p>
                <p>
                    For the full text of this Agreement and other policies of the Association, please visit the official
                    website
                    of the Association.
                </p>
                <p>
                    By continuing to use the Application, you agree to the terms of this Agreement.
                </p>
                <p className="contact">
                    ANKARA FENERBAHÇE BUSINESSMEN ASSOCIATION
                    <br/>
                    Turan Güneş Boulevard No: 102/5 Çankaya / ANKARA
                    <br/>
                    0312 312 1907
                    <br/>
                    fb@afider.org.tr
                    <br/>
                    <a href="http://www.afider.org.tr" target="_blank" rel="noopener noreferrer">
                        www.afider.org.tr
                    </a>
                </p>
            </div>
        );
}
