import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {UserDTO} from "../../api/swagger/api";
import {lockUnlockUserAction} from "../../slices/userSlice";

interface LockUnlockUserDialogProps {
    lockedUnlockedUser: UserDTO;
    hideLockUserDialog: () => void;
}

export const LockUnlockUserDialog = ({lockedUnlockedUser, hideLockUserDialog}: LockUnlockUserDialogProps) => {
    const toast = useRef<Toast>(null);
    const dispatch: any = useDispatch();

    const confirmMessage = `${lockedUnlockedUser.firstName} ${lockedUnlockedUser.surname}'i ${
        lockedUnlockedUser.locked ? "kilidini kaldırmak" : "kilitlemek"
    } istediğinize emin misiniz?`;

    const lockUnlockUserSubmit = () => {
        dispatch(lockUnlockUserAction(Number(lockedUnlockedUser.id), !lockedUnlockedUser.locked, 1, hideLockUserDialog));
        toast.current?.show({
            severity: "success",
            summary: "Başarılı",
            detail: !lockedUnlockedUser.locked ? "Kullanıcı kilidi kaldırıldı!" : "Kullanıcı kilitlendi!",
            life: 3000,
        });
    };

    const lockUserDialogFooter = (
        <React.Fragment>
            <Button label="Hayır" icon="pi pi-times" className="p-button-text" onClick={hideLockUserDialog}/>
            <Button label="Evet" icon="pi pi-check" className="p-button-text" onClick={lockUnlockUserSubmit}/>
        </React.Fragment>
    );

    return (
        <Dialog visible={true} style={{width: "450px"}} header="Onayla" modal footer={lockUserDialogFooter}
                onHide={hideLockUserDialog}>
            <div className="flex align-items-center justify-content-center">
                <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: "2rem"}}/>
                {lockedUnlockedUser && (
                    <span><b>{confirmMessage}</b></span>
                )}
            </div>
        </Dialog>
    );
};
