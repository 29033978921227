import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {AnnouncementDTO, PagedEntityListAnnouncementDTO, ResponseDTO} from '../api/swagger/api';
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";
import {fetchWithTimeout} from "./fetchWithTimeout";

const endPoint = EndPoints.announcements

export interface AnnouncementState {
    allAnnouncementsInPage: AnnouncementDTO[];
    lastAnnouncementInPageInfo: PagedEntityListAnnouncementDTO;
    loadAnnouncementsTaskStatus: AsyncTaskStatus | null;
    currentPage: number;
}

const initialState: AnnouncementState = {
    allAnnouncementsInPage: [],
    lastAnnouncementInPageInfo: {
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    } as PagedEntityListAnnouncementDTO,
    currentPage: 1,
    loadAnnouncementsTaskStatus: null
}

export const announcementsSlice = createSlice({
    name: 'announcements',
    initialState: initialState,
    reducers: {
        setLoadAnnouncementsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAnnouncementsTaskStatus = action.payload;
        },
        setCurrentPage: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentPage = action.payload;
        },
        setLastAnnouncementInPageInfo: (state, {payload}) => {
            state.lastAnnouncementInPageInfo = payload;
        },
        setAllAnnouncementInPage: (
            state,
            action: PayloadAction<AnnouncementDTO[]>
        ) => {
            state.allAnnouncementsInPage = action.payload;
        },
    },
});

export const {
    setLoadAnnouncementsTaskStatus,
    setAllAnnouncementInPage,
    setLastAnnouncementInPageInfo,
    setCurrentPage
} = announcementsSlice.actions;

const fetchAnnouncements = async (pageNumber: number) => {
    const token = localStorage.getItem('token');
    return fetchWithTimeout(`${endPoint}?pageNumber=${pageNumber}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const getAllAnnouncementsAction = (callback?: () => void, clear?: boolean) =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setLoadAnnouncementsTaskStatus({type: AsyncTaskStatusType.Loading}));
        const currentPage = clear
            ? 1
            : getState().announcements.currentPage;
        fetchAnnouncements(currentPage)
            .then(response => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setCurrentPage(currentPage + 1));
                    const resultValue = response.resultValue as PagedEntityList<AnnouncementDTO>;
                    dispatch(setLastAnnouncementInPageInfo(resultValue));
                    let currentAnnouncements = clear
                        ? []
                        : getState().announcements.allAnnouncementsInPage;
                    currentAnnouncements = [...currentAnnouncements, ...resultValue.entity];
                    dispatch(setAllAnnouncementInPage(currentAnnouncements));
                    if (callback) {
                        callback();
                    }
                    dispatch(setLoadAnnouncementsTaskStatus({type: AsyncTaskStatusType.Success}));
                } else {
                    dispatch(setLoadAnnouncementsTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            })
            .catch((error) => {
                dispatch(setLoadAnnouncementsTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: error.message
                }));
            });
    };

export const lastAnnouncementInPageInfoSelector = (state: RootState) => state.announcements.lastAnnouncementInPageInfo;
export const loadAnnouncementsTaskStatusSelector = (state: RootState) => state.announcements.loadAnnouncementsTaskStatus;

export default announcementsSlice.reducer;
