import {Campaign, CardGiftcard, Groups, Share, Sports} from '@mui/icons-material';
import {CircularProgress, Grid, Icon, IconButton} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import logo from "../assets/afider_logo.png";
import {
    getAllAnnouncementsAction,
    lastAnnouncementInPageInfoSelector,
    loadAnnouncementsTaskStatusSelector
} from "../slices/announcementSlice";
import {
    getAllPromotionsAction,
    lastPromotionInPageInfoSelector,
    loadPromotionsTaskStatusSelector
} from "../slices/promotionSlice";
import {getAllUsersAction, loadUsersTaskStatusSelector, pageUserObjectSelector} from "../slices/userSlice";
import {useNavigate} from 'react-router-dom';
import {getAllPostsAction, lastPostInPageInfoSelector, loadPostsTaskStatusSelector} from "../slices/postSlice";
import {getAllMatchesAction, lastMatchInPageInfoSelector, loadAllMatchesTaskStatusSelector} from "../slices/matchSlice";
import {AsyncTaskStatusType} from "../shared/dtos";

export const Home = () => {
    const dispatch: any = useDispatch();
    const navigate = useNavigate();
    const promotionsCount = useSelector(lastPromotionInPageInfoSelector)?.totalElements || 0;
    const matchesCount = useSelector(lastMatchInPageInfoSelector)?.totalElements || 0;
    const postCount = useSelector(lastPostInPageInfoSelector)?.totalElements || 0;
    const usersCount = useSelector(pageUserObjectSelector)?.totalElements || 0;
    const announcementsCount = useSelector(lastAnnouncementInPageInfoSelector)?.totalElements || 0;
    const isLoadingAnnouncements = useSelector(loadAnnouncementsTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const isLoadingPromotions = useSelector(loadPromotionsTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const isLoadingMatches = useSelector(loadAllMatchesTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const isLoadingPosts = useSelector(loadPostsTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const isLoadingUsers = useSelector(loadUsersTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const DEFAULT_PAGE_NUMBER = 1;

    useEffect(() => {
        dispatch(getAllAnnouncementsAction());
        dispatch(getAllUsersAction(DEFAULT_PAGE_NUMBER));
        dispatch(getAllPromotionsAction());
        dispatch(getAllMatchesAction());
        dispatch(getAllPostsAction());
    }, [dispatch]);

    const navigateToUserManagement = () => {
        navigate("/kullanici-yonetimi");
    };

    return (
        <>
            <Container maxWidth={false}>
                <Box p={1}>
                    <img src={logo} width="150" height="150" alt="Afider"/>
                    <Typography variant="h4" component="h2" gutterBottom p={2} color="primary">
                        Yönetim Paneli
                    </Typography>
                </Box>
            </Container>
            <Container style={{marginBottom: "3%"}}>
                <Grid container spacing={2} pt={3} style={{
                    flexGrow: 1
                }}>
                    <Grid item lg={3} sm={6} xs={12} mt={2}>
                        <div style={{
                            background: "linear-gradient(90deg, rgb(255, 237, 0) 8% ,rgb(27, 35, 126) 8%)",
                            borderRadius: 10,
                            minHeight: 173
                        }}>
                            <div style={{marginLeft: "8%"}}>
                                <Typography variant="h6" component="h2" gutterBottom color="white">
                                    Kullanıcılar
                                </Typography>
                                <Divider style={{backgroundColor: "white"}}/>
                                <Typography variant="h3" component="h2" gutterBottom color="white">
                                    {isLoadingUsers
                                        ? (<CircularProgress color="success"/>)
                                        : usersCount}
                                </Typography>
                                <IconButton aria-label="share" onClick={navigateToUserManagement}>
                                    <Groups style={{fontSize: 44, color: 'white'}}/>
                                </IconButton>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12} mt={2}>
                        <div style={{
                            background: "linear-gradient(90deg, rgb(255, 237, 0) 8% ,rgb(27, 35, 126) 8%)",
                            borderRadius: 10,
                            minHeight: 173
                        }}>
                            <div style={{marginLeft: "8%"}}>
                                <Typography variant="h6" component="h2" gutterBottom color="white">
                                    Gönderiler
                                </Typography>
                                <Divider style={{backgroundColor: "white"}}/>
                                <Typography variant="h3" component="h2" gutterBottom color="white">
                                    {isLoadingPosts
                                        ? (<CircularProgress color="success"/>)
                                        : postCount}
                                </Typography>
                                <Icon aria-label="posts" style={{width: 44, height: 44}}>
                                    <Share style={{fontSize: 44, color: 'white'}}/>
                                </Icon>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12} mt={2}>
                        <div style={{
                            background: "linear-gradient(90deg, rgb(255, 237, 0) 8% ,rgb(27, 35, 126) 8%)",
                            borderRadius: 10,
                            minHeight: 173
                        }}>
                            <div style={{marginLeft: "8%"}}>
                                <Typography variant="h6" component="h2" gutterBottom color="white">
                                    Duyurular
                                </Typography>
                                <Divider style={{backgroundColor: "white"}}/>
                                <Typography variant="h3" component="h2" gutterBottom color="white">
                                    {isLoadingAnnouncements
                                        ? (<CircularProgress color="success"/>)
                                        : announcementsCount}
                                </Typography>
                                <Icon aria-label="announcements" style={{width: 44, height: 44}}>
                                    <Campaign style={{fontSize: 44, color: 'white'}}/>
                                </Icon>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12} mt={2}>
                        <div style={{
                            background: "linear-gradient(90deg, rgb(255, 237, 0) 8% ,rgb(27, 35, 126) 8%)",
                            borderRadius: 10,
                            minHeight: 173
                        }}>
                            <div style={{marginLeft: "8%"}}>
                                <Typography variant="h6" component="h2" gutterBottom color="white">
                                    Promosyonlar
                                </Typography>
                                <Divider style={{backgroundColor: "white"}}/>

                                <Typography variant="h3" component="h2" gutterBottom color="white">
                                    {isLoadingPromotions
                                        ? (<CircularProgress color="success"/>)
                                        : promotionsCount}
                                </Typography>
                                <Icon aria-label="promotions" style={{width: 44, height: 44}}>
                                    <CardGiftcard style={{fontSize: 44, color: 'white'}}/>
                                </Icon>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={3} sm={6} xs={12} mt={2}>
                        <div style={{
                            background: "linear-gradient(90deg, rgb(255, 237, 0) 8% ,rgb(27, 35, 126) 8%)",
                            borderRadius: 10,
                            minHeight: 173
                        }}>
                            <div style={{marginLeft: "8%"}}>
                                <Typography variant="h6" component="h2" gutterBottom color="white">
                                    Maçlar
                                </Typography>
                                <Divider style={{backgroundColor: "white"}}/>
                                <Typography variant="h3" component="h2" gutterBottom color="white">
                                    {isLoadingMatches
                                        ? (<CircularProgress color="success"/>)
                                        : matchesCount}
                                </Typography>
                                <Icon aria-label="sports" style={{width: 44, height: 44}}>
                                    <Sports style={{fontSize: 44, color: 'white'}}/>
                                </Icon>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}
