const timeout = 60000;

export async function fetchWithTimeout(
    url: string,
    options: any,
): Promise<any> {
    return Promise.race([
        fetch(url, options),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('timeout')), timeout),
        ),
    ]);
}
