import {FieldErrorDTO} from "../api/swagger/api";

export interface PagedEntityList<T> {
    totalNumberPages: number;
    totalElements: number;
    numberOfElements: number;
    entity: T[];
}
export interface PagedEntityList<T> {
    totalNumberPages: number;
    totalElements: number;
    numberOfElements: number;
    entity: T[];
}

export enum AsyncTaskStatusType {
    Loading = "Loading",
    Success = "Success",
    Error = "Error"
}

export interface AsyncTaskStatus {
    type: AsyncTaskStatusType;
    errorMessage?: string;
    fieldErrors?: FieldErrorDTO[];
    errorDetails?: any;
}
