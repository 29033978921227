import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {PagedEntityListPostDTO, PostDTO, ResponseDTO} from "../api/swagger/api";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";
import {fetchWithTimeout} from "./fetchWithTimeout";

const endPoint = EndPoints.posts

export interface PostState {
    loadPostsTaskStatus: AsyncTaskStatus | null;
    pagePostObject: PagedEntityListPostDTO;
    allPostsInPage: PostDTO[];
    currentPage: number;
}

const initialState: PostState = {
    loadPostsTaskStatus: null,
    pagePostObject: {
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    } as PagedEntityListPostDTO,
    allPostsInPage: [],
    currentPage: 1
};

export const postSlice = createSlice({
    name: 'posts',
    initialState,
    reducers: {
        setLoadPostsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadPostsTaskStatus = action.payload;
        },
        setCurrentPage: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentPage = action.payload;
        },
        setLastPostInPageInfo: (
            state,
            action: PayloadAction<PagedEntityListPostDTO>,
        ) => {
            state.pagePostObject = action.payload;
        },
        setAllPostInPage: (
            state,
            action: PayloadAction<PostDTO[]>
        ) => {
            state.allPostsInPage = action.payload;
        }
    },
});

export const {
    setLoadPostsTaskStatus,
    setCurrentPage,
    setLastPostInPageInfo,
    setAllPostInPage
} = postSlice.actions;


const fetchPosts = async (pageNumber: number) => {
    const token = localStorage.getItem('token');
    return fetchWithTimeout(`${endPoint}?pageNumber=${pageNumber}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const getAllPostsAction = (callback?: () => void, clear?: boolean) =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setLoadPostsTaskStatus({type: AsyncTaskStatusType.Loading}));
        const currentPage = clear
            ? 1
            : getState().posts.currentPage;
        fetchPosts(currentPage)
            .then(response => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setCurrentPage(currentPage + 1));
                    const resultValue = response.resultValue as PagedEntityList<PostDTO>;
                    dispatch(setLastPostInPageInfo(resultValue));
                    let currentPosts = clear
                        ? []
                        : getState().posts.allPostsInPage;
                    currentPosts = [...currentPosts, ...resultValue.entity];
                    dispatch(setAllPostInPage(currentPosts));

                    if (callback) {
                        callback();
                    }
                    dispatch(setLoadPostsTaskStatus({type: AsyncTaskStatusType.Success}));
                } else {
                    dispatch(setLoadPostsTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            })
            .catch((error) => {
                dispatch(setLoadPostsTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            });
    };

export const lastPostInPageInfoSelector = (state: RootState) => state.posts.pagePostObject;
export const loadPostsTaskStatusSelector = (state: RootState) => state.posts.loadPostsTaskStatus;

export default postSlice.reducer;
