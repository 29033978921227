import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import logo from "../assets/afider_logo.png";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logoutAction} from '../slices/authSlice';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Transition} from '../shared/Transition';
import {profileSelector} from "../slices/profileSlice";

interface NavigationProps {
    pageTitle: string;
    path: string;
}

const pages: NavigationProps[] = [
    {pageTitle: "Anasayfa", path: "anasayfa"},
    {pageTitle: "KULLANICI YÖNETİMİ", path: "kullanici-yonetimi"},
    {pageTitle: "KAYITLAR", path: "kayitlar"}
];
const settings: NavigationProps[] = [
    {pageTitle: 'Profil', path: 'profil'},
    {pageTitle: 'Çıkış', path: 'logout'}
];

const ResponsiveAppBar = () => {
    const navigate = useNavigate();
    const dispatch: any = useDispatch();
    const profile = useSelector(profileSelector)
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const navigateToPage = (path: string) => {
        navigate("/" + path);
    };

    const navigateToSettings = (path: string) => {
        switch (path) {
            case 'profil':
                navigate("/profil");
                break;
            case 'hesap':
                navigate("/hesap");
                break;
            case 'logout':
                handleClickOpen();
                break;
            default:
                break;
        }
    };

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const renderDialogContent = () => {
        return (
            <div>
                <Dialog
                    open={dialogOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle>Çıkış</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Çıkış yapmak istediğinize emin misiniz?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Hayır</Button>
                        <Button onClick={() => dispatch(logoutAction())}>Evet</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }

    return (
        <AppBar position="static" color="primary">
            {renderDialogContent()}
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}>
                        <img src={logo} width="100" height="100" alt="Afider" onClick={() => navigate("/anasayfa")}
                             style={{cursor: 'pointer'}}/>
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="secondary">
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}>
                            {pages.map((page: NavigationProps) => (
                                <MenuItem key={page.path} onClick={() => navigateToPage(page.path)} id={page.path}>
                                    <Typography textAlign="center">{page.pageTitle}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <img src={logo} width="70" height="70" alt="Afider" onClick={() => navigate("/anasayfa")}
                             style={{cursor: 'pointer'}}/>
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {pages.map((page: NavigationProps) => (
                            <Button
                                key={page.path}
                                onClick={() => navigateToPage(page.path)}
                                sx={{my: 2, color: 'white', display: 'block'}}>
                                {page.pageTitle}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                <Avatar alt={profile?.username?.toLocaleUpperCase()} src="/static/images/avatar/2.jpg"/>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{mt: '45px'}}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}>
                            {settings.map((setting: NavigationProps) => (
                                <MenuItem key={setting.path} onClick={() => navigateToSettings(setting.path)}>
                                    <Typography textAlign="center">{setting.pageTitle}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
