import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";
import {LogDTO, ResponseDTO} from "../api/swagger/api";

const endPoint = EndPoints.logs

export interface LogState {
    pageLogObject: PagedEntityList<LogDTO>;
    loadLogsTaskStatus: AsyncTaskStatus | null;
}

const initialState: LogState = {
    pageLogObject: {
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    } as PagedEntityList<LogDTO>,
    loadLogsTaskStatus: null
}

export const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        getLogsResult: (state, {payload}) => {
            state.pageLogObject = payload
        },
        setLoadLogsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadLogsTaskStatus = action.payload;
        }
    }
});

export const {
    getLogsResult,
    setLoadLogsTaskStatus,
} = logSlice.actions;

export function getAllLogsAction(pageNumber: number, startDate: string, endDate: string) {
    return async (dispatch: any) => {
        dispatch(setLoadLogsTaskStatus({type: AsyncTaskStatusType.Loading}));
        const token = localStorage.getItem('token');

        fetch(`${endPoint}?pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        }).then((response) => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setLoadLogsTaskStatus({type: AsyncTaskStatusType.Success}));
                    dispatch(getLogsResult(response.resultValue as LogDTO[]));
                } else {
                    dispatch(setLoadLogsTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            }).catch((error) => {
                dispatch(setLoadLogsTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            }
        );
    }
}

export const pageLogObjectSelector = (state: RootState) => state.logs.pageLogObject

export const loadLogsTaskStatusSelector = (state: RootState) => state.logs.loadLogsTaskStatus

export default logSlice.reducer;
