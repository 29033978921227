import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {RadioButton} from 'primereact/radiobutton';
import {useDispatch, useSelector} from 'react-redux';
import {editUserTaskStatusSelector, getAllUsersAction, updateUserAction} from '../../slices/userSlice';
import {EditUserDTO, SignupRequestDTO, UserDTO} from '../../api/swagger/api';
import './AddEditUserDialog.css';
import {classNames} from "primereact/utils";
import {
    ALL_MEMBERSHIP_TYPES,
    ALL_ROLES,
    EMAIL_REGEX,
    getPresentableMembershipType,
    getPresentableRole
} from "../../shared/utils";

interface EditUserDialogProps {
    user: UserDTO;
    hideEditUserDialog: () => void;
    currentPage: number;
}

interface EditUserFormData {
    email: string;
    firstName: string;
    role: EditUserDTO.RoleEnum;
    surname: string;
    membershipType: SignupRequestDTO.MembershipTypeEnum;
}

export const EditUserDialog = (props: EditUserDialogProps) => {
    const editUserTaskStatus = useSelector(editUserTaskStatusSelector);
    const dispatch: any = useDispatch();
    const {user, currentPage, hideEditUserDialog} = props;
    const defaultValues: EditUserFormData = {
        email: user.email,
        firstName: user.firstName,
        role: user.role,
        surname: user.surname,
        membershipType: user.membershipType
    };

    const {control, reset, formState: {errors}, handleSubmit} = useForm({
        defaultValues
    });

    useEffect(() => {
        if (editUserTaskStatus?.fieldErrors) {
            editUserTaskStatus?.fieldErrors.forEach((it) =>
                // @ts-ignore
                setError(it.field, {type: 'custom', message: it.message}))
        }
    }, [editUserTaskStatus]);

    useEffect(() => {
        return () => {
            reset();
        }
    }, []);

    const onSubmit = (data: EditUserFormData) => {
        const dto: EditUserDTO = {
            email: data.email,
            firstName: data.firstName,
            role: data.role,
            surname: data.surname,
            membershipType: data.membershipType,
        };

        dispatch(updateUserAction(Number(user.id), dto, () => {
            dispatch(getAllUsersAction(currentPage));
            hideEditUserDialog();
        }));
    };

    const renderEditUserDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideEditUserDialog}/>
            <Button type="submit" icon="pi pi-check" label="Kaydet" onClick={handleSubmit(onSubmit)}/>
        </React.Fragment>
    );

    return (
        <Dialog
            visible={true}
            breakpoints={{'960px': '75vw', '640px': '100vw'}}
            style={{width: '40vw'}}
            header="Kullanıcı Detayları"
            modal
            className="p-fluid"
            onHide={hideEditUserDialog}
            footer={renderEditUserDialogFooter}>
            {user.image && (
                <img
                    src={user?.image}
                    alt={user.imagePath || ''}
                    className="block mt-1 mx-auto mb-5 w-20rem shadow-2"/>
            )}

            <div className="form-demo">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <div className="field">
                                <span className="p-float-label">
                                  <Controller
                                      name="firstName"
                                      control={control}
                                      rules={{required: 'Ad boş geçilemez.'}}
                                      render={({field, fieldState}) => (
                                          <InputText
                                              id={field.name}
                                              {...field}
                                              autoFocus
                                              className={classNames({'p-invalid': fieldState.invalid})}/>
                                      )}/>
                                  <label htmlFor="firstName" className={classNames({'p-error': errors.firstName})}>
                                    Ad*
                                  </label>
                                </span>
                                {errors.firstName && (
                                    <small className="p-error">{errors.firstName.message}</small>
                                )}
                            </div>

                            <div className="field">
                                <span className="p-float-label">
                                  <Controller
                                      name="surname"
                                      control={control}
                                      rules={{required: 'Soyad boş geçilemez.'}}
                                      render={({field, fieldState}) => (
                                          <InputText
                                              id={field.name}
                                              {...field}
                                              className={classNames({'p-invalid': fieldState.invalid})}/>
                                      )}/>
                                  <label htmlFor="surname" className={classNames({'p-error': errors.surname})}>
                                    Soyad*
                                  </label>
                                </span>
                                {errors.surname && (
                                    <small className="p-error">{errors.surname.message}</small>
                                )}
                            </div>
                            <div className="field">
                                <span className="p-float-label p-input-icon-right">
                                  <i className="pi pi-envelope"/>
                                  <Controller
                                      name="email"
                                      control={control}
                                      rules={{
                                          required: 'E-Posta boş geçilemez.',
                                          pattern: {
                                              value: EMAIL_REGEX,
                                              message: 'Geçersiz email adresi. Ör: abcdef@gg.com',
                                          }
                                      }}
                                      render={({field, fieldState}) => (
                                          <InputText
                                              id={field.name}
                                              {...field}
                                              className={classNames({'p-invalid': fieldState.invalid})}/>
                                      )}/>
                                  <label htmlFor="email" className={classNames({'p-error': errors.email})}>
                                    E-Posta*
                                  </label>
                                </span>
                                {errors.email && (
                                    <small className="p-error">{errors.email.message}</small>
                                )}
                            </div>

                            <div className="field">
                                <label className="mb-3">Rol</label>
                                <div className="formgrid grid">
                                    {ALL_ROLES.map((roleValue) => (
                                        <div key={roleValue} className="field-radiobutton col-6">
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({field}) => (
                                                    <RadioButton
                                                        {...field}
                                                        inputId={`role-${roleValue}`}
                                                        value={roleValue}
                                                        checked={field.value === roleValue}/>
                                                )}/>
                                            <label htmlFor={`role-${roleValue}`}>
                                                {getPresentableRole(roleValue)}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                {errors.role && (
                                    <small className="p-error">{errors.role.message}</small>
                                )}
                            </div>

                            <div className="field">
                                <label className="mb-3">Üye Tipi</label>
                                <div className="formgrid grid">
                                    {ALL_MEMBERSHIP_TYPES.map((membershipTypeValue) => (
                                        <div key={membershipTypeValue} className="field-radiobutton col-6">
                                            <Controller
                                                name="membershipType"
                                                control={control}
                                                render={({field}) => (
                                                    <RadioButton
                                                        {...field}
                                                        inputId={`membershipType-${membershipTypeValue}`}
                                                        value={membershipTypeValue}
                                                        checked={field.value === membershipTypeValue}/>
                                                )}/>
                                            <label htmlFor={`membershipType-${membershipTypeValue}`}>
                                                {getPresentableMembershipType(membershipTypeValue)}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                {errors.membershipType && (
                                    <small className="p-error">{errors.membershipType.message}</small>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
