import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from 'react-router-dom';
import {Home} from './pages/Home';
import {UserManagement} from './pages/UserManagement/UserManagement';
import ResponsiveAppBar from './navigation/AppBar';
import {LoginPage} from './pages/Login';
import {authenticationResult, logoutAction, setAuthenticationResult} from './slices/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {fetchAuthToken} from "./misc/CommonUtils";
import {Profile} from "./pages/Profile";
import {createTheme} from "@mui/material/styles";
import {indigo, yellow} from "@mui/material/colors";
import {ThemeProvider} from "@mui/material";
import {Logs} from "./pages/Logs/Logs";
import {Privacy} from "./pages/Privacy";
import {NotFound} from "./pages/NotFound";
import {Eula} from "./pages/Eula";

function App() {
    const tokenFromRedux = useSelector(authenticationResult);
    const isSignedIn = tokenFromRedux != null;

    const dispatch: any = useDispatch();

    useEffect(() => {
        fetchAuthToken().then(token => {
            dispatch(setAuthenticationResult(token as any));
        });
    }, [dispatch]);

    useEffect(() => {
        fetchAuthToken().then(token => {
            if (!token) {
                dispatch(logoutAction());
            }
        });
    }, [dispatch, tokenFromRedux]);

    const theme = createTheme({
        palette: {
            primary: {
                main: indigo[900],
            },
            secondary: {
                main: yellow[500],
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                {isSignedIn && (<ResponsiveAppBar/>)}
                <Routes>
                    <Route path="gizlilik" element={<Privacy lang="tr"/>}/>
                    <Route path="privacy" element={<Privacy lang="en"/>}/>
                    <Route path="eula" element={<Eula lang="en"/>}/>
                    <Route path="son-kullanici" element={<Eula lang="tr"/>}/>
                    {isSignedIn
                        ? (
                            <>
                                <Route path="/anasayfa" element={<Home/>}/>
                                <Route path="/" element={<Home/>}/>
                                <Route path="kullanici-yonetimi" element={<UserManagement/>}/>
                                <Route path="profil" element={<Profile/>}/>
                                <Route path="kayitlar" element={<Logs/>}/>
                                <Route path="/*" element={<NotFound/>}/>
                            </>
                        )
                        : <Route path="/*" element={<LoginPage/>}/>
                    }
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
