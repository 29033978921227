import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import logo from "../assets/afider_logo.png";
import {loginAction, loginTaskStatusSelector} from '../slices/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import {AsyncTaskStatusType} from "../shared/dtos";

// @TODO: add forget password!!!

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://www.afider.org.tr/">
                Afider
            </Link>{' '}
            {new Date().getFullYear()}
        </Typography>
    );
}

const theme = createTheme();

export const LoginPage = () => {
    const dispatch: any = useDispatch();
    const loginTaskStatus = useSelector(loginTaskStatusSelector);
    const isLoading = useSelector(loginTaskStatusSelector)?.type === AsyncTaskStatusType.Loading;
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        dispatch(loginAction({
            username: data.get('username')?.toString() || '',
            password: data.get('password')?.toString() || '',
        }));
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <img src={logo} width="200" height="200" alt="Afider"/>
                    <Typography component="h1" variant="h5" p={5}>
                        Afider Yönetim Paneli
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Kullanıcı Adı"
                            name="username"
                            autoComplete="username"
                            autoFocus/>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Şifre"
                            type="password"
                            id="password"
                            autoComplete="current-password"/>
                        <div style={{width: "100%", textAlign: "left"}}>
                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary"/>}
                                label="Beni hatırla"/>
                        </div>
                        {loginTaskStatus?.type === AsyncTaskStatusType.Error && (
                            <div style={{color: "#70757a"}}>
                                {loginTaskStatus.errorMessage === "Bad credentials"
                                    ? 'Kullanıcı adı ya da parola yanlış!'
                                    : loginTaskStatus.errorMessage}
                            </div>
                        )}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            loading={isLoading}
                            variant="contained"
                            sx={{mt: 3, mb: 2}}>
                            Giriş Yap
                        </LoadingButton>
                        <Grid container>
                            <Grid item xl>
                                <Link href="#" variant="body2">
                                    Şifremi unuttum?
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{mt: 8, mb: 4}}/>
            </Container>
        </ThemeProvider>
    );
}
