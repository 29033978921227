import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {MatchDTO, PagedEntityListMatchDTO, ResponseDTO} from "../api/swagger/api";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";
import {fetchWithTimeout} from "./fetchWithTimeout";

const endPoint = EndPoints.matches

export interface MatchState {
    allMatchesInPage: MatchDTO[];
    pageMatchObject: PagedEntityListMatchDTO;
    loadAllMatchesTaskStatus: AsyncTaskStatus | null;
    currentPage: number;
}

const initialState: MatchState = {
    allMatchesInPage: [],
    pageMatchObject: {
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    } as PagedEntityListMatchDTO,
    loadAllMatchesTaskStatus: null,
    currentPage: 1
};


export const matchesSlice = createSlice({
    name: 'matches',
    initialState,
    reducers: {
        setCurrentPage: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentPage = action.payload;
        },
        setLoadAllMatchesTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadAllMatchesTaskStatus = action.payload;
        },
        setGetAllMatchResult: (
            state,
            action: PayloadAction<PagedEntityListMatchDTO>,
        ) => {
            state.pageMatchObject = action.payload;
        },
        setAllMatchInPage: (
            state,
            action: PayloadAction<MatchDTO[]>
        ) => {
            state.allMatchesInPage = action.payload;
        },
    }
});

export const {
    setCurrentPage,
    setLoadAllMatchesTaskStatus,
    setGetAllMatchResult,
    setAllMatchInPage
} = matchesSlice.actions;

const fetchMatches = async (pageNumber: number) => {
    const token = localStorage.getItem('token');
    return fetchWithTimeout(`${endPoint}?pageNumber=${pageNumber}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const getAllMatchesAction = (callback?: () => void, clear?: boolean) =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setLoadAllMatchesTaskStatus({type: AsyncTaskStatusType.Loading}));
        console.log("here");
        const currentPage = clear
            ? 1
            : getState().matches.currentPage;
        fetchMatches(currentPage)
            .then(response => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setCurrentPage(currentPage + 1));
                    const resultValue = response.resultValue as PagedEntityList<MatchDTO>;
                    dispatch(setGetAllMatchResult(resultValue));
                    dispatch(setLoadAllMatchesTaskStatus({type: AsyncTaskStatusType.Success}));
                    let currentMatches = clear
                        ? []
                        : getState().matches.allMatchesInPage;
                    currentMatches = [...currentMatches, ...resultValue.entity];
                    dispatch(setAllMatchInPage(currentMatches));

                    if (callback) {
                        callback();
                    }
                } else {
                    dispatch(setLoadAllMatchesTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            })
            .catch((error) => {
                dispatch(setLoadAllMatchesTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            });
    }

export const lastMatchInPageInfoSelector = (state: RootState) => state.matches.pageMatchObject
export const loadAllMatchesTaskStatusSelector = (state: RootState) => state.matches.loadAllMatchesTaskStatus;

export default matchesSlice.reducer;
