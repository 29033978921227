/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * AFIDER API
 * Afider Api Documentation
 *
 * OpenAPI spec version: 1.12.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost:8080".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration?: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
}

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 *
 * @export
 * @interface AbuseReportDTO
 */
export interface AbuseReportDTO {
    /**
     *
     * @type {number}
     * @memberof AbuseReportDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AbuseReportDTO
     */
    reportingUserName: string;
    /**
     *
     * @type {string}
     * @memberof AbuseReportDTO
     */
    sikayetMetni: string;
    /**
     *
     * @type {string}
     * @memberof AbuseReportDTO
     */
    type: AbuseReportDTO.TypeEnum;
}

/**
 * @export
 * @namespace AbuseReportDTO
 */
export namespace AbuseReportDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BadWords = <any> 'BadWords',
        Insult = <any> 'Insult'
    }
}

/**
 *
 * @export
 * @interface AddAbuseReportDTO
 */
export interface AddAbuseReportDTO {
    /**
     *
     * @type {string}
     * @memberof AddAbuseReportDTO
     */
    sikayetMetni: string;
    /**
     *
     * @type {string}
     * @memberof AddAbuseReportDTO
     */
    type: AddAbuseReportDTO.TypeEnum;
}

/**
 * @export
 * @namespace AddAbuseReportDTO
 */
export namespace AddAbuseReportDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BadWords = <any> 'BadWords',
        Insult = <any> 'Insult'
    }
}

/**
 *
 * @export
 * @interface AddAppMessageDTO
 */
export interface AddAppMessageDTO {
    /**
     *
     * @type {string}
     * @memberof AddAppMessageDTO
     */
    appMessageType: AddAppMessageDTO.AppMessageTypeEnum;
    /**
     *
     * @type {string}
     * @memberof AddAppMessageDTO
     */
    message: string;
}

/**
 * @export
 * @namespace AddAppMessageDTO
 */
export namespace AddAppMessageDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum AppMessageTypeEnum {
        OpeningMessage = <any> 'OpeningMessage'
    }
}

/**
 *
 * @export
 * @interface AddBloodAnnouncementDTO
 */
export interface AddBloodAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof AddBloodAnnouncementDTO
     */
    bloodType: AddBloodAnnouncementDTO.BloodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof AddBloodAnnouncementDTO
     */
    contactPhone?: string;
    /**
     *
     * @type {Date}
     * @memberof AddBloodAnnouncementDTO
     */
    endDate?: Date;
    /**
     *
     * @type {string}
     * @memberof AddBloodAnnouncementDTO
     */
    note?: string;
    /**
     *
     * @type {Date}
     * @memberof AddBloodAnnouncementDTO
     */
    startDate?: Date;
    /**
     *
     * @type {User}
     * @memberof AddBloodAnnouncementDTO
     */
    user?: User;
}

/**
 * @export
 * @namespace AddBloodAnnouncementDTO
 */
export namespace AddBloodAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
}

/**
 *
 * @export
 * @interface AddMatchDTO
 */
export interface AddMatchDTO {
    /**
     *
     * @type {string}
     * @memberof AddMatchDTO
     */
    award?: string;
    /**
     *
     * @type {string}
     * @memberof AddMatchDTO
     */
    awayTeamName: string;
    /**
     *
     * @type {string}
     * @memberof AddMatchDTO
     */
    homeTeamName: string;
    /**
     *
     * @type {string}
     * @memberof AddMatchDTO
     */
    startTime: string;
}

/**
 *
 * @export
 * @interface AddMeetingEventDTO
 */
export interface AddMeetingEventDTO {
    /**
     *
     * @type {string}
     * @memberof AddMeetingEventDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof AddMeetingEventDTO
     */
    eventDate: Date;
    /**
     *
     * @type {string}
     * @memberof AddMeetingEventDTO
     */
    eventPlace?: string;
    /**
     *
     * @type {string}
     * @memberof AddMeetingEventDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface AddPollDTO
 */
export interface AddPollDTO {
    /**
     *
     * @type {string}
     * @memberof AddPollDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof AddPollDTO
     */
    endDate: Date;
    /**
     *
     * @type {string}
     * @memberof AddPollDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface AddScorePredictionDTO
 */
export interface AddScorePredictionDTO {
    /**
     *
     * @type {number}
     * @memberof AddScorePredictionDTO
     */
    awayTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof AddScorePredictionDTO
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof AddScorePredictionDTO
     */
    matchId?: number;
}

/**
 *
 * @export
 * @interface AddTagDTO
 */
export interface AddTagDTO {
    /**
     *
     * @type {string}
     * @memberof AddTagDTO
     */
    tag: string;
}

/**
 *
 * @export
 * @interface AllUsersDTO
 */
export interface AllUsersDTO {
    /**
     *
     * @type {boolean}
     * @memberof AllUsersDTO
     */
    allowDisplayContactInfo: boolean;
    /**
     *
     * @type {Date}
     * @memberof AllUsersDTO
     */
    birthday?: Date;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    bloodType?: AllUsersDTO.BloodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    firstName: string;
    /**
     *
     * @type {number}
     * @memberof AllUsersDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    imagePath?: string;
    /**
     *
     * @type {boolean}
     * @memberof AllUsersDTO
     */
    locked: boolean;
    /**
     *
     * @type {number}
     * @memberof AllUsersDTO
     */
    membershipNumber?: number;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    membershipType: AllUsersDTO.MembershipTypeEnum;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    phoneNumber?: string;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    role: AllUsersDTO.RoleEnum;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof AllUsersDTO
     */
    username: string;
}

/**
 * @export
 * @namespace AllUsersDTO
 */
export namespace AllUsersDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        DenetimKurulu = <any> 'DenetimKurulu',
        DernekUyesi = <any> 'DernekUyesi',
        DisiplinKurulu = <any> 'DisiplinKurulu',
        SistemYoneticisi = <any> 'SistemYoneticisi',
        YonetimKurulu = <any> 'YonetimKurulu',
        YonetimKuruluBaskani = <any> 'YonetimKuruluBaskani'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Moderator = <any> 'Moderator',
        Subscriber = <any> 'Subscriber',
        User = <any> 'User'
    }
}

/**
 *
 * @export
 * @interface AnnouncementDTO
 */
export interface AnnouncementDTO {
    /**
     *
     * @type {Date}
     * @memberof AnnouncementDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDTO
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof AnnouncementDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDTO
     */
    imagePath?: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof AnnouncementDTO
     */
    issuer: IssuerDTO;
    /**
     *
     * @type {number}
     * @memberof AnnouncementDTO
     */
    likeCount: number;
    /**
     *
     * @type {Array<IssuerDTO>}
     * @memberof AnnouncementDTO
     */
    likedBy: Array<IssuerDTO>;
    /**
     *
     * @type {string}
     * @memberof AnnouncementDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface AppMessageDTO
 */
export interface AppMessageDTO {
    /**
     *
     * @type {string}
     * @memberof AppMessageDTO
     */
    appMessageType: AppMessageDTO.AppMessageTypeEnum;
    /**
     *
     * @type {number}
     * @memberof AppMessageDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof AppMessageDTO
     */
    message: string;
}

/**
 * @export
 * @namespace AppMessageDTO
 */
export namespace AppMessageDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum AppMessageTypeEnum {
        OpeningMessage = <any> 'OpeningMessage'
    }
}

/**
 *
 * @export
 * @interface AuthorityDTO
 */
export interface AuthorityDTO {
    /**
     *
     * @type {string}
     * @memberof AuthorityDTO
     */
    authorityType: AuthorityDTO.AuthorityTypeEnum;
    /**
     *
     * @type {number}
     * @memberof AuthorityDTO
     */
    id: number;
}

/**
 * @export
 * @namespace AuthorityDTO
 */
export namespace AuthorityDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum AuthorityTypeEnum {
        Anket = <any> 'Anket',
        Duyuru = <any> 'Duyuru',
        Etkinlik = <any> 'Etkinlik',
        GonderiSilme = <any> 'GonderiSilme',
        KullaniciIslemleri = <any> 'KullaniciIslemleri',
        KullaniciKilitleme = <any> 'KullaniciKilitleme',
        Mac = <any> 'Mac',
        Promosyon = <any> 'Promosyon',
        Sikayet = <any> 'Sikayet',
        UygulamaMesaji = <any> 'UygulamaMesaji'
    }
}

/**
 *
 * @export
 * @interface AuthorityUsersDTO
 */
export interface AuthorityUsersDTO {
    /**
     *
     * @type {string}
     * @memberof AuthorityUsersDTO
     */
    authorityType: AuthorityUsersDTO.AuthorityTypeEnum;
    /**
     *
     * @type {Date}
     * @memberof AuthorityUsersDTO
     */
    createdDate: Date;
    /**
     *
     * @type {number}
     * @memberof AuthorityUsersDTO
     */
    id: number;
    /**
     *
     * @type {IssuerDTO}
     * @memberof AuthorityUsersDTO
     */
    user: IssuerDTO;
}

/**
 * @export
 * @namespace AuthorityUsersDTO
 */
export namespace AuthorityUsersDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum AuthorityTypeEnum {
        Anket = <any> 'Anket',
        Duyuru = <any> 'Duyuru',
        Etkinlik = <any> 'Etkinlik',
        GonderiSilme = <any> 'GonderiSilme',
        KullaniciIslemleri = <any> 'KullaniciIslemleri',
        KullaniciKilitleme = <any> 'KullaniciKilitleme',
        Mac = <any> 'Mac',
        Promosyon = <any> 'Promosyon',
        Sikayet = <any> 'Sikayet',
        UygulamaMesaji = <any> 'UygulamaMesaji'
    }
}

/**
 *
 * @export
 * @interface BloodAnnouncementDTO
 */
export interface BloodAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof BloodAnnouncementDTO
     */
    bloodType: BloodAnnouncementDTO.BloodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof BloodAnnouncementDTO
     */
    contactPhone: string;
    /**
     *
     * @type {Date}
     * @memberof BloodAnnouncementDTO
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof BloodAnnouncementDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof BloodAnnouncementDTO
     */
    note?: string;
    /**
     *
     * @type {Date}
     * @memberof BloodAnnouncementDTO
     */
    startDate: Date;
    /**
     *
     * @type {IssuerDTO}
     * @memberof BloodAnnouncementDTO
     */
    user?: IssuerDTO;
}

/**
 * @export
 * @namespace BloodAnnouncementDTO
 */
export namespace BloodAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
}

/**
 *
 * @export
 * @interface ChangeOwnPasswordDTO
 */
export interface ChangeOwnPasswordDTO {
    /**
     *
     * @type {string}
     * @memberof ChangeOwnPasswordDTO
     */
    newPassword: string;
    /**
     *
     * @type {string}
     * @memberof ChangeOwnPasswordDTO
     */
    oldPassword: string;
}

/**
 *
 * @export
 * @interface ChangePasswordDTO
 */
export interface ChangePasswordDTO {
    /**
     *
     * @type {string}
     * @memberof ChangePasswordDTO
     */
    password: string;
}

/**
 *
 * @export
 * @interface CommentDTO
 */
export interface CommentDTO {
    /**
     *
     * @type {string}
     * @memberof CommentDTO
     */
    comment: string;
    /**
     *
     * @type {Date}
     * @memberof CommentDTO
     */
    date: Date;
    /**
     *
     * @type {number}
     * @memberof CommentDTO
     */
    id: number;
    /**
     *
     * @type {IssuerDTO}
     * @memberof CommentDTO
     */
    issuer: IssuerDTO;
}

/**
 *
 * @export
 * @interface EditAnnouncementDTO
 */
export interface EditAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof EditAnnouncementDTO
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof EditAnnouncementDTO
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EditAnnouncementDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface EditAppMessageDTO
 */
export interface EditAppMessageDTO {
    /**
     *
     * @type {string}
     * @memberof EditAppMessageDTO
     */
    message: string;
}

/**
 *
 * @export
 * @interface EditBloodAnnouncementDTO
 */
export interface EditBloodAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof EditBloodAnnouncementDTO
     */
    bloodType: EditBloodAnnouncementDTO.BloodTypeEnum;
    /**
     *
     * @type {string}
     * @memberof EditBloodAnnouncementDTO
     */
    contactPhone?: string;
    /**
     *
     * @type {Date}
     * @memberof EditBloodAnnouncementDTO
     */
    endDate?: Date;
    /**
     *
     * @type {string}
     * @memberof EditBloodAnnouncementDTO
     */
    note?: string;
    /**
     *
     * @type {Date}
     * @memberof EditBloodAnnouncementDTO
     */
    startDate?: Date;
    /**
     *
     * @type {User}
     * @memberof EditBloodAnnouncementDTO
     */
    user?: User;
}

/**
 * @export
 * @namespace EditBloodAnnouncementDTO
 */
export namespace EditBloodAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
}

/**
 *
 * @export
 * @interface EditMatchDTO
 */
export interface EditMatchDTO {
    /**
     *
     * @type {string}
     * @memberof EditMatchDTO
     */
    award?: string;
    /**
     *
     * @type {string}
     * @memberof EditMatchDTO
     */
    awayTeamName: string;
    /**
     *
     * @type {number}
     * @memberof EditMatchDTO
     */
    awayTeamScore?: number;
    /**
     *
     * @type {string}
     * @memberof EditMatchDTO
     */
    homeTeamName: string;
    /**
     *
     * @type {number}
     * @memberof EditMatchDTO
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof EditMatchDTO
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EditMatchDTO
     */
    startTime: string;
}

/**
 *
 * @export
 * @interface EditPostDTO
 */
export interface EditPostDTO {
    /**
     *
     * @type {string}
     * @memberof EditPostDTO
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof EditPostDTO
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof EditPostDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface EditPromotionDTO
 */
export interface EditPromotionDTO {
    /**
     *
     * @type {string}
     * @memberof EditPromotionDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof EditPromotionDTO
     */
    endDate?: Date;
    /**
     *
     * @type {number}
     * @memberof EditPromotionDTO
     */
    id?: number;
    /**
     *
     * @type {Date}
     * @memberof EditPromotionDTO
     */
    startDate?: Date;
    /**
     *
     * @type {string}
     * @memberof EditPromotionDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface EditScorePredictionDTO
 */
export interface EditScorePredictionDTO {
    /**
     *
     * @type {number}
     * @memberof EditScorePredictionDTO
     */
    awayTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof EditScorePredictionDTO
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof EditScorePredictionDTO
     */
    id?: number;
}

/**
 *
 * @export
 * @interface EditSelfUserDTO
 */
export interface EditSelfUserDTO {
    /**
     *
     * @type {boolean}
     * @memberof EditSelfUserDTO
     */
    allowDisplayContactInfo?: boolean;
    /**
     *
     * @type {Date}
     * @memberof EditSelfUserDTO
     */
    birthday?: Date;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    bloodType?: EditSelfUserDTO.BloodTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof EditSelfUserDTO
     */
    bloodTypeConsentConfirmation?: boolean;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    company?: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    emergencyFirstName: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    emergencyPhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    emergencySurname: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    facebookLink: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    instagramLink: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    job?: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    linkedInLink: string;
    /**
     *
     * @type {number}
     * @memberof EditSelfUserDTO
     */
    membershipNumber?: number;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    twitterLink: string;
    /**
     *
     * @type {string}
     * @memberof EditSelfUserDTO
     */
    websiteLink: string;
}

/**
 * @export
 * @namespace EditSelfUserDTO
 */
export namespace EditSelfUserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
}

/**
 *
 * @export
 * @interface EditUserDTO
 */
export interface EditUserDTO {
    /**
     *
     * @type {string}
     * @memberof EditUserDTO
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof EditUserDTO
     */
    firstName: string;
    /**
     *
     * @type {string}
     * @memberof EditUserDTO
     */
    membershipType: EditUserDTO.MembershipTypeEnum;
    /**
     *
     * @type {string}
     * @memberof EditUserDTO
     */
    role: EditUserDTO.RoleEnum;
    /**
     *
     * @type {string}
     * @memberof EditUserDTO
     */
    surname: string;
}

/**
 * @export
 * @namespace EditUserDTO
 */
export namespace EditUserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        DenetimKurulu = <any> 'DenetimKurulu',
        DernekUyesi = <any> 'DernekUyesi',
        DisiplinKurulu = <any> 'DisiplinKurulu',
        SistemYoneticisi = <any> 'SistemYoneticisi',
        YonetimKurulu = <any> 'YonetimKurulu',
        YonetimKuruluBaskani = <any> 'YonetimKuruluBaskani'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Moderator = <any> 'Moderator',
        Subscriber = <any> 'Subscriber',
        User = <any> 'User'
    }
}

/**
 *
 * @export
 * @interface FieldErrorDTO
 */
export interface FieldErrorDTO {
    /**
     *
     * @type {string}
     * @memberof FieldErrorDTO
     */
    field: string;
    /**
     *
     * @type {string}
     * @memberof FieldErrorDTO
     */
    message: string;
}

/**
 *
 * @export
 * @interface IssuerDTO
 */
export interface IssuerDTO {
    /**
     *
     * @type {string}
     * @memberof IssuerDTO
     */
    avatarPath?: string;
    /**
     *
     * @type {number}
     * @memberof IssuerDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof IssuerDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof IssuerDTO
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof IssuerDTO
     */
    tag?: string;
}

/**
 *
 * @export
 * @interface LogDTO
 */
export interface LogDTO {
    /**
     *
     * @type {Date}
     * @memberof LogDTO
     */
    date: Date;
    /**
     *
     * @type {number}
     * @memberof LogDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof LogDTO
     */
    logMessage: string;
    /**
     *
     * @type {boolean}
     * @memberof LogDTO
     */
    operationSuccessful: boolean;
    /**
     *
     * @type {string}
     * @memberof LogDTO
     */
    type: LogDTO.TypeEnum;
    /**
     *
     * @type {ShortUserDTO}
     * @memberof LogDTO
     */
    user: ShortUserDTO;
}

/**
 * @export
 * @namespace LogDTO
 */
export namespace LogDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        AddAnnouncement = <any> 'AddAnnouncement',
        AddAppMessage = <any> 'AddAppMessage',
        AddBloodAnnouncement = <any> 'AddBloodAnnouncement',
        AddEventUser = <any> 'AddEventUser',
        AddEvents = <any> 'AddEvents',
        AddMatch = <any> 'AddMatch',
        AddPoll = <any> 'AddPoll',
        AddPost = <any> 'AddPost',
        AddPromotion = <any> 'AddPromotion',
        AddScorePrediction = <any> 'AddScorePrediction',
        AddTag = <any> 'AddTag',
        AddVote = <any> 'AddVote',
        ApproveBloodTypeConsent = <any> 'ApproveBloodTypeConsent',
        ApproveKvkk = <any> 'ApproveKvkk',
        ChangeAvatar = <any> 'ChangeAvatar',
        ChangePassword = <any> 'ChangePassword',
        CommentPost = <any> 'CommentPost',
        DeleteAnnouncement = <any> 'DeleteAnnouncement',
        DeleteAppMessage = <any> 'DeleteAppMessage',
        DeleteBloodAnnouncement = <any> 'DeleteBloodAnnouncement',
        DeleteComment = <any> 'DeleteComment',
        DeleteEvent = <any> 'DeleteEvent',
        DeleteEventUser = <any> 'DeleteEventUser',
        DeleteMatch = <any> 'DeleteMatch',
        DeletePoll = <any> 'DeletePoll',
        DeletePost = <any> 'DeletePost',
        DeletePromotion = <any> 'DeletePromotion',
        DeleteScorePrediction = <any> 'DeleteScorePrediction',
        DeleteTag = <any> 'DeleteTag',
        DeleteUser = <any> 'DeleteUser',
        DeleteVote = <any> 'DeleteVote',
        LikeAnnouncement = <any> 'LikeAnnouncement',
        LikePost = <any> 'LikePost',
        LikePromotion = <any> 'LikePromotion',
        LockUser = <any> 'LockUser',
        ProcessAbuse = <any> 'ProcessAbuse',
        RegisterUser = <any> 'RegisterUser',
        RemoveAvatar = <any> 'RemoveAvatar',
        ReportAbuse = <any> 'ReportAbuse',
        SetMatchScore = <any> 'SetMatchScore',
        UnlockUser = <any> 'UnlockUser',
        UpdateAnnouncement = <any> 'UpdateAnnouncement',
        UpdateAppMessage = <any> 'UpdateAppMessage',
        UpdateBloodAnnouncement = <any> 'UpdateBloodAnnouncement',
        UpdateEvent = <any> 'UpdateEvent',
        UpdateEventUser = <any> 'UpdateEventUser',
        UpdateMatch = <any> 'UpdateMatch',
        UpdatePoll = <any> 'UpdatePoll',
        UpdatePost = <any> 'UpdatePost',
        UpdatePromotion = <any> 'UpdatePromotion',
        UpdateScorePrediction = <any> 'UpdateScorePrediction',
        UpdateUser = <any> 'UpdateUser',
        UpdateUserAsSelf = <any> 'UpdateUserAsSelf',
        UpdateVote = <any> 'UpdateVote'
    }
}

/**
 *
 * @export
 * @interface LoginRequestDTO
 */
export interface LoginRequestDTO {
    /**
     *
     * @type {string}
     * @memberof LoginRequestDTO
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof LoginRequestDTO
     */
    username: string;
}

/**
 *
 * @export
 * @interface Match
 */
export interface Match {
    /**
     *
     * @type {string}
     * @memberof Match
     */
    award?: string;
    /**
     *
     * @type {string}
     * @memberof Match
     */
    awayTeamName: string;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    awayTeamScore?: number;
    /**
     *
     * @type {string}
     * @memberof Match
     */
    homeTeamName: string;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof Match
     */
    id: number;
    /**
     *
     * @type {Array<ScorePrediction>}
     * @memberof Match
     */
    scorePrediction: Array<ScorePrediction>;
    /**
     *
     * @type {Date}
     * @memberof Match
     */
    startTime: Date;
}

/**
 *
 * @export
 * @interface MatchDTO
 */
export interface MatchDTO {
    /**
     *
     * @type {string}
     * @memberof MatchDTO
     */
    award?: string;
    /**
     *
     * @type {string}
     * @memberof MatchDTO
     */
    awayTeamName: string;
    /**
     *
     * @type {number}
     * @memberof MatchDTO
     */
    awayTeamScore?: number;
    /**
     *
     * @type {string}
     * @memberof MatchDTO
     */
    homeTeamName: string;
    /**
     *
     * @type {number}
     * @memberof MatchDTO
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof MatchDTO
     */
    id: number;
    /**
     *
     * @type {Array<ScorePrediction>}
     * @memberof MatchDTO
     */
    scorePrediction: Array<ScorePrediction>;
    /**
     *
     * @type {Date}
     * @memberof MatchDTO
     */
    startTime: Date;
}

/**
 *
 * @export
 * @interface MeetingEventDTO
 */
export interface MeetingEventDTO {
    /**
     *
     * @type {Date}
     * @memberof MeetingEventDTO
     */
    createdDate: Date;
    /**
     *
     * @type {ShortMeetingEventUserDTO}
     * @memberof MeetingEventDTO
     */
    currentUserAttendance?: ShortMeetingEventUserDTO;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof MeetingEventDTO
     */
    eventDate: Date;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDTO
     */
    eventPlace?: string;
    /**
     *
     * @type {number}
     * @memberof MeetingEventDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDTO
     */
    title: string;
    /**
     *
     * @type {number}
     * @memberof MeetingEventDTO
     */
    totalCount: number;
    /**
     *
     * @type {IssuerDTO}
     * @memberof MeetingEventDTO
     */
    user: IssuerDTO;
}

/**
 *
 * @export
 * @interface MeetingEventDetailDTO
 */
export interface MeetingEventDetailDTO {
    /**
     *
     * @type {Date}
     * @memberof MeetingEventDetailDTO
     */
    createdDate: Date;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDetailDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof MeetingEventDetailDTO
     */
    eventDate: Date;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDetailDTO
     */
    eventPlace?: string;
    /**
     *
     * @type {number}
     * @memberof MeetingEventDetailDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof MeetingEventDetailDTO
     */
    title: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof MeetingEventDetailDTO
     */
    user: IssuerDTO;
    /**
     *
     * @type {Array<MeetingEventUserDTO>}
     * @memberof MeetingEventDetailDTO
     */
    userList: Array<MeetingEventUserDTO>;
}

/**
 *
 * @export
 * @interface MeetingEventUserDTO
 */
export interface MeetingEventUserDTO {
    /**
     *
     * @type {number}
     * @memberof MeetingEventUserDTO
     */
    id: number;
    /**
     *
     * @type {IssuerDTO}
     * @memberof MeetingEventUserDTO
     */
    user: IssuerDTO;
    /**
     *
     * @type {number}
     * @memberof MeetingEventUserDTO
     */
    userCount?: number;
}

/**
 *
 * @export
 * @interface PagedEntityListAnnouncementDTO
 */
export interface PagedEntityListAnnouncementDTO {
    /**
     *
     * @type {Array<AnnouncementDTO>}
     * @memberof PagedEntityListAnnouncementDTO
     */
    entity: Array<AnnouncementDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListAnnouncementDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListAnnouncementDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListAnnouncementDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListLogDTO
 */
export interface PagedEntityListLogDTO {
    /**
     *
     * @type {Array<LogDTO>}
     * @memberof PagedEntityListLogDTO
     */
    entity: Array<LogDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListLogDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListLogDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListLogDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListMatchDTO
 */
export interface PagedEntityListMatchDTO {
    /**
     *
     * @type {Array<MatchDTO>}
     * @memberof PagedEntityListMatchDTO
     */
    entity: Array<MatchDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMatchDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMatchDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMatchDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListMeetingEventDTO
 */
export interface PagedEntityListMeetingEventDTO {
    /**
     *
     * @type {Array<MeetingEventDTO>}
     * @memberof PagedEntityListMeetingEventDTO
     */
    entity: Array<MeetingEventDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMeetingEventDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMeetingEventDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListMeetingEventDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListPollDTO
 */
export interface PagedEntityListPollDTO {
    /**
     *
     * @type {Array<PollDTO>}
     * @memberof PagedEntityListPollDTO
     */
    entity: Array<PollDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPollDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPollDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPollDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListPostDTO
 */
export interface PagedEntityListPostDTO {
    /**
     *
     * @type {Array<PostDTO>}
     * @memberof PagedEntityListPostDTO
     */
    entity: Array<PostDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPostDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPostDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPostDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListPromotionDTO
 */
export interface PagedEntityListPromotionDTO {
    /**
     *
     * @type {Array<PromotionDTO>}
     * @memberof PagedEntityListPromotionDTO
     */
    entity: Array<PromotionDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPromotionDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPromotionDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListPromotionDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListScorePredictionDTO
 */
export interface PagedEntityListScorePredictionDTO {
    /**
     *
     * @type {Array<ScorePredictionDTO>}
     * @memberof PagedEntityListScorePredictionDTO
     */
    entity: Array<ScorePredictionDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListScorePredictionDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListScorePredictionDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListScorePredictionDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListUserDTO
 */
export interface PagedEntityListUserDTO {
    /**
     *
     * @type {Array<UserDTO>}
     * @memberof PagedEntityListUserDTO
     */
    entity: Array<UserDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface PagedEntityListUserNotificationDTO
 */
export interface PagedEntityListUserNotificationDTO {
    /**
     *
     * @type {Array<UserNotificationDTO>}
     * @memberof PagedEntityListUserNotificationDTO
     */
    entity: Array<UserNotificationDTO>;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserNotificationDTO
     */
    numberOfElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserNotificationDTO
     */
    totalElements: number;
    /**
     *
     * @type {number}
     * @memberof PagedEntityListUserNotificationDTO
     */
    totalNumberPages: number;
}

/**
 *
 * @export
 * @interface Poll
 */
export interface Poll {
    /**
     *
     * @type {Date}
     * @memberof Poll
     */
    createdDate: Date;
    /**
     *
     * @type {string}
     * @memberof Poll
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof Poll
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof Poll
     */
    id: number;
    /**
     *
     * @type {Array<PollOption>}
     * @memberof Poll
     */
    options: Array<PollOption>;
    /**
     *
     * @type {string}
     * @memberof Poll
     */
    title: string;
    /**
     *
     * @type {User}
     * @memberof Poll
     */
    user: User;
}

/**
 *
 * @export
 * @interface PollDTO
 */
export interface PollDTO {
    /**
     *
     * @type {Date}
     * @memberof PollDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof PollDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof PollDTO
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof PollDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PollDTO
     */
    title: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof PollDTO
     */
    user: IssuerDTO;
}

/**
 *
 * @export
 * @interface PollDetailDTO
 */
export interface PollDetailDTO {
    /**
     *
     * @type {Date}
     * @memberof PollDetailDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof PollDetailDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof PollDetailDTO
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof PollDetailDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PollDetailDTO
     */
    title: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof PollDetailDTO
     */
    user: IssuerDTO;
    /**
     *
     * @type {Array<VoteDTO>}
     * @memberof PollDetailDTO
     */
    voteList: Array<VoteDTO>;
}

/**
 *
 * @export
 * @interface PollOption
 */
export interface PollOption {
    /**
     *
     * @type {number}
     * @memberof PollOption
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PollOption
     */
    option: string;
    /**
     *
     * @type {Poll}
     * @memberof PollOption
     */
    poll?: Poll;
}

/**
 *
 * @export
 * @interface PostCommentDTO
 */
export interface PostCommentDTO {
    /**
     *
     * @type {string}
     * @memberof PostCommentDTO
     */
    comment: string;
}

/**
 *
 * @export
 * @interface PostDTO
 */
export interface PostDTO {
    /**
     *
     * @type {Array<CommentDTO>}
     * @memberof PostDTO
     */
    comments: Array<CommentDTO>;
    /**
     *
     * @type {Date}
     * @memberof PostDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof PostDTO
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof PostDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PostDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof PostDTO
     */
    imagePath?: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof PostDTO
     */
    issuer: IssuerDTO;
    /**
     *
     * @type {number}
     * @memberof PostDTO
     */
    likeCount: number;
    /**
     *
     * @type {Array<IssuerDTO>}
     * @memberof PostDTO
     */
    likedBy: Array<IssuerDTO>;
    /**
     *
     * @type {string}
     * @memberof PostDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface PromotionDTO
 */
export interface PromotionDTO {
    /**
     *
     * @type {Date}
     * @memberof PromotionDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof PromotionDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof PromotionDTO
     */
    endDate: Date;
    /**
     *
     * @type {number}
     * @memberof PromotionDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof PromotionDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof PromotionDTO
     */
    imagePath?: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof PromotionDTO
     */
    issuer: IssuerDTO;
    /**
     *
     * @type {number}
     * @memberof PromotionDTO
     */
    likeCount: number;
    /**
     *
     * @type {Array<IssuerDTO>}
     * @memberof PromotionDTO
     */
    likedBy: Array<IssuerDTO>;
    /**
     *
     * @type {Date}
     * @memberof PromotionDTO
     */
    startDate: Date;
    /**
     *
     * @type {string}
     * @memberof PromotionDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface ResponseDTO
 */
export interface ResponseDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTO
     */
    code: ResponseDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTO
     */
    message: string;
    /**
     *
     * @type {any}
     * @memberof ResponseDTO
     */
    resultValue?: any;
}

/**
 * @export
 * @namespace ResponseDTO
 */
export namespace ResponseDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOAnnouncementDTO
 */
export interface ResponseDTOAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOAnnouncementDTO
     */
    code: ResponseDTOAnnouncementDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOAnnouncementDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOAnnouncementDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOAnnouncementDTO
     */
    message: string;
    /**
     *
     * @type {AnnouncementDTO}
     * @memberof ResponseDTOAnnouncementDTO
     */
    resultValue?: AnnouncementDTO;
}

/**
 * @export
 * @namespace ResponseDTOAnnouncementDTO
 */
export namespace ResponseDTOAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOAppMessageDTO
 */
export interface ResponseDTOAppMessageDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOAppMessageDTO
     */
    code: ResponseDTOAppMessageDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOAppMessageDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOAppMessageDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOAppMessageDTO
     */
    message: string;
    /**
     *
     * @type {AppMessageDTO}
     * @memberof ResponseDTOAppMessageDTO
     */
    resultValue?: AppMessageDTO;
}

/**
 * @export
 * @namespace ResponseDTOAppMessageDTO
 */
export namespace ResponseDTOAppMessageDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOBloodAnnouncementDTO
 */
export interface ResponseDTOBloodAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOBloodAnnouncementDTO
     */
    code: ResponseDTOBloodAnnouncementDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOBloodAnnouncementDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOBloodAnnouncementDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOBloodAnnouncementDTO
     */
    message: string;
    /**
     *
     * @type {BloodAnnouncementDTO}
     * @memberof ResponseDTOBloodAnnouncementDTO
     */
    resultValue?: BloodAnnouncementDTO;
}

/**
 * @export
 * @namespace ResponseDTOBloodAnnouncementDTO
 */
export namespace ResponseDTOBloodAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListAbuseReportDTO
 */
export interface ResponseDTOListAbuseReportDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAbuseReportDTO
     */
    code: ResponseDTOListAbuseReportDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListAbuseReportDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListAbuseReportDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAbuseReportDTO
     */
    message: string;
    /**
     *
     * @type {Array<AbuseReportDTO>}
     * @memberof ResponseDTOListAbuseReportDTO
     */
    resultValue?: Array<AbuseReportDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListAbuseReportDTO
 */
export namespace ResponseDTOListAbuseReportDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListAllUsersDTO
 */
export interface ResponseDTOListAllUsersDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAllUsersDTO
     */
    code: ResponseDTOListAllUsersDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListAllUsersDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListAllUsersDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAllUsersDTO
     */
    message: string;
    /**
     *
     * @type {Array<AllUsersDTO>}
     * @memberof ResponseDTOListAllUsersDTO
     */
    resultValue?: Array<AllUsersDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListAllUsersDTO
 */
export namespace ResponseDTOListAllUsersDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListAppMessageDTO
 */
export interface ResponseDTOListAppMessageDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAppMessageDTO
     */
    code: ResponseDTOListAppMessageDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListAppMessageDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListAppMessageDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAppMessageDTO
     */
    message: string;
    /**
     *
     * @type {Array<AppMessageDTO>}
     * @memberof ResponseDTOListAppMessageDTO
     */
    resultValue?: Array<AppMessageDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListAppMessageDTO
 */
export namespace ResponseDTOListAppMessageDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListAuthorityDTO
 */
export interface ResponseDTOListAuthorityDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAuthorityDTO
     */
    code: ResponseDTOListAuthorityDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListAuthorityDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListAuthorityDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAuthorityDTO
     */
    message: string;
    /**
     *
     * @type {Array<AuthorityDTO>}
     * @memberof ResponseDTOListAuthorityDTO
     */
    resultValue?: Array<AuthorityDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListAuthorityDTO
 */
export namespace ResponseDTOListAuthorityDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListAuthorityUsersDTO
 */
export interface ResponseDTOListAuthorityUsersDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAuthorityUsersDTO
     */
    code: ResponseDTOListAuthorityUsersDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListAuthorityUsersDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListAuthorityUsersDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListAuthorityUsersDTO
     */
    message: string;
    /**
     *
     * @type {Array<AuthorityUsersDTO>}
     * @memberof ResponseDTOListAuthorityUsersDTO
     */
    resultValue?: Array<AuthorityUsersDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListAuthorityUsersDTO
 */
export namespace ResponseDTOListAuthorityUsersDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListBloodAnnouncementDTO
 */
export interface ResponseDTOListBloodAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListBloodAnnouncementDTO
     */
    code: ResponseDTOListBloodAnnouncementDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListBloodAnnouncementDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListBloodAnnouncementDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListBloodAnnouncementDTO
     */
    message: string;
    /**
     *
     * @type {Array<BloodAnnouncementDTO>}
     * @memberof ResponseDTOListBloodAnnouncementDTO
     */
    resultValue?: Array<BloodAnnouncementDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListBloodAnnouncementDTO
 */
export namespace ResponseDTOListBloodAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListScorePredictionDTO
 */
export interface ResponseDTOListScorePredictionDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListScorePredictionDTO
     */
    code: ResponseDTOListScorePredictionDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListScorePredictionDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListScorePredictionDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListScorePredictionDTO
     */
    message: string;
    /**
     *
     * @type {Array<ScorePredictionDTO>}
     * @memberof ResponseDTOListScorePredictionDTO
     */
    resultValue?: Array<ScorePredictionDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListScorePredictionDTO
 */
export namespace ResponseDTOListScorePredictionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListUserDTO
 */
export interface ResponseDTOListUserDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListUserDTO
     */
    code: ResponseDTOListUserDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListUserDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListUserDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListUserDTO
     */
    message: string;
    /**
     *
     * @type {Array<UserDTO>}
     * @memberof ResponseDTOListUserDTO
     */
    resultValue?: Array<UserDTO>;
}

/**
 * @export
 * @namespace ResponseDTOListUserDTO
 */
export namespace ResponseDTOListUserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOListstring
 */
export interface ResponseDTOListstring {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListstring
     */
    code: ResponseDTOListstring.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOListstring
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOListstring
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOListstring
     */
    message: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ResponseDTOListstring
     */
    resultValue?: Array<string>;
}

/**
 * @export
 * @namespace ResponseDTOListstring
 */
export namespace ResponseDTOListstring {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOMeetingEventDTO
 */
export interface ResponseDTOMeetingEventDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOMeetingEventDTO
     */
    code: ResponseDTOMeetingEventDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOMeetingEventDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOMeetingEventDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOMeetingEventDTO
     */
    message: string;
    /**
     *
     * @type {MeetingEventDTO}
     * @memberof ResponseDTOMeetingEventDTO
     */
    resultValue?: MeetingEventDTO;
}

/**
 * @export
 * @namespace ResponseDTOMeetingEventDTO
 */
export namespace ResponseDTOMeetingEventDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOMeetingEventDetailDTO
 */
export interface ResponseDTOMeetingEventDetailDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOMeetingEventDetailDTO
     */
    code: ResponseDTOMeetingEventDetailDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOMeetingEventDetailDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOMeetingEventDetailDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOMeetingEventDetailDTO
     */
    message: string;
    /**
     *
     * @type {MeetingEventDetailDTO}
     * @memberof ResponseDTOMeetingEventDetailDTO
     */
    resultValue?: MeetingEventDetailDTO;
}

/**
 * @export
 * @namespace ResponseDTOMeetingEventDetailDTO
 */
export namespace ResponseDTOMeetingEventDetailDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListAnnouncementDTO
 */
export interface ResponseDTOPagedEntityListAnnouncementDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListAnnouncementDTO
     */
    code: ResponseDTOPagedEntityListAnnouncementDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListAnnouncementDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListAnnouncementDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListAnnouncementDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListAnnouncementDTO}
     * @memberof ResponseDTOPagedEntityListAnnouncementDTO
     */
    resultValue?: PagedEntityListAnnouncementDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListAnnouncementDTO
 */
export namespace ResponseDTOPagedEntityListAnnouncementDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListLogDTO
 */
export interface ResponseDTOPagedEntityListLogDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListLogDTO
     */
    code: ResponseDTOPagedEntityListLogDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListLogDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListLogDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListLogDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListLogDTO}
     * @memberof ResponseDTOPagedEntityListLogDTO
     */
    resultValue?: PagedEntityListLogDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListLogDTO
 */
export namespace ResponseDTOPagedEntityListLogDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListMatchDTO
 */
export interface ResponseDTOPagedEntityListMatchDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListMatchDTO
     */
    code: ResponseDTOPagedEntityListMatchDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListMatchDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListMatchDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListMatchDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListMatchDTO}
     * @memberof ResponseDTOPagedEntityListMatchDTO
     */
    resultValue?: PagedEntityListMatchDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListMatchDTO
 */
export namespace ResponseDTOPagedEntityListMatchDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListMeetingEventDTO
 */
export interface ResponseDTOPagedEntityListMeetingEventDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListMeetingEventDTO
     */
    code: ResponseDTOPagedEntityListMeetingEventDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListMeetingEventDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListMeetingEventDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListMeetingEventDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListMeetingEventDTO}
     * @memberof ResponseDTOPagedEntityListMeetingEventDTO
     */
    resultValue?: PagedEntityListMeetingEventDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListMeetingEventDTO
 */
export namespace ResponseDTOPagedEntityListMeetingEventDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListPollDTO
 */
export interface ResponseDTOPagedEntityListPollDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPollDTO
     */
    code: ResponseDTOPagedEntityListPollDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListPollDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListPollDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPollDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListPollDTO}
     * @memberof ResponseDTOPagedEntityListPollDTO
     */
    resultValue?: PagedEntityListPollDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListPollDTO
 */
export namespace ResponseDTOPagedEntityListPollDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListPostDTO
 */
export interface ResponseDTOPagedEntityListPostDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPostDTO
     */
    code: ResponseDTOPagedEntityListPostDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListPostDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListPostDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPostDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListPostDTO}
     * @memberof ResponseDTOPagedEntityListPostDTO
     */
    resultValue?: PagedEntityListPostDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListPostDTO
 */
export namespace ResponseDTOPagedEntityListPostDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListPromotionDTO
 */
export interface ResponseDTOPagedEntityListPromotionDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPromotionDTO
     */
    code: ResponseDTOPagedEntityListPromotionDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListPromotionDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListPromotionDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListPromotionDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListPromotionDTO}
     * @memberof ResponseDTOPagedEntityListPromotionDTO
     */
    resultValue?: PagedEntityListPromotionDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListPromotionDTO
 */
export namespace ResponseDTOPagedEntityListPromotionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListScorePredictionDTO
 */
export interface ResponseDTOPagedEntityListScorePredictionDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListScorePredictionDTO
     */
    code: ResponseDTOPagedEntityListScorePredictionDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListScorePredictionDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListScorePredictionDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListScorePredictionDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListScorePredictionDTO}
     * @memberof ResponseDTOPagedEntityListScorePredictionDTO
     */
    resultValue?: PagedEntityListScorePredictionDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListScorePredictionDTO
 */
export namespace ResponseDTOPagedEntityListScorePredictionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListUserDTO
 */
export interface ResponseDTOPagedEntityListUserDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListUserDTO
     */
    code: ResponseDTOPagedEntityListUserDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListUserDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListUserDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListUserDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListUserDTO}
     * @memberof ResponseDTOPagedEntityListUserDTO
     */
    resultValue?: PagedEntityListUserDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListUserDTO
 */
export namespace ResponseDTOPagedEntityListUserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPagedEntityListUserNotificationDTO
 */
export interface ResponseDTOPagedEntityListUserNotificationDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListUserNotificationDTO
     */
    code: ResponseDTOPagedEntityListUserNotificationDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPagedEntityListUserNotificationDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPagedEntityListUserNotificationDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPagedEntityListUserNotificationDTO
     */
    message: string;
    /**
     *
     * @type {PagedEntityListUserNotificationDTO}
     * @memberof ResponseDTOPagedEntityListUserNotificationDTO
     */
    resultValue?: PagedEntityListUserNotificationDTO;
}

/**
 * @export
 * @namespace ResponseDTOPagedEntityListUserNotificationDTO
 */
export namespace ResponseDTOPagedEntityListUserNotificationDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPollDetailDTO
 */
export interface ResponseDTOPollDetailDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPollDetailDTO
     */
    code: ResponseDTOPollDetailDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPollDetailDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPollDetailDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPollDetailDTO
     */
    message: string;
    /**
     *
     * @type {PollDetailDTO}
     * @memberof ResponseDTOPollDetailDTO
     */
    resultValue?: PollDetailDTO;
}

/**
 * @export
 * @namespace ResponseDTOPollDetailDTO
 */
export namespace ResponseDTOPollDetailDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPostDTO
 */
export interface ResponseDTOPostDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPostDTO
     */
    code: ResponseDTOPostDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPostDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPostDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPostDTO
     */
    message: string;
    /**
     *
     * @type {PostDTO}
     * @memberof ResponseDTOPostDTO
     */
    resultValue?: PostDTO;
}

/**
 * @export
 * @namespace ResponseDTOPostDTO
 */
export namespace ResponseDTOPostDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOPromotionDTO
 */
export interface ResponseDTOPromotionDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPromotionDTO
     */
    code: ResponseDTOPromotionDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOPromotionDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOPromotionDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOPromotionDTO
     */
    message: string;
    /**
     *
     * @type {PromotionDTO}
     * @memberof ResponseDTOPromotionDTO
     */
    resultValue?: PromotionDTO;
}

/**
 * @export
 * @namespace ResponseDTOPromotionDTO
 */
export namespace ResponseDTOPromotionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOScorePredictionDTO
 */
export interface ResponseDTOScorePredictionDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOScorePredictionDTO
     */
    code: ResponseDTOScorePredictionDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOScorePredictionDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOScorePredictionDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOScorePredictionDTO
     */
    message: string;
    /**
     *
     * @type {ScorePredictionDTO}
     * @memberof ResponseDTOScorePredictionDTO
     */
    resultValue?: ScorePredictionDTO;
}

/**
 * @export
 * @namespace ResponseDTOScorePredictionDTO
 */
export namespace ResponseDTOScorePredictionDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOSearchResultDTO
 */
export interface ResponseDTOSearchResultDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOSearchResultDTO
     */
    code: ResponseDTOSearchResultDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOSearchResultDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOSearchResultDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOSearchResultDTO
     */
    message: string;
    /**
     *
     * @type {SearchResultDTO}
     * @memberof ResponseDTOSearchResultDTO
     */
    resultValue?: SearchResultDTO;
}

/**
 * @export
 * @namespace ResponseDTOSearchResultDTO
 */
export namespace ResponseDTOSearchResultDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOSignupResponse
 */
export interface ResponseDTOSignupResponse {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOSignupResponse
     */
    code: ResponseDTOSignupResponse.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOSignupResponse
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOSignupResponse
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOSignupResponse
     */
    message: string;
    /**
     *
     * @type {SignupResponse}
     * @memberof ResponseDTOSignupResponse
     */
    resultValue?: SignupResponse;
}

/**
 * @export
 * @namespace ResponseDTOSignupResponse
 */
export namespace ResponseDTOSignupResponse {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOUserDTO
 */
export interface ResponseDTOUserDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOUserDTO
     */
    code: ResponseDTOUserDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOUserDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOUserDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOUserDTO
     */
    message: string;
    /**
     *
     * @type {UserDTO}
     * @memberof ResponseDTOUserDTO
     */
    resultValue?: UserDTO;
}

/**
 * @export
 * @namespace ResponseDTOUserDTO
 */
export namespace ResponseDTOUserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOVoteDTO
 */
export interface ResponseDTOVoteDTO {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOVoteDTO
     */
    code: ResponseDTOVoteDTO.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOVoteDTO
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOVoteDTO
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOVoteDTO
     */
    message: string;
    /**
     *
     * @type {VoteDTO}
     * @memberof ResponseDTOVoteDTO
     */
    resultValue?: VoteDTO;
}

/**
 * @export
 * @namespace ResponseDTOVoteDTO
 */
export namespace ResponseDTOVoteDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ResponseDTOstring
 */
export interface ResponseDTOstring {
    /**
     *
     * @type {string}
     * @memberof ResponseDTOstring
     */
    code: ResponseDTOstring.CodeEnum;
    /**
     *
     * @type {any}
     * @memberof ResponseDTOstring
     */
    errorDetails?: any;
    /**
     *
     * @type {Array<FieldErrorDTO>}
     * @memberof ResponseDTOstring
     */
    fieldErrors: Array<FieldErrorDTO>;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOstring
     */
    message: string;
    /**
     *
     * @type {string}
     * @memberof ResponseDTOstring
     */
    resultValue?: string;
}

/**
 * @export
 * @namespace ResponseDTOstring
 */
export namespace ResponseDTOstring {
    /**
     * @export
     * @enum {string}
     */
    export enum CodeEnum {
        DuplicateObject = <any> 'DuplicateObject',
        Forbidden = <any> 'Forbidden',
        InternalError = <any> 'InternalError',
        InvalidFile = <any> 'InvalidFile',
        InvalidObjectState = <any> 'InvalidObjectState',
        MethodNotAllowed = <any> 'MethodNotAllowed',
        ObjectNotFound = <any> 'ObjectNotFound',
        Success = <any> 'Success',
        UnsupportedMediaType = <any> 'UnsupportedMediaType',
        ValidationError = <any> 'ValidationError'
    }
}

/**
 *
 * @export
 * @interface ScorePrediction
 */
export interface ScorePrediction {
    /**
     *
     * @type {number}
     * @memberof ScorePrediction
     */
    awayTeamScore: number;
    /**
     *
     * @type {Date}
     * @memberof ScorePrediction
     */
    createdDate: Date;
    /**
     *
     * @type {number}
     * @memberof ScorePrediction
     */
    homeTeamScore: number;
    /**
     *
     * @type {number}
     * @memberof ScorePrediction
     */
    id: number;
    /**
     *
     * @type {Date}
     * @memberof ScorePrediction
     */
    updatedDate?: Date;
    /**
     *
     * @type {User}
     * @memberof ScorePrediction
     */
    user: User;
}

/**
 *
 * @export
 * @interface ScorePredictionDTO
 */
export interface ScorePredictionDTO {
    /**
     *
     * @type {number}
     * @memberof ScorePredictionDTO
     */
    awayTeamScore: number;
    /**
     *
     * @type {Date}
     * @memberof ScorePredictionDTO
     */
    createdDate: Date;
    /**
     *
     * @type {number}
     * @memberof ScorePredictionDTO
     */
    homeTeamScore: number;
    /**
     *
     * @type {number}
     * @memberof ScorePredictionDTO
     */
    id: number;
    /**
     *
     * @type {Match}
     * @memberof ScorePredictionDTO
     */
    match: Match;
    /**
     *
     * @type {Date}
     * @memberof ScorePredictionDTO
     */
    updatedDate?: Date;
    /**
     *
     * @type {ShortUserDTO}
     * @memberof ScorePredictionDTO
     */
    user: ShortUserDTO;
}

/**
 *
 * @export
 * @interface SearchResultDTO
 */
export interface SearchResultDTO {
    /**
     *
     * @type {Array<ShortAnnouncementDTO>}
     * @memberof SearchResultDTO
     */
    announcements: Array<ShortAnnouncementDTO>;
    /**
     *
     * @type {Date}
     * @memberof SearchResultDTO
     */
    date: Date;
    /**
     *
     * @type {Array<IssuerDTO>}
     * @memberof SearchResultDTO
     */
    tags: Array<IssuerDTO>;
    /**
     *
     * @type {Array<IssuerDTO>}
     * @memberof SearchResultDTO
     */
    users: Array<IssuerDTO>;
}

/**
 *
 * @export
 * @interface SetMatchScoreDTO
 */
export interface SetMatchScoreDTO {
    /**
     *
     * @type {number}
     * @memberof SetMatchScoreDTO
     */
    awayTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof SetMatchScoreDTO
     */
    homeTeamScore?: number;
    /**
     *
     * @type {number}
     * @memberof SetMatchScoreDTO
     */
    id?: number;
}

/**
 *
 * @export
 * @interface ShortAnnouncementDTO
 */
export interface ShortAnnouncementDTO {
    /**
     *
     * @type {Date}
     * @memberof ShortAnnouncementDTO
     */
    date: Date;
    /**
     *
     * @type {string}
     * @memberof ShortAnnouncementDTO
     */
    description: string;
    /**
     *
     * @type {number}
     * @memberof ShortAnnouncementDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShortAnnouncementDTO
     */
    imagePath?: string;
    /**
     *
     * @type {IssuerDTO}
     * @memberof ShortAnnouncementDTO
     */
    issuer: IssuerDTO;
    /**
     *
     * @type {string}
     * @memberof ShortAnnouncementDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface ShortMeetingEventUserDTO
 */
export interface ShortMeetingEventUserDTO {
    /**
     *
     * @type {number}
     * @memberof ShortMeetingEventUserDTO
     */
    id: number;
    /**
     *
     * @type {number}
     * @memberof ShortMeetingEventUserDTO
     */
    userCount: number;
}

/**
 *
 * @export
 * @interface ShortUserDTO
 */
export interface ShortUserDTO {
    /**
     *
     * @type {string}
     * @memberof ShortUserDTO
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof ShortUserDTO
     */
    firstName: string;
    /**
     *
     * @type {number}
     * @memberof ShortUserDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof ShortUserDTO
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof ShortUserDTO
     */
    username: string;
}

/**
 *
 * @export
 * @interface SignupRequestDTO
 */
export interface SignupRequestDTO {
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    firstName: string;
    /**
     *
     * @type {number}
     * @memberof SignupRequestDTO
     */
    membershipNumber?: number;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    membershipType: SignupRequestDTO.MembershipTypeEnum;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    role: SignupRequestDTO.RoleEnum;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    surname: string;
    /**
     *
     * @type {string}
     * @memberof SignupRequestDTO
     */
    username: string;
}

/**
 * @export
 * @namespace SignupRequestDTO
 */
export namespace SignupRequestDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        DenetimKurulu = <any> 'DenetimKurulu',
        DernekUyesi = <any> 'DernekUyesi',
        DisiplinKurulu = <any> 'DisiplinKurulu',
        SistemYoneticisi = <any> 'SistemYoneticisi',
        YonetimKurulu = <any> 'YonetimKurulu',
        YonetimKuruluBaskani = <any> 'YonetimKuruluBaskani'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Moderator = <any> 'Moderator',
        Subscriber = <any> 'Subscriber',
        User = <any> 'User'
    }
}

/**
 *
 * @export
 * @interface SignupResponse
 */
export interface SignupResponse {
    /**
     *
     * @type {string}
     * @memberof SignupResponse
     */
    message: string;
    /**
     *
     * @type {number}
     * @memberof SignupResponse
     */
    registeredUserId?: number;
}

/**
 *
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     *
     * @type {number}
     * @memberof Tag
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof Tag
     */
    tag: string;
}

/**
 *
 * @export
 * @interface UpdateMeetingEventDTO
 */
export interface UpdateMeetingEventDTO {
    /**
     *
     * @type {string}
     * @memberof UpdateMeetingEventDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof UpdateMeetingEventDTO
     */
    eventDate: Date;
    /**
     *
     * @type {string}
     * @memberof UpdateMeetingEventDTO
     */
    eventPlace?: string;
    /**
     *
     * @type {string}
     * @memberof UpdateMeetingEventDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface UpdatePollDTO
 */
export interface UpdatePollDTO {
    /**
     *
     * @type {string}
     * @memberof UpdatePollDTO
     */
    description: string;
    /**
     *
     * @type {Date}
     * @memberof UpdatePollDTO
     */
    endDate: Date;
    /**
     *
     * @type {string}
     * @memberof UpdatePollDTO
     */
    title: string;
}

/**
 *
 * @export
 * @interface User
 */
export interface User {
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    allowDisplayContactInfo: boolean;
    /**
     *
     * @type {Date}
     * @memberof User
     */
    birthday?: Date;
    /**
     *
     * @type {string}
     * @memberof User
     */
    bloodType?: User.BloodTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    bloodTypeConsentApproved: boolean;
    /**
     *
     * @type {string}
     * @memberof User
     */
    company?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    cvText?: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    emergencyFirstName: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    emergencyPhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    emergencySurname: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    facebookLink: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     *
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof User
     */
    imagePath: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    instagramLink: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    job?: string;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    kvkkApproved: boolean;
    /**
     *
     * @type {string}
     * @memberof User
     */
    linkedInLink: string;
    /**
     *
     * @type {boolean}
     * @memberof User
     */
    locked: boolean;
    /**
     *
     * @type {number}
     * @memberof User
     */
    membershipNumber?: number;
    /**
     *
     * @type {string}
     * @memberof User
     */
    membershipType: User.MembershipTypeEnum;
    /**
     *
     * @type {string}
     * @memberof User
     */
    password: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    role: User.RoleEnum;
    /**
     *
     * @type {string}
     * @memberof User
     */
    surname: string;
    /**
     *
     * @type {Array<Tag>}
     * @memberof User
     */
    tags?: Array<Tag>;
    /**
     *
     * @type {string}
     * @memberof User
     */
    twitterLink: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof User
     */
    websiteLink: string;
}

/**
 * @export
 * @namespace User
 */
export namespace User {
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        DenetimKurulu = <any> 'DenetimKurulu',
        DernekUyesi = <any> 'DernekUyesi',
        DisiplinKurulu = <any> 'DisiplinKurulu',
        SistemYoneticisi = <any> 'SistemYoneticisi',
        YonetimKurulu = <any> 'YonetimKurulu',
        YonetimKuruluBaskani = <any> 'YonetimKuruluBaskani'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Moderator = <any> 'Moderator',
        Subscriber = <any> 'Subscriber',
        User = <any> 'User'
    }
}

/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     *
     * @type {boolean}
     * @memberof UserDTO
     */
    allowDisplayContactInfo: boolean;
    /**
     *
     * @type {Array<string>}
     * @memberof UserDTO
     */
    authorities: Array<UserDTO.AuthoritiesEnum>;
    /**
     *
     * @type {Date}
     * @memberof UserDTO
     */
    birthday?: Date;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    bloodType?: UserDTO.BloodTypeEnum;
    /**
     *
     * @type {boolean}
     * @memberof UserDTO
     */
    bloodTypeConsentApproved: boolean;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    company?: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    email: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    emergencyFirstName: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    emergencyPhoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    emergencySurname: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    facebookLink: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    firstName: string;
    /**
     *
     * @type {number}
     * @memberof UserDTO
     */
    id: number;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    imagePath?: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    instagramLink: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    job?: string;
    /**
     *
     * @type {boolean}
     * @memberof UserDTO
     */
    kvkkApproved: boolean;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    linkedInLink: string;
    /**
     *
     * @type {boolean}
     * @memberof UserDTO
     */
    locked: boolean;
    /**
     *
     * @type {number}
     * @memberof UserDTO
     */
    membershipNumber?: number;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    membershipType: UserDTO.MembershipTypeEnum;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    phoneNumber: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    role: UserDTO.RoleEnum;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    surname: string;
    /**
     *
     * @type {Array<Tag>}
     * @memberof UserDTO
     */
    tags?: Array<Tag>;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    twitterLink: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof UserDTO
     */
    websiteLink: string;
}

/**
 * @export
 * @namespace UserDTO
 */
export namespace UserDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum AuthoritiesEnum {
        Anket = <any> 'Anket',
        Duyuru = <any> 'Duyuru',
        Etkinlik = <any> 'Etkinlik',
        GonderiSilme = <any> 'GonderiSilme',
        KullaniciIslemleri = <any> 'KullaniciIslemleri',
        KullaniciKilitleme = <any> 'KullaniciKilitleme',
        Mac = <any> 'Mac',
        Promosyon = <any> 'Promosyon',
        Sikayet = <any> 'Sikayet',
        UygulamaMesaji = <any> 'UygulamaMesaji'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum BloodTypeEnum {
        RhN0 = <any> 'RhN0',
        RhNA = <any> 'RhNA',
        RhNAB = <any> 'RhNAB',
        RhNB = <any> 'RhNB',
        RhP0 = <any> 'RhP0',
        RhPA = <any> 'RhPA',
        RhPAB = <any> 'RhPAB',
        RhPB = <any> 'RhPB'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum MembershipTypeEnum {
        DenetimKurulu = <any> 'DenetimKurulu',
        DernekUyesi = <any> 'DernekUyesi',
        DisiplinKurulu = <any> 'DisiplinKurulu',
        SistemYoneticisi = <any> 'SistemYoneticisi',
        YonetimKurulu = <any> 'YonetimKurulu',
        YonetimKuruluBaskani = <any> 'YonetimKuruluBaskani'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum RoleEnum {
        Admin = <any> 'Admin',
        Moderator = <any> 'Moderator',
        Subscriber = <any> 'Subscriber',
        User = <any> 'User'
    }
}

/**
 *
 * @export
 * @interface UserNotificationDTO
 */
export interface UserNotificationDTO {
    /**
     *
     * @type {number}
     * @memberof UserNotificationDTO
     */
    id: number;
    /**
     *
     * @type {boolean}
     * @memberof UserNotificationDTO
     */
    read: boolean;
    /**
     *
     * @type {number}
     * @memberof UserNotificationDTO
     */
    relatedEntityId?: number;
    /**
     *
     * @type {number}
     * @memberof UserNotificationDTO
     */
    relatedUserId?: number;
    /**
     *
     * @type {string}
     * @memberof UserNotificationDTO
     */
    text: string;
    /**
     *
     * @type {string}
     * @memberof UserNotificationDTO
     */
    type: UserNotificationDTO.TypeEnum;
    /**
     *
     * @type {number}
     * @memberof UserNotificationDTO
     */
    userId?: number;
}

/**
 * @export
 * @namespace UserNotificationDTO
 */
export namespace UserNotificationDTO {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        ANNOUNCEMENTADD = <any> 'ANNOUNCEMENT_ADD',
        BIRTHDAYLIKE = <any> 'BIRTHDAY_LIKE',
        POSTCOMMENT = <any> 'POST_COMMENT',
        POSTLIKE = <any> 'POST_LIKE'
    }
}

/**
 *
 * @export
 * @interface VoteDTO
 */
export interface VoteDTO {
    /**
     *
     * @type {Date}
     * @memberof VoteDTO
     */
    date: Date;
    /**
     *
     * @type {number}
     * @memberof VoteDTO
     */
    id: number;
    /**
     *
     * @type {PollOption}
     * @memberof VoteDTO
     */
    option: PollOption;
    /**
     *
     * @type {IssuerDTO}
     * @memberof VoteDTO
     */
    user: IssuerDTO;
}


/**
 * AbuseReportControllerApi - fetch parameter creator
 * @export
 */
export const AbuseReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAbuseReport
         * @param {AddAbuseReportDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbuseReportUsingPOST(dto: AddAbuseReportDTO, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling addAbuseReportUsingPOST.');
            }
            const localVarPath = `/api/abuse/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAbuseReportDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAbuseReports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbuseReportsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/abuse/reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary processAbuseReport
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAbuseReportUsingPUT(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling processAbuseReportUsingPUT.');
            }
            const localVarPath = `/api/abuse/process/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AbuseReportControllerApi - functional programming interface
 * @export
 */
export const AbuseReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAbuseReport
         * @param {AddAbuseReportDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbuseReportUsingPOST(dto: AddAbuseReportDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AbuseReportControllerApiFetchParamCreator(configuration).addAbuseReportUsingPOST(dto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAbuseReports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbuseReportsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAbuseReportDTO> {
            const localVarFetchArgs = AbuseReportControllerApiFetchParamCreator(configuration).getAbuseReportsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary processAbuseReport
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAbuseReportUsingPUT(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AbuseReportControllerApiFetchParamCreator(configuration).processAbuseReportUsingPUT(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AbuseReportControllerApi - factory interface
 * @export
 */
export const AbuseReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addAbuseReport
         * @param {AddAbuseReportDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAbuseReportUsingPOST(dto: AddAbuseReportDTO, options?: any) {
            return AbuseReportControllerApiFp(configuration).addAbuseReportUsingPOST(dto, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAbuseReports
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAbuseReportsUsingGET(options?: any) {
            return AbuseReportControllerApiFp(configuration).getAbuseReportsUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary processAbuseReport
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processAbuseReportUsingPUT(id: number, options?: any) {
            return AbuseReportControllerApiFp(configuration).processAbuseReportUsingPUT(id, options)(fetch, basePath);
        },
    };
};

/**
 * AbuseReportControllerApi - object-oriented interface
 * @export
 * @class AbuseReportControllerApi
 * @extends {BaseAPI}
 */
export class AbuseReportControllerApi extends BaseAPI {
    /**
     *
     * @summary addAbuseReport
     * @param {AddAbuseReportDTO} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbuseReportControllerApi
     */
    public addAbuseReportUsingPOST(dto: AddAbuseReportDTO, options?: any) {
        return AbuseReportControllerApiFp(this.configuration).addAbuseReportUsingPOST(dto, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAbuseReports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbuseReportControllerApi
     */
    public getAbuseReportsUsingGET(options?: any) {
        return AbuseReportControllerApiFp(this.configuration).getAbuseReportsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary processAbuseReport
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AbuseReportControllerApi
     */
    public processAbuseReportUsingPUT(id: number, options?: any) {
        return AbuseReportControllerApiFp(this.configuration).processAbuseReportUsingPUT(id, options)(this.fetch, this.basePath);
    }

}

/**
 * AnnouncementControllerApi - fetch parameter creator
 * @export
 */
export const AnnouncementControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAnnouncement
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnouncementUsingPOST(description?: string, file?: any, title?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/announcements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary addBloodAnnouncement
         * @param {AddBloodAnnouncementDTO} announcement announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBloodAnnouncementUsingPOST(announcement: AddBloodAnnouncementDTO, options: any = {}): FetchArgs {
            // verify required parameter 'announcement' is not null or undefined
            if (announcement === null || announcement === undefined) {
                throw new RequiredError('announcement','Required parameter announcement was null or undefined when calling addBloodAnnouncementUsingPOST.');
            }
            const localVarPath = `/api/announcements/blood`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddBloodAnnouncementDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(announcement || {}) : (announcement || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncementUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAnnouncementUsingDELETE.');
            }
            const localVarPath = `/api/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBloodAnnouncementUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteBloodAnnouncementUsingDELETE.');
            }
            const localVarPath = `/api/announcements/blood/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getAnnouncementUsingGET.');
            }
            const localVarPath = `/api/announcement/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAnnouncements
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getAnnouncementsUsingGET.');
            }
            const localVarPath = `/api/announcements`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getBloodAnnouncementUsingGET.');
            }
            const localVarPath = `/api/announcement/blood/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getBloodAnnouncements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/announcements/blood`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary likeAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeAnnouncementUsingPOST(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likeAnnouncementUsingPOST.');
            }
            const localVarPath = `/api/announcements/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateAnnouncement
         * @param {EditAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncementUsingPUT(announcement: EditAnnouncementDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'announcement' is not null or undefined
            if (announcement === null || announcement === undefined) {
                throw new RequiredError('announcement','Required parameter announcement was null or undefined when calling updateAnnouncementUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateAnnouncementUsingPUT.');
            }
            const localVarPath = `/api/announcements/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditAnnouncementDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(announcement || {}) : (announcement || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateBloodAnnouncement
         * @param {EditBloodAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBloodAnnouncementUsingPUT(announcement: EditBloodAnnouncementDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'announcement' is not null or undefined
            if (announcement === null || announcement === undefined) {
                throw new RequiredError('announcement','Required parameter announcement was null or undefined when calling updateBloodAnnouncementUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateBloodAnnouncementUsingPUT.');
            }
            const localVarPath = `/api/announcements/blood/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditBloodAnnouncementDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(announcement || {}) : (announcement || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnouncementControllerApi - functional programming interface
 * @export
 */
export const AnnouncementControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAnnouncement
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnouncementUsingPOST(description?: string, file?: any, title?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).addAnnouncementUsingPOST(description, file, title, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary addBloodAnnouncement
         * @param {AddBloodAnnouncementDTO} announcement announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBloodAnnouncementUsingPOST(announcement: AddBloodAnnouncementDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).addBloodAnnouncementUsingPOST(announcement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncementUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).deleteAnnouncementUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBloodAnnouncementUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).deleteBloodAnnouncementUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOAnnouncementDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).getAnnouncementUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAnnouncements
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListAnnouncementDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).getAnnouncementsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOBloodAnnouncementDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).getBloodAnnouncementUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getBloodAnnouncements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListBloodAnnouncementDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).getBloodAnnouncementsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary likeAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeAnnouncementUsingPOST(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).likeAnnouncementUsingPOST(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateAnnouncement
         * @param {EditAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncementUsingPUT(announcement: EditAnnouncementDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).updateAnnouncementUsingPUT(announcement, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateBloodAnnouncement
         * @param {EditBloodAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBloodAnnouncementUsingPUT(announcement: EditBloodAnnouncementDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AnnouncementControllerApiFetchParamCreator(configuration).updateBloodAnnouncementUsingPUT(announcement, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AnnouncementControllerApi - factory interface
 * @export
 */
export const AnnouncementControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addAnnouncement
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAnnouncementUsingPOST(description?: string, file?: any, title?: string, options?: any) {
            return AnnouncementControllerApiFp(configuration).addAnnouncementUsingPOST(description, file, title, options)(fetch, basePath);
        },
        /**
         *
         * @summary addBloodAnnouncement
         * @param {AddBloodAnnouncementDTO} announcement announcement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addBloodAnnouncementUsingPOST(announcement: AddBloodAnnouncementDTO, options?: any) {
            return AnnouncementControllerApiFp(configuration).addBloodAnnouncementUsingPOST(announcement, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnouncementUsingDELETE(id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).deleteAnnouncementUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBloodAnnouncementUsingDELETE(id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).deleteBloodAnnouncementUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementUsingGET(id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).getAnnouncementUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAnnouncements
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnouncementsUsingGET(pageNumber: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).getAnnouncementsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getBloodAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementUsingGET(id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).getBloodAnnouncementUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getBloodAnnouncements
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBloodAnnouncementsUsingGET(options?: any) {
            return AnnouncementControllerApiFp(configuration).getBloodAnnouncementsUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary likeAnnouncement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeAnnouncementUsingPOST(id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).likeAnnouncementUsingPOST(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateAnnouncement
         * @param {EditAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAnnouncementUsingPUT(announcement: EditAnnouncementDTO, id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).updateAnnouncementUsingPUT(announcement, id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateBloodAnnouncement
         * @param {EditBloodAnnouncementDTO} announcement announcement
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBloodAnnouncementUsingPUT(announcement: EditBloodAnnouncementDTO, id: number, options?: any) {
            return AnnouncementControllerApiFp(configuration).updateBloodAnnouncementUsingPUT(announcement, id, options)(fetch, basePath);
        },
    };
};

/**
 * AnnouncementControllerApi - object-oriented interface
 * @export
 * @class AnnouncementControllerApi
 * @extends {BaseAPI}
 */
export class AnnouncementControllerApi extends BaseAPI {
    /**
     *
     * @summary addAnnouncement
     * @param {string} [description]
     * @param {any} [file]
     * @param {string} [title]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public addAnnouncementUsingPOST(description?: string, file?: any, title?: string, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).addAnnouncementUsingPOST(description, file, title, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary addBloodAnnouncement
     * @param {AddBloodAnnouncementDTO} announcement announcement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public addBloodAnnouncementUsingPOST(announcement: AddBloodAnnouncementDTO, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).addBloodAnnouncementUsingPOST(announcement, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteAnnouncement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public deleteAnnouncementUsingDELETE(id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).deleteAnnouncementUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteBloodAnnouncement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public deleteBloodAnnouncementUsingDELETE(id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).deleteBloodAnnouncementUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAnnouncement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getAnnouncementUsingGET(id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).getAnnouncementUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAnnouncements
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getAnnouncementsUsingGET(pageNumber: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).getAnnouncementsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getBloodAnnouncement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getBloodAnnouncementUsingGET(id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).getBloodAnnouncementUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getBloodAnnouncements
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public getBloodAnnouncementsUsingGET(options?: any) {
        return AnnouncementControllerApiFp(this.configuration).getBloodAnnouncementsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary likeAnnouncement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public likeAnnouncementUsingPOST(id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).likeAnnouncementUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateAnnouncement
     * @param {EditAnnouncementDTO} announcement announcement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public updateAnnouncementUsingPUT(announcement: EditAnnouncementDTO, id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).updateAnnouncementUsingPUT(announcement, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateBloodAnnouncement
     * @param {EditBloodAnnouncementDTO} announcement announcement
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnouncementControllerApi
     */
    public updateBloodAnnouncementUsingPUT(announcement: EditBloodAnnouncementDTO, id: number, options?: any) {
        return AnnouncementControllerApiFp(this.configuration).updateBloodAnnouncementUsingPUT(announcement, id, options)(this.fetch, this.basePath);
    }

}

/**
 * AppMessageControllerApi - fetch parameter creator
 * @export
 */
export const AppMessageControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAppMessage
         * @param {AddAppMessageDTO} appMessage appMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppMessageUsingPOST(appMessage: AddAppMessageDTO, options: any = {}): FetchArgs {
            // verify required parameter 'appMessage' is not null or undefined
            if (appMessage === null || appMessage === undefined) {
                throw new RequiredError('appMessage','Required parameter appMessage was null or undefined when calling addAppMessageUsingPOST.');
            }
            const localVarPath = `/api/appMessage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAppMessageDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(appMessage || {}) : (appMessage || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteAppMessage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppMessageUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAppMessageUsingDELETE.');
            }
            const localVarPath = `/api/appMessage/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllAppMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAppMessagesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/appMessage`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAppMessageByEnum
         * @param {'OpeningMessage'} messageEnum messageEnum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMessageByEnumUsingGET(messageEnum: 'OpeningMessage', options: any = {}): FetchArgs {
            // verify required parameter 'messageEnum' is not null or undefined
            if (messageEnum === null || messageEnum === undefined) {
                throw new RequiredError('messageEnum','Required parameter messageEnum was null or undefined when calling getAppMessageByEnumUsingGET.');
            }
            const localVarPath = `/api/appMessage/{messageEnum}`
                .replace(`{${"messageEnum"}}`, encodeURIComponent(String(messageEnum)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateAppMessage
         * @param {EditAppMessageDTO} appMessage appMessage
         * @param {'OpeningMessage'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppMessageUsingPUT(appMessage: EditAppMessageDTO, type: 'OpeningMessage', options: any = {}): FetchArgs {
            // verify required parameter 'appMessage' is not null or undefined
            if (appMessage === null || appMessage === undefined) {
                throw new RequiredError('appMessage','Required parameter appMessage was null or undefined when calling updateAppMessageUsingPUT.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updateAppMessageUsingPUT.');
            }
            const localVarPath = `/api/appMessage/{type}`
                .replace(`{${"type"}}`, encodeURIComponent(String(type)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditAppMessageDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(appMessage || {}) : (appMessage || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppMessageControllerApi - functional programming interface
 * @export
 */
export const AppMessageControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAppMessage
         * @param {AddAppMessageDTO} appMessage appMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppMessageUsingPOST(appMessage: AddAppMessageDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOstring> {
            const localVarFetchArgs = AppMessageControllerApiFetchParamCreator(configuration).addAppMessageUsingPOST(appMessage, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteAppMessage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppMessageUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AppMessageControllerApiFetchParamCreator(configuration).deleteAppMessageUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllAppMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAppMessagesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAppMessageDTO> {
            const localVarFetchArgs = AppMessageControllerApiFetchParamCreator(configuration).getAllAppMessagesUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAppMessageByEnum
         * @param {'OpeningMessage'} messageEnum messageEnum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMessageByEnumUsingGET(messageEnum: 'OpeningMessage', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOAppMessageDTO> {
            const localVarFetchArgs = AppMessageControllerApiFetchParamCreator(configuration).getAppMessageByEnumUsingGET(messageEnum, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateAppMessage
         * @param {EditAppMessageDTO} appMessage appMessage
         * @param {'OpeningMessage'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppMessageUsingPUT(appMessage: EditAppMessageDTO, type: 'OpeningMessage', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = AppMessageControllerApiFetchParamCreator(configuration).updateAppMessageUsingPUT(appMessage, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AppMessageControllerApi - factory interface
 * @export
 */
export const AppMessageControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addAppMessage
         * @param {AddAppMessageDTO} appMessage appMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAppMessageUsingPOST(appMessage: AddAppMessageDTO, options?: any) {
            return AppMessageControllerApiFp(configuration).addAppMessageUsingPOST(appMessage, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteAppMessage
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppMessageUsingDELETE(id: number, options?: any) {
            return AppMessageControllerApiFp(configuration).deleteAppMessageUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllAppMessages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAppMessagesUsingGET(options?: any) {
            return AppMessageControllerApiFp(configuration).getAllAppMessagesUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary getAppMessageByEnum
         * @param {'OpeningMessage'} messageEnum messageEnum
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppMessageByEnumUsingGET(messageEnum: 'OpeningMessage', options?: any) {
            return AppMessageControllerApiFp(configuration).getAppMessageByEnumUsingGET(messageEnum, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateAppMessage
         * @param {EditAppMessageDTO} appMessage appMessage
         * @param {'OpeningMessage'} type type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAppMessageUsingPUT(appMessage: EditAppMessageDTO, type: 'OpeningMessage', options?: any) {
            return AppMessageControllerApiFp(configuration).updateAppMessageUsingPUT(appMessage, type, options)(fetch, basePath);
        },
    };
};

/**
 * AppMessageControllerApi - object-oriented interface
 * @export
 * @class AppMessageControllerApi
 * @extends {BaseAPI}
 */
export class AppMessageControllerApi extends BaseAPI {
    /**
     *
     * @summary addAppMessage
     * @param {AddAppMessageDTO} appMessage appMessage
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppMessageControllerApi
     */
    public addAppMessageUsingPOST(appMessage: AddAppMessageDTO, options?: any) {
        return AppMessageControllerApiFp(this.configuration).addAppMessageUsingPOST(appMessage, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteAppMessage
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppMessageControllerApi
     */
    public deleteAppMessageUsingDELETE(id: number, options?: any) {
        return AppMessageControllerApiFp(this.configuration).deleteAppMessageUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllAppMessages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppMessageControllerApi
     */
    public getAllAppMessagesUsingGET(options?: any) {
        return AppMessageControllerApiFp(this.configuration).getAllAppMessagesUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAppMessageByEnum
     * @param {'OpeningMessage'} messageEnum messageEnum
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppMessageControllerApi
     */
    public getAppMessageByEnumUsingGET(messageEnum: 'OpeningMessage', options?: any) {
        return AppMessageControllerApiFp(this.configuration).getAppMessageByEnumUsingGET(messageEnum, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateAppMessage
     * @param {EditAppMessageDTO} appMessage appMessage
     * @param {'OpeningMessage'} type type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppMessageControllerApi
     */
    public updateAppMessageUsingPUT(appMessage: EditAppMessageDTO, type: 'OpeningMessage', options?: any) {
        return AppMessageControllerApiFp(this.configuration).updateAppMessageUsingPUT(appMessage, type, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthControllerApi - fetch parameter creator
 * @export
 */
export const AuthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary authenticateUser
         * @param {LoginRequestDTO} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(loginRequest: LoginRequestDTO, options: any = {}): FetchArgs {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling authenticateUserUsingPOST.');
            }
            const localVarPath = `/api/auth/signin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LoginRequestDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(loginRequest || {}) : (loginRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary authenticateUser
         * @param {LoginRequestDTO} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(loginRequest: LoginRequestDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateUserUsingPOST(loginRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary authenticateUser
         * @param {LoginRequestDTO} loginRequest loginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(loginRequest: LoginRequestDTO, options?: any) {
            return AuthControllerApiFp(configuration).authenticateUserUsingPOST(loginRequest, options)(fetch, basePath);
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     *
     * @summary authenticateUser
     * @param {LoginRequestDTO} loginRequest loginRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserUsingPOST(loginRequest: LoginRequestDTO, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserUsingPOST(loginRequest, options)(this.fetch, this.basePath);
    }

}

/**
 * LoggerControllerApi - fetch parameter creator
 * @export
 */
export const LoggerControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary getLogs
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsUsingGET(endDate: string, pageNumber: number, startDate: string, options: any = {}): FetchArgs {
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling getLogsUsingGET.');
            }
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getLogsUsingGET.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling getLogsUsingGET.');
            }
            const localVarPath = `/api/logs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoggerControllerApi - functional programming interface
 * @export
 */
export const LoggerControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary getLogs
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListLogDTO> {
            const localVarFetchArgs = LoggerControllerApiFetchParamCreator(configuration).getLogsUsingGET(endDate, pageNumber, startDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LoggerControllerApi - factory interface
 * @export
 */
export const LoggerControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary getLogs
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any) {
            return LoggerControllerApiFp(configuration).getLogsUsingGET(endDate, pageNumber, startDate, options)(fetch, basePath);
        },
    };
};

/**
 * LoggerControllerApi - object-oriented interface
 * @export
 * @class LoggerControllerApi
 * @extends {BaseAPI}
 */
export class LoggerControllerApi extends BaseAPI {
    /**
     *
     * @summary getLogs
     * @param {string} endDate endDate
     * @param {number} pageNumber pageNumber
     * @param {string} startDate startDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoggerControllerApi
     */
    public getLogsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any) {
        return LoggerControllerApiFp(this.configuration).getLogsUsingGET(endDate, pageNumber, startDate, options)(this.fetch, this.basePath);
    }

}

/**
 * MatchControllerApi - fetch parameter creator
 * @export
 */
export const MatchControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addMatch
         * @param {AddMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMatchUsingPOST(match: AddMatchDTO, options: any = {}): FetchArgs {
            // verify required parameter 'match' is not null or undefined
            if (match === null || match === undefined) {
                throw new RequiredError('match','Required parameter match was null or undefined when calling addMatchUsingPOST.');
            }
            const localVarPath = `/api/matches`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddMatchDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(match || {}) : (match || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteMatch
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatchUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteMatchUsingDELETE.');
            }
            const localVarPath = `/api/matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getMatchesByUserId
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesByUserIdUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getMatchesByUserIdUsingGET.');
            }
            const localVarPath = `/api/matches/user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getMatches
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getMatchesUsingGET.');
            }
            const localVarPath = `/api/matches`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary setMatchScore
         * @param {number} id id
         * @param {SetMatchScoreDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMatchScoreUsingPUT(id: number, match: SetMatchScoreDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setMatchScoreUsingPUT.');
            }
            // verify required parameter 'match' is not null or undefined
            if (match === null || match === undefined) {
                throw new RequiredError('match','Required parameter match was null or undefined when calling setMatchScoreUsingPUT.');
            }
            const localVarPath = `/api/matches/score/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SetMatchScoreDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(match || {}) : (match || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateMatch
         * @param {number} id id
         * @param {EditMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatchUsingPUT(id: number, match: EditMatchDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateMatchUsingPUT.');
            }
            // verify required parameter 'match' is not null or undefined
            if (match === null || match === undefined) {
                throw new RequiredError('match','Required parameter match was null or undefined when calling updateMatchUsingPUT.');
            }
            const localVarPath = `/api/matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditMatchDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(match || {}) : (match || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchControllerApi - functional programming interface
 * @export
 */
export const MatchControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addMatch
         * @param {AddMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMatchUsingPOST(match: AddMatchDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).addMatchUsingPOST(match, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteMatch
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatchUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).deleteMatchUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getMatchesByUserId
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesByUserIdUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListMatchDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).getMatchesByUserIdUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getMatches
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListMatchDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).getMatchesUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary setMatchScore
         * @param {number} id id
         * @param {SetMatchScoreDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMatchScoreUsingPUT(id: number, match: SetMatchScoreDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).setMatchScoreUsingPUT(id, match, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateMatch
         * @param {number} id id
         * @param {EditMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatchUsingPUT(id: number, match: EditMatchDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MatchControllerApiFetchParamCreator(configuration).updateMatchUsingPUT(id, match, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MatchControllerApi - factory interface
 * @export
 */
export const MatchControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addMatch
         * @param {AddMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMatchUsingPOST(match: AddMatchDTO, options?: any) {
            return MatchControllerApiFp(configuration).addMatchUsingPOST(match, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteMatch
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatchUsingDELETE(id: number, options?: any) {
            return MatchControllerApiFp(configuration).deleteMatchUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getMatchesByUserId
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesByUserIdUsingGET(pageNumber: number, options?: any) {
            return MatchControllerApiFp(configuration).getMatchesByUserIdUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getMatches
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchesUsingGET(pageNumber: number, options?: any) {
            return MatchControllerApiFp(configuration).getMatchesUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary setMatchScore
         * @param {number} id id
         * @param {SetMatchScoreDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMatchScoreUsingPUT(id: number, match: SetMatchScoreDTO, options?: any) {
            return MatchControllerApiFp(configuration).setMatchScoreUsingPUT(id, match, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateMatch
         * @param {number} id id
         * @param {EditMatchDTO} match match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatchUsingPUT(id: number, match: EditMatchDTO, options?: any) {
            return MatchControllerApiFp(configuration).updateMatchUsingPUT(id, match, options)(fetch, basePath);
        },
    };
};

/**
 * MatchControllerApi - object-oriented interface
 * @export
 * @class MatchControllerApi
 * @extends {BaseAPI}
 */
export class MatchControllerApi extends BaseAPI {
    /**
     *
     * @summary addMatch
     * @param {AddMatchDTO} match match
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public addMatchUsingPOST(match: AddMatchDTO, options?: any) {
        return MatchControllerApiFp(this.configuration).addMatchUsingPOST(match, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteMatch
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public deleteMatchUsingDELETE(id: number, options?: any) {
        return MatchControllerApiFp(this.configuration).deleteMatchUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getMatchesByUserId
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public getMatchesByUserIdUsingGET(pageNumber: number, options?: any) {
        return MatchControllerApiFp(this.configuration).getMatchesByUserIdUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getMatches
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public getMatchesUsingGET(pageNumber: number, options?: any) {
        return MatchControllerApiFp(this.configuration).getMatchesUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary setMatchScore
     * @param {number} id id
     * @param {SetMatchScoreDTO} match match
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public setMatchScoreUsingPUT(id: number, match: SetMatchScoreDTO, options?: any) {
        return MatchControllerApiFp(this.configuration).setMatchScoreUsingPUT(id, match, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateMatch
     * @param {number} id id
     * @param {EditMatchDTO} match match
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchControllerApi
     */
    public updateMatchUsingPUT(id: number, match: EditMatchDTO, options?: any) {
        return MatchControllerApiFp(this.configuration).updateMatchUsingPUT(id, match, options)(this.fetch, this.basePath);
    }

}

/**
 * MeetingEventControllerApi - fetch parameter creator
 * @export
 */
export const MeetingEventControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttendanceUsingPOST(count: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'count' is not null or undefined
            if (count === null || count === undefined) {
                throw new RequiredError('count','Required parameter count was null or undefined when calling addAttendanceUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addAttendanceUsingPOST.');
            }
            const localVarPath = `/api/event/{id}/attend/{count}`
                .replace(`{${"count"}}`, encodeURIComponent(String(count)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary addEvent
         * @param {AddMeetingEventDTO} addMeetingEventDTO addMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventUsingPOST(addMeetingEventDTO: AddMeetingEventDTO, options: any = {}): FetchArgs {
            // verify required parameter 'addMeetingEventDTO' is not null or undefined
            if (addMeetingEventDTO === null || addMeetingEventDTO === undefined) {
                throw new RequiredError('addMeetingEventDTO','Required parameter addMeetingEventDTO was null or undefined when calling addEventUsingPOST.');
            }
            const localVarPath = `/api/event`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddMeetingEventDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(addMeetingEventDTO || {}) : (addMeetingEventDTO || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteEventAttendance
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventAttendanceUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEventAttendanceUsingDELETE.');
            }
            const localVarPath = `/api/event/{id}/attend`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEventUsingDELETE.');
            }
            const localVarPath = `/api/event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllEvents
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getAllEventsUsingGET.');
            }
            const localVarPath = `/api/event`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getEventDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetailUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEventDetailUsingGET.');
            }
            const localVarPath = `/api/event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getEventUsingGET.');
            }
            const localVarPath = `/api/event/{id}/self`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateEventAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventAttendanceUsingPUT(count: number, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'count' is not null or undefined
            if (count === null || count === undefined) {
                throw new RequiredError('count','Required parameter count was null or undefined when calling updateEventAttendanceUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEventAttendanceUsingPUT.');
            }
            const localVarPath = `/api/event/{id}/attended/{count}`
                .replace(`{${"count"}}`, encodeURIComponent(String(count)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateEvent
         * @param {number} id id
         * @param {UpdateMeetingEventDTO} updateMeetingEventDTO updateMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventUsingPUT(id: number, updateMeetingEventDTO: UpdateMeetingEventDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateEventUsingPUT.');
            }
            // verify required parameter 'updateMeetingEventDTO' is not null or undefined
            if (updateMeetingEventDTO === null || updateMeetingEventDTO === undefined) {
                throw new RequiredError('updateMeetingEventDTO','Required parameter updateMeetingEventDTO was null or undefined when calling updateEventUsingPUT.');
            }
            const localVarPath = `/api/event/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateMeetingEventDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updateMeetingEventDTO || {}) : (updateMeetingEventDTO || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MeetingEventControllerApi - functional programming interface
 * @export
 */
export const MeetingEventControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttendanceUsingPOST(count: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).addAttendanceUsingPOST(count, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary addEvent
         * @param {AddMeetingEventDTO} addMeetingEventDTO addMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventUsingPOST(addMeetingEventDTO: AddMeetingEventDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).addEventUsingPOST(addMeetingEventDTO, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteEventAttendance
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventAttendanceUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).deleteEventAttendanceUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).deleteEventUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllEvents
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListMeetingEventDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).getAllEventsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getEventDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetailUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOMeetingEventDetailDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).getEventDetailUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOMeetingEventDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).getEventUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateEventAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventAttendanceUsingPUT(count: number, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).updateEventAttendanceUsingPUT(count, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateEvent
         * @param {number} id id
         * @param {UpdateMeetingEventDTO} updateMeetingEventDTO updateMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventUsingPUT(id: number, updateMeetingEventDTO: UpdateMeetingEventDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = MeetingEventControllerApiFetchParamCreator(configuration).updateEventUsingPUT(id, updateMeetingEventDTO, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MeetingEventControllerApi - factory interface
 * @export
 */
export const MeetingEventControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAttendanceUsingPOST(count: number, id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).addAttendanceUsingPOST(count, id, options)(fetch, basePath);
        },
        /**
         *
         * @summary addEvent
         * @param {AddMeetingEventDTO} addMeetingEventDTO addMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEventUsingPOST(addMeetingEventDTO: AddMeetingEventDTO, options?: any) {
            return MeetingEventControllerApiFp(configuration).addEventUsingPOST(addMeetingEventDTO, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteEventAttendance
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventAttendanceUsingDELETE(id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).deleteEventAttendanceUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventUsingDELETE(id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).deleteEventUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllEvents
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEventsUsingGET(pageNumber: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).getAllEventsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getEventDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventDetailUsingGET(id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).getEventDetailUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getEvent
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventUsingGET(id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).getEventUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateEventAttendance
         * @param {number} count count
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventAttendanceUsingPUT(count: number, id: number, options?: any) {
            return MeetingEventControllerApiFp(configuration).updateEventAttendanceUsingPUT(count, id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateEvent
         * @param {number} id id
         * @param {UpdateMeetingEventDTO} updateMeetingEventDTO updateMeetingEventDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventUsingPUT(id: number, updateMeetingEventDTO: UpdateMeetingEventDTO, options?: any) {
            return MeetingEventControllerApiFp(configuration).updateEventUsingPUT(id, updateMeetingEventDTO, options)(fetch, basePath);
        },
    };
};

/**
 * MeetingEventControllerApi - object-oriented interface
 * @export
 * @class MeetingEventControllerApi
 * @extends {BaseAPI}
 */
export class MeetingEventControllerApi extends BaseAPI {
    /**
     *
     * @summary addAttendance
     * @param {number} count count
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public addAttendanceUsingPOST(count: number, id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).addAttendanceUsingPOST(count, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary addEvent
     * @param {AddMeetingEventDTO} addMeetingEventDTO addMeetingEventDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public addEventUsingPOST(addMeetingEventDTO: AddMeetingEventDTO, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).addEventUsingPOST(addMeetingEventDTO, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteEventAttendance
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public deleteEventAttendanceUsingDELETE(id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).deleteEventAttendanceUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteEvent
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public deleteEventUsingDELETE(id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).deleteEventUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllEvents
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public getAllEventsUsingGET(pageNumber: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).getAllEventsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getEventDetail
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public getEventDetailUsingGET(id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).getEventDetailUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getEvent
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public getEventUsingGET(id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).getEventUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateEventAttendance
     * @param {number} count count
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public updateEventAttendanceUsingPUT(count: number, id: number, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).updateEventAttendanceUsingPUT(count, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateEvent
     * @param {number} id id
     * @param {UpdateMeetingEventDTO} updateMeetingEventDTO updateMeetingEventDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MeetingEventControllerApi
     */
    public updateEventUsingPUT(id: number, updateMeetingEventDTO: UpdateMeetingEventDTO, options?: any) {
        return MeetingEventControllerApiFp(this.configuration).updateEventUsingPUT(id, updateMeetingEventDTO, options)(this.fetch, this.basePath);
    }

}

/**
 * PollControllerApi - fetch parameter creator
 * @export
 */
export const PollControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPoll
         * @param {AddPollDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPollUsingPOST(dto: AddPollDTO, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling addPollUsingPOST.');
            }
            const localVarPath = `/api/poll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddPollDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary addVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoteUsingPOST(option: number, pollId: number, options: any = {}): FetchArgs {
            // verify required parameter 'option' is not null or undefined
            if (option === null || option === undefined) {
                throw new RequiredError('option','Required parameter option was null or undefined when calling addVoteUsingPOST.');
            }
            // verify required parameter 'pollId' is not null or undefined
            if (pollId === null || pollId === undefined) {
                throw new RequiredError('pollId','Required parameter pollId was null or undefined when calling addVoteUsingPOST.');
            }
            const localVarPath = `/api/poll/{pollId}/option/{option}`
                .replace(`{${"option"}}`, encodeURIComponent(String(option)))
                .replace(`{${"pollId"}}`, encodeURIComponent(String(pollId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deletePoll
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePollUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePollUsingDELETE.');
            }
            const localVarPath = `/api/poll/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteVote
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVoteUsingDELETE(pollId: number, options: any = {}): FetchArgs {
            // verify required parameter 'pollId' is not null or undefined
            if (pollId === null || pollId === undefined) {
                throw new RequiredError('pollId','Required parameter pollId was null or undefined when calling deleteVoteUsingDELETE.');
            }
            const localVarPath = `/api/poll/{pollId}`
                .replace(`{${"pollId"}}`, encodeURIComponent(String(pollId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllPools
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoolsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getAllPoolsUsingGET.');
            }
            const localVarPath = `/api/poll`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getMyVote
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVoteUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMyVoteUsingGET.');
            }
            const localVarPath = `/api/poll/{id}/vote`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getPollDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPollDetailUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPollDetailUsingGET.');
            }
            const localVarPath = `/api/poll/{id}/detail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updatePoll
         * @param {number} id id
         * @param {UpdatePollDTO} updatePollDTO updatePollDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePollUsingPUT(id: number, updatePollDTO: UpdatePollDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePollUsingPUT.');
            }
            // verify required parameter 'updatePollDTO' is not null or undefined
            if (updatePollDTO === null || updatePollDTO === undefined) {
                throw new RequiredError('updatePollDTO','Required parameter updatePollDTO was null or undefined when calling updatePollUsingPUT.');
            }
            const localVarPath = `/api/poll/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdatePollDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(updatePollDTO || {}) : (updatePollDTO || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoteUsingPUT(option: number, pollId: number, options: any = {}): FetchArgs {
            // verify required parameter 'option' is not null or undefined
            if (option === null || option === undefined) {
                throw new RequiredError('option','Required parameter option was null or undefined when calling updateVoteUsingPUT.');
            }
            // verify required parameter 'pollId' is not null or undefined
            if (pollId === null || pollId === undefined) {
                throw new RequiredError('pollId','Required parameter pollId was null or undefined when calling updateVoteUsingPUT.');
            }
            const localVarPath = `/api/poll/{pollId}/option/{option}`
                .replace(`{${"option"}}`, encodeURIComponent(String(option)))
                .replace(`{${"pollId"}}`, encodeURIComponent(String(pollId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PollControllerApi - functional programming interface
 * @export
 */
export const PollControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPoll
         * @param {AddPollDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPollUsingPOST(dto: AddPollDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).addPollUsingPOST(dto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary addVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoteUsingPOST(option: number, pollId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).addVoteUsingPOST(option, pollId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deletePoll
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePollUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).deletePollUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteVote
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVoteUsingDELETE(pollId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).deleteVoteUsingDELETE(pollId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllPools
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoolsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListPollDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).getAllPoolsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getMyVote
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVoteUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOVoteDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).getMyVoteUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getPollDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPollDetailUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPollDetailDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).getPollDetailUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updatePoll
         * @param {number} id id
         * @param {UpdatePollDTO} updatePollDTO updatePollDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePollUsingPUT(id: number, updatePollDTO: UpdatePollDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).updatePollUsingPUT(id, updatePollDTO, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoteUsingPUT(option: number, pollId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PollControllerApiFetchParamCreator(configuration).updateVoteUsingPUT(option, pollId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PollControllerApi - factory interface
 * @export
 */
export const PollControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addPoll
         * @param {AddPollDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPollUsingPOST(dto: AddPollDTO, options?: any) {
            return PollControllerApiFp(configuration).addPollUsingPOST(dto, options)(fetch, basePath);
        },
        /**
         *
         * @summary addVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addVoteUsingPOST(option: number, pollId: number, options?: any) {
            return PollControllerApiFp(configuration).addVoteUsingPOST(option, pollId, options)(fetch, basePath);
        },
        /**
         *
         * @summary deletePoll
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePollUsingDELETE(id: number, options?: any) {
            return PollControllerApiFp(configuration).deletePollUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteVote
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVoteUsingDELETE(pollId: number, options?: any) {
            return PollControllerApiFp(configuration).deleteVoteUsingDELETE(pollId, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllPools
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPoolsUsingGET(pageNumber: number, options?: any) {
            return PollControllerApiFp(configuration).getAllPoolsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getMyVote
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyVoteUsingGET(id: number, options?: any) {
            return PollControllerApiFp(configuration).getMyVoteUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getPollDetail
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPollDetailUsingGET(id: number, options?: any) {
            return PollControllerApiFp(configuration).getPollDetailUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updatePoll
         * @param {number} id id
         * @param {UpdatePollDTO} updatePollDTO updatePollDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePollUsingPUT(id: number, updatePollDTO: UpdatePollDTO, options?: any) {
            return PollControllerApiFp(configuration).updatePollUsingPUT(id, updatePollDTO, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateVote
         * @param {number} option option
         * @param {number} pollId pollId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoteUsingPUT(option: number, pollId: number, options?: any) {
            return PollControllerApiFp(configuration).updateVoteUsingPUT(option, pollId, options)(fetch, basePath);
        },
    };
};

/**
 * PollControllerApi - object-oriented interface
 * @export
 * @class PollControllerApi
 * @extends {BaseAPI}
 */
export class PollControllerApi extends BaseAPI {
    /**
     *
     * @summary addPoll
     * @param {AddPollDTO} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public addPollUsingPOST(dto: AddPollDTO, options?: any) {
        return PollControllerApiFp(this.configuration).addPollUsingPOST(dto, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary addVote
     * @param {number} option option
     * @param {number} pollId pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public addVoteUsingPOST(option: number, pollId: number, options?: any) {
        return PollControllerApiFp(this.configuration).addVoteUsingPOST(option, pollId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deletePoll
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public deletePollUsingDELETE(id: number, options?: any) {
        return PollControllerApiFp(this.configuration).deletePollUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteVote
     * @param {number} pollId pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public deleteVoteUsingDELETE(pollId: number, options?: any) {
        return PollControllerApiFp(this.configuration).deleteVoteUsingDELETE(pollId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllPools
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public getAllPoolsUsingGET(pageNumber: number, options?: any) {
        return PollControllerApiFp(this.configuration).getAllPoolsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getMyVote
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public getMyVoteUsingGET(id: number, options?: any) {
        return PollControllerApiFp(this.configuration).getMyVoteUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getPollDetail
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public getPollDetailUsingGET(id: number, options?: any) {
        return PollControllerApiFp(this.configuration).getPollDetailUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updatePoll
     * @param {number} id id
     * @param {UpdatePollDTO} updatePollDTO updatePollDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public updatePollUsingPUT(id: number, updatePollDTO: UpdatePollDTO, options?: any) {
        return PollControllerApiFp(this.configuration).updatePollUsingPUT(id, updatePollDTO, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateVote
     * @param {number} option option
     * @param {number} pollId pollId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PollControllerApi
     */
    public updateVoteUsingPUT(option: number, pollId: number, options?: any) {
        return PollControllerApiFp(this.configuration).updateVoteUsingPUT(option, pollId, options)(this.fetch, this.basePath);
    }

}

/**
 * PostControllerApi - fetch parameter creator
 * @export
 */
export const PostControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPost
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPostUsingPOST(description?: string, file?: any, title?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/post`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary commentPost
         * @param {PostCommentDTO} comment comment
         * @param {number} postId postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentPostUsingPOST(comment: PostCommentDTO, postId: number, options: any = {}): FetchArgs {
            // verify required parameter 'comment' is not null or undefined
            if (comment === null || comment === undefined) {
                throw new RequiredError('comment','Required parameter comment was null or undefined when calling commentPostUsingPOST.');
            }
            // verify required parameter 'postId' is not null or undefined
            if (postId === null || postId === undefined) {
                throw new RequiredError('postId','Required parameter postId was null or undefined when calling commentPostUsingPOST.');
            }
            const localVarPath = `/api/post/{postId}/comment`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PostCommentDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(comment || {}) : (comment || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteAnotherPersonPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnotherPersonPostUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAnotherPersonPostUsingDELETE.');
            }
            const localVarPath = `/api/post/other/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deletePostComment
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostCommentUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePostCommentUsingDELETE.');
            }
            const localVarPath = `/api/post/comment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deletePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePostUsingDELETE.');
            }
            const localVarPath = `/api/post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPostUsingGET.');
            }
            const localVarPath = `/api/get-post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getPosts
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getPostsUsingGET.');
            }
            const localVarPath = `/api/post`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary likePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePostUsingPOST(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likePostUsingPOST.');
            }
            const localVarPath = `/api/post/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updatePost
         * @param {number} id id
         * @param {EditPostDTO} post post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostUsingPUT(id: number, post: EditPostDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePostUsingPUT.');
            }
            // verify required parameter 'post' is not null or undefined
            if (post === null || post === undefined) {
                throw new RequiredError('post','Required parameter post was null or undefined when calling updatePostUsingPUT.');
            }
            const localVarPath = `/api/post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditPostDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(post || {}) : (post || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostControllerApi - functional programming interface
 * @export
 */
export const PostControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPost
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPostUsingPOST(description?: string, file?: any, title?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).addPostUsingPOST(description, file, title, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary commentPost
         * @param {PostCommentDTO} comment comment
         * @param {number} postId postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentPostUsingPOST(comment: PostCommentDTO, postId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).commentPostUsingPOST(comment, postId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteAnotherPersonPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnotherPersonPostUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).deleteAnotherPersonPostUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deletePostComment
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostCommentUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).deletePostCommentUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deletePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).deletePostUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPostDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).getPostUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getPosts
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListPostDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).getPostsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary likePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePostUsingPOST(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).likePostUsingPOST(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updatePost
         * @param {number} id id
         * @param {EditPostDTO} post post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostUsingPUT(id: number, post: EditPostDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PostControllerApiFetchParamCreator(configuration).updatePostUsingPUT(id, post, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PostControllerApi - factory interface
 * @export
 */
export const PostControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addPost
         * @param {string} [description]
         * @param {any} [file]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPostUsingPOST(description?: string, file?: any, title?: string, options?: any) {
            return PostControllerApiFp(configuration).addPostUsingPOST(description, file, title, options)(fetch, basePath);
        },
        /**
         *
         * @summary commentPost
         * @param {PostCommentDTO} comment comment
         * @param {number} postId postId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentPostUsingPOST(comment: PostCommentDTO, postId: number, options?: any) {
            return PostControllerApiFp(configuration).commentPostUsingPOST(comment, postId, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteAnotherPersonPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnotherPersonPostUsingDELETE(id: number, options?: any) {
            return PostControllerApiFp(configuration).deleteAnotherPersonPostUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deletePostComment
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostCommentUsingDELETE(id: number, options?: any) {
            return PostControllerApiFp(configuration).deletePostCommentUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deletePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostUsingDELETE(id: number, options?: any) {
            return PostControllerApiFp(configuration).deletePostUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getPost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostUsingGET(id: number, options?: any) {
            return PostControllerApiFp(configuration).getPostUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getPosts
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostsUsingGET(pageNumber: number, options?: any) {
            return PostControllerApiFp(configuration).getPostsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary likePost
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePostUsingPOST(id: number, options?: any) {
            return PostControllerApiFp(configuration).likePostUsingPOST(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updatePost
         * @param {number} id id
         * @param {EditPostDTO} post post
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostUsingPUT(id: number, post: EditPostDTO, options?: any) {
            return PostControllerApiFp(configuration).updatePostUsingPUT(id, post, options)(fetch, basePath);
        },
    };
};

/**
 * PostControllerApi - object-oriented interface
 * @export
 * @class PostControllerApi
 * @extends {BaseAPI}
 */
export class PostControllerApi extends BaseAPI {
    /**
     *
     * @summary addPost
     * @param {string} [description]
     * @param {any} [file]
     * @param {string} [title]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public addPostUsingPOST(description?: string, file?: any, title?: string, options?: any) {
        return PostControllerApiFp(this.configuration).addPostUsingPOST(description, file, title, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary commentPost
     * @param {PostCommentDTO} comment comment
     * @param {number} postId postId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public commentPostUsingPOST(comment: PostCommentDTO, postId: number, options?: any) {
        return PostControllerApiFp(this.configuration).commentPostUsingPOST(comment, postId, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteAnotherPersonPost
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public deleteAnotherPersonPostUsingDELETE(id: number, options?: any) {
        return PostControllerApiFp(this.configuration).deleteAnotherPersonPostUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deletePostComment
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public deletePostCommentUsingDELETE(id: number, options?: any) {
        return PostControllerApiFp(this.configuration).deletePostCommentUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deletePost
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public deletePostUsingDELETE(id: number, options?: any) {
        return PostControllerApiFp(this.configuration).deletePostUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getPost
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public getPostUsingGET(id: number, options?: any) {
        return PostControllerApiFp(this.configuration).getPostUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getPosts
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public getPostsUsingGET(pageNumber: number, options?: any) {
        return PostControllerApiFp(this.configuration).getPostsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary likePost
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public likePostUsingPOST(id: number, options?: any) {
        return PostControllerApiFp(this.configuration).likePostUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updatePost
     * @param {number} id id
     * @param {EditPostDTO} post post
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostControllerApi
     */
    public updatePostUsingPUT(id: number, post: EditPostDTO, options?: any) {
        return PostControllerApiFp(this.configuration).updatePostUsingPUT(id, post, options)(this.fetch, this.basePath);
    }

}

/**
 * PromotionControllerApi - fetch parameter creator
 * @export
 */
export const PromotionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPromotion
         * @param {string} [description]
         * @param {Date} [endDate]
         * @param {any} [file]
         * @param {Date} [startDate]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionUsingPOST(description?: string, endDate?: Date, file?: any, startDate?: Date, title?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/promotions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any).toISOString();
            }

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any).toISOString();
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deletePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePromotionUsingDELETE.');
            }
            const localVarPath = `/api/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getPromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPromotionUsingGET.');
            }
            const localVarPath = `/api/promotion/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getPromotions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getPromotionsUsingGET.');
            }
            const localVarPath = `/api/promotions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary likePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePromotionUsingPOST(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling likePromotionUsingPOST.');
            }
            const localVarPath = `/api/promotions/{id}/like`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updatePromotion
         * @param {EditPromotionDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromotionUsingPUT(dto: EditPromotionDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling updatePromotionUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updatePromotionUsingPUT.');
            }
            const localVarPath = `/api/promotions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditPromotionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromotionControllerApi - functional programming interface
 * @export
 */
export const PromotionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addPromotion
         * @param {string} [description]
         * @param {Date} [endDate]
         * @param {any} [file]
         * @param {Date} [startDate]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionUsingPOST(description?: string, endDate?: Date, file?: any, startDate?: Date, title?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).addPromotionUsingPOST(description, endDate, file, startDate, title, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deletePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).deletePromotionUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getPromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPromotionDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).getPromotionUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getPromotions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListPromotionDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).getPromotionsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary likePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePromotionUsingPOST(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).likePromotionUsingPOST(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updatePromotion
         * @param {EditPromotionDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromotionUsingPUT(dto: EditPromotionDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = PromotionControllerApiFetchParamCreator(configuration).updatePromotionUsingPUT(dto, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PromotionControllerApi - factory interface
 * @export
 */
export const PromotionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addPromotion
         * @param {string} [description]
         * @param {Date} [endDate]
         * @param {any} [file]
         * @param {Date} [startDate]
         * @param {string} [title]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPromotionUsingPOST(description?: string, endDate?: Date, file?: any, startDate?: Date, title?: string, options?: any) {
            return PromotionControllerApiFp(configuration).addPromotionUsingPOST(description, endDate, file, startDate, title, options)(fetch, basePath);
        },
        /**
         *
         * @summary deletePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromotionUsingDELETE(id: number, options?: any) {
            return PromotionControllerApiFp(configuration).deletePromotionUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getPromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionUsingGET(id: number, options?: any) {
            return PromotionControllerApiFp(configuration).getPromotionUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getPromotions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromotionsUsingGET(pageNumber: number, options?: any) {
            return PromotionControllerApiFp(configuration).getPromotionsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary likePromotion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likePromotionUsingPOST(id: number, options?: any) {
            return PromotionControllerApiFp(configuration).likePromotionUsingPOST(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updatePromotion
         * @param {EditPromotionDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromotionUsingPUT(dto: EditPromotionDTO, id: number, options?: any) {
            return PromotionControllerApiFp(configuration).updatePromotionUsingPUT(dto, id, options)(fetch, basePath);
        },
    };
};

/**
 * PromotionControllerApi - object-oriented interface
 * @export
 * @class PromotionControllerApi
 * @extends {BaseAPI}
 */
export class PromotionControllerApi extends BaseAPI {
    /**
     *
     * @summary addPromotion
     * @param {string} [description]
     * @param {Date} [endDate]
     * @param {any} [file]
     * @param {Date} [startDate]
     * @param {string} [title]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public addPromotionUsingPOST(description?: string, endDate?: Date, file?: any, startDate?: Date, title?: string, options?: any) {
        return PromotionControllerApiFp(this.configuration).addPromotionUsingPOST(description, endDate, file, startDate, title, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deletePromotion
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public deletePromotionUsingDELETE(id: number, options?: any) {
        return PromotionControllerApiFp(this.configuration).deletePromotionUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getPromotion
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public getPromotionUsingGET(id: number, options?: any) {
        return PromotionControllerApiFp(this.configuration).getPromotionUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getPromotions
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public getPromotionsUsingGET(pageNumber: number, options?: any) {
        return PromotionControllerApiFp(this.configuration).getPromotionsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary likePromotion
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public likePromotionUsingPOST(id: number, options?: any) {
        return PromotionControllerApiFp(this.configuration).likePromotionUsingPOST(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updatePromotion
     * @param {EditPromotionDTO} dto dto
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromotionControllerApi
     */
    public updatePromotionUsingPUT(dto: EditPromotionDTO, id: number, options?: any) {
        return PromotionControllerApiFp(this.configuration).updatePromotionUsingPUT(dto, id, options)(this.fetch, this.basePath);
    }

}

/**
 * ScorePredictionControllerApi - fetch parameter creator
 * @export
 */
export const ScorePredictionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addScorePrediction
         * @param {AddScorePredictionDTO} scorePrediction scorePrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScorePredictionUsingPOST(scorePrediction: AddScorePredictionDTO, options: any = {}): FetchArgs {
            // verify required parameter 'scorePrediction' is not null or undefined
            if (scorePrediction === null || scorePrediction === undefined) {
                throw new RequiredError('scorePrediction','Required parameter scorePrediction was null or undefined when calling addScorePredictionUsingPOST.');
            }
            const localVarPath = `/api/matches/score-predictions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddScorePredictionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(scorePrediction || {}) : (scorePrediction || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScorePredictionUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteScorePredictionUsingDELETE.');
            }
            const localVarPath = `/api/matches/score-predictions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getCorrectScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorrectScorePredictionsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getCorrectScorePredictionsUsingGET.');
            }
            const localVarPath = `/api/matches/correct-score-predictions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getMatchScorePredictions
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchScorePredictionsUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMatchScorePredictionsUsingGET.');
            }
            const localVarPath = `/api/matches/{id}/score-predictions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getScorePredictionUsingGET.');
            }
            const localVarPath = `/api/matches/{id}/my-score-prediction`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getScorePredictions
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionsUsingGET(endDate: string, pageNumber: number, startDate: string, options: any = {}): FetchArgs {
            // verify required parameter 'endDate' is not null or undefined
            if (endDate === null || endDate === undefined) {
                throw new RequiredError('endDate','Required parameter endDate was null or undefined when calling getScorePredictionsUsingGET.');
            }
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getScorePredictionsUsingGET.');
            }
            // verify required parameter 'startDate' is not null or undefined
            if (startDate === null || startDate === undefined) {
                throw new RequiredError('startDate','Required parameter startDate was null or undefined when calling getScorePredictionsUsingGET.');
            }
            const localVarPath = `/api/matches/score-predictions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = endDate;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = startDate;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getWrongScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrongScorePredictionsUsingGET(pageNumber: number, pageSize: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getWrongScorePredictionsUsingGET.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling getWrongScorePredictionsUsingGET.');
            }
            const localVarPath = `/api/matches/wrong-score-predictions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateScorePrediction
         * @param {EditScorePredictionDTO} editScorePredictionDTO editScorePredictionDTO
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScorePredictionUsingPUT(editScorePredictionDTO: EditScorePredictionDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'editScorePredictionDTO' is not null or undefined
            if (editScorePredictionDTO === null || editScorePredictionDTO === undefined) {
                throw new RequiredError('editScorePredictionDTO','Required parameter editScorePredictionDTO was null or undefined when calling updateScorePredictionUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateScorePredictionUsingPUT.');
            }
            const localVarPath = `/api/matches/score-predictions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditScorePredictionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(editScorePredictionDTO || {}) : (editScorePredictionDTO || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScorePredictionControllerApi - functional programming interface
 * @export
 */
export const ScorePredictionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addScorePrediction
         * @param {AddScorePredictionDTO} scorePrediction scorePrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScorePredictionUsingPOST(scorePrediction: AddScorePredictionDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).addScorePredictionUsingPOST(scorePrediction, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScorePredictionUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).deleteScorePredictionUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getCorrectScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorrectScorePredictionsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListScorePredictionDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).getCorrectScorePredictionsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getMatchScorePredictions
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchScorePredictionsUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListScorePredictionDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).getMatchScorePredictionsUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOScorePredictionDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).getScorePredictionUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getScorePredictions
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListScorePredictionDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).getScorePredictionsUsingGET(endDate, pageNumber, startDate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getWrongScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrongScorePredictionsUsingGET(pageNumber: number, pageSize: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListScorePredictionDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).getWrongScorePredictionsUsingGET(pageNumber, pageSize, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateScorePrediction
         * @param {EditScorePredictionDTO} editScorePredictionDTO editScorePredictionDTO
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScorePredictionUsingPUT(editScorePredictionDTO: EditScorePredictionDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = ScorePredictionControllerApiFetchParamCreator(configuration).updateScorePredictionUsingPUT(editScorePredictionDTO, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ScorePredictionControllerApi - factory interface
 * @export
 */
export const ScorePredictionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addScorePrediction
         * @param {AddScorePredictionDTO} scorePrediction scorePrediction
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addScorePredictionUsingPOST(scorePrediction: AddScorePredictionDTO, options?: any) {
            return ScorePredictionControllerApiFp(configuration).addScorePredictionUsingPOST(scorePrediction, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScorePredictionUsingDELETE(id: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).deleteScorePredictionUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getCorrectScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorrectScorePredictionsUsingGET(pageNumber: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).getCorrectScorePredictionsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getMatchScorePredictions
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchScorePredictionsUsingGET(id: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).getMatchScorePredictionsUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getScorePrediction
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionUsingGET(id: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).getScorePredictionUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getScorePredictions
         * @param {string} endDate endDate
         * @param {number} pageNumber pageNumber
         * @param {string} startDate startDate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScorePredictionsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any) {
            return ScorePredictionControllerApiFp(configuration).getScorePredictionsUsingGET(endDate, pageNumber, startDate, options)(fetch, basePath);
        },
        /**
         *
         * @summary getWrongScorePredictions
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrongScorePredictionsUsingGET(pageNumber: number, pageSize: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).getWrongScorePredictionsUsingGET(pageNumber, pageSize, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateScorePrediction
         * @param {EditScorePredictionDTO} editScorePredictionDTO editScorePredictionDTO
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateScorePredictionUsingPUT(editScorePredictionDTO: EditScorePredictionDTO, id: number, options?: any) {
            return ScorePredictionControllerApiFp(configuration).updateScorePredictionUsingPUT(editScorePredictionDTO, id, options)(fetch, basePath);
        },
    };
};

/**
 * ScorePredictionControllerApi - object-oriented interface
 * @export
 * @class ScorePredictionControllerApi
 * @extends {BaseAPI}
 */
export class ScorePredictionControllerApi extends BaseAPI {
    /**
     *
     * @summary addScorePrediction
     * @param {AddScorePredictionDTO} scorePrediction scorePrediction
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public addScorePredictionUsingPOST(scorePrediction: AddScorePredictionDTO, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).addScorePredictionUsingPOST(scorePrediction, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteScorePrediction
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public deleteScorePredictionUsingDELETE(id: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).deleteScorePredictionUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getCorrectScorePredictions
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public getCorrectScorePredictionsUsingGET(pageNumber: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).getCorrectScorePredictionsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getMatchScorePredictions
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public getMatchScorePredictionsUsingGET(id: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).getMatchScorePredictionsUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getScorePrediction
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public getScorePredictionUsingGET(id: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).getScorePredictionUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getScorePredictions
     * @param {string} endDate endDate
     * @param {number} pageNumber pageNumber
     * @param {string} startDate startDate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public getScorePredictionsUsingGET(endDate: string, pageNumber: number, startDate: string, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).getScorePredictionsUsingGET(endDate, pageNumber, startDate, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getWrongScorePredictions
     * @param {number} pageNumber pageNumber
     * @param {number} pageSize pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public getWrongScorePredictionsUsingGET(pageNumber: number, pageSize: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).getWrongScorePredictionsUsingGET(pageNumber, pageSize, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateScorePrediction
     * @param {EditScorePredictionDTO} editScorePredictionDTO editScorePredictionDTO
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScorePredictionControllerApi
     */
    public updateScorePredictionUsingPUT(editScorePredictionDTO: EditScorePredictionDTO, id: number, options?: any) {
        return ScorePredictionControllerApiFp(this.configuration).updateScorePredictionUsingPUT(editScorePredictionDTO, id, options)(this.fetch, this.basePath);
    }

}

/**
 * SearchControllerApi - fetch parameter creator
 * @export
 */
export const SearchControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary getSearchResults
         * @param {string} query query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET(query: string, options: any = {}): FetchArgs {
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError('query','Required parameter query was null or undefined when calling getSearchResultsUsingGET.');
            }
            const localVarPath = `/api/search/{query}`
                .replace(`{${"query"}}`, encodeURIComponent(String(query)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchControllerApi - functional programming interface
 * @export
 */
export const SearchControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary getSearchResults
         * @param {string} query query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET(query: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOSearchResultDTO> {
            const localVarFetchArgs = SearchControllerApiFetchParamCreator(configuration).getSearchResultsUsingGET(query, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SearchControllerApi - factory interface
 * @export
 */
export const SearchControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary getSearchResults
         * @param {string} query query
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSearchResultsUsingGET(query: string, options?: any) {
            return SearchControllerApiFp(configuration).getSearchResultsUsingGET(query, options)(fetch, basePath);
        },
    };
};

/**
 * SearchControllerApi - object-oriented interface
 * @export
 * @class SearchControllerApi
 * @extends {BaseAPI}
 */
export class SearchControllerApi extends BaseAPI {
    /**
     *
     * @summary getSearchResults
     * @param {string} query query
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchControllerApi
     */
    public getSearchResultsUsingGET(query: string, options?: any) {
        return SearchControllerApiFp(this.configuration).getSearchResultsUsingGET(query, options)(this.fetch, this.basePath);
    }

}

/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
export const UserControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAuthority
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuthorityUsingPOST(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', id: number, options: any = {}): FetchArgs {
            // verify required parameter 'authority' is not null or undefined
            if (authority === null || authority === undefined) {
                throw new RequiredError('authority','Required parameter authority was null or undefined when calling addAuthorityUsingPOST.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addAuthorityUsingPOST.');
            }
            const localVarPath = `/api/users/{id}/authority/{authority}`
                .replace(`{${"authority"}}`, encodeURIComponent(String(authority)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary addTag
         * @param {AddTagDTO} addTagDTO addTagDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagUsingPOST(addTagDTO: AddTagDTO, options: any = {}): FetchArgs {
            // verify required parameter 'addTagDTO' is not null or undefined
            if (addTagDTO === null || addTagDTO === undefined) {
                throw new RequiredError('addTagDTO','Required parameter addTagDTO was null or undefined when calling addTagUsingPOST.');
            }
            const localVarPath = `/api/add-tag`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddTagDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(addTagDTO || {}) : (addTagDTO || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary approveKvkk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveKvkkUsingPUT(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/approve-kvkk`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary changeAvatar
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAvatarUsingPUT(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/api/users/change-avatar`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (file !== undefined) {
                localVarQueryParameter['file'] = file;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary changeOwnPassword
         * @param {ChangeOwnPasswordDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOwnPasswordUsingPUT(dto: ChangeOwnPasswordDTO, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling changeOwnPasswordUsingPUT.');
            }
            const localVarPath = `/api/users/changeOwnPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangeOwnPasswordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary changePassword
         * @param {ChangePasswordDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPUT(dto: ChangePasswordDTO, id: number, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling changePasswordUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling changePasswordUsingPUT.');
            }
            const localVarPath = `/api/users/{id}/changePassword`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePasswordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthorityUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAuthorityUsingDELETE.');
            }
            const localVarPath = `/api/users/authority/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteTag
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTagUsingDELETE.');
            }
            const localVarPath = `/api/delete-tag/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary deleteUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserUsingDELETE.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllAuthorityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorityTypeUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/authority/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllUsersByBloodType
         * @param {'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB'} bloodType bloodType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersByBloodTypeUsingGET(bloodType: 'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB', options: any = {}): FetchArgs {
            // verify required parameter 'bloodType' is not null or undefined
            if (bloodType === null || bloodType === undefined) {
                throw new RequiredError('bloodType','Required parameter bloodType was null or undefined when calling getAllUsersByBloodTypeUsingGET.');
            }
            const localVarPath = `/api/users/blood/{bloodType}`
                .replace(`{${"bloodType"}}`, encodeURIComponent(String(bloodType)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAllUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getAuthorityUsers
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityUsersUsingGET(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', options: any = {}): FetchArgs {
            // verify required parameter 'authority' is not null or undefined
            if (authority === null || authority === undefined) {
                throw new RequiredError('authority','Required parameter authority was null or undefined when calling getAuthorityUsersUsingGET.');
            }
            const localVarPath = `/api/authority/users/{authority}`
                .replace(`{${"authority"}}`, encodeURIComponent(String(authority)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getBirthdayUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthdayUsersUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/birthday`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getUserAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthorityUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserAuthorityUsingGET.');
            }
            const localVarPath = `/api/users/{id}/authority`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getUserNotifications
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotificationsUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getUserNotificationsUsingGET.');
            }
            const localVarPath = `/api/users/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserUsingGET.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary getUsers
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsingGET(pageNumber: number, options: any = {}): FetchArgs {
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling getUsersUsingGET.');
            }
            const localVarPath = `/api/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary lockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUserUsingPUT(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling lockUserUsingPUT.');
            }
            const localVarPath = `/api/users/{id}/lock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary registerUser
         * @param {SignupRequestDTO} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST(signUpRequest: SignupRequestDTO, options: any = {}): FetchArgs {
            // verify required parameter 'signUpRequest' is not null or undefined
            if (signUpRequest === null || signUpRequest === undefined) {
                throw new RequiredError('signUpRequest','Required parameter signUpRequest was null or undefined when calling registerUserUsingPOST.');
            }
            const localVarPath = `/api/signup`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SignupRequestDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(signUpRequest || {}) : (signUpRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary removeAvatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAvatarUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/remove-avatar`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary unlockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUserUsingPUT(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling unlockUserUsingPUT.');
            }
            const localVarPath = `/api/users/{id}/unlock`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateUserAsSelf
         * @param {EditSelfUserDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSelfUsingPUT(dto: EditSelfUserDTO, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling updateUserAsSelfUsingPUT.');
            }
            const localVarPath = `/api/users/update-self`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditSelfUserDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary updateUser
         * @param {number} id id
         * @param {EditUserDTO} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPUT(id: number, user: EditUserDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateUserUsingPUT.');
            }
            // verify required parameter 'user' is not null or undefined
            if (user === null || user === undefined) {
                throw new RequiredError('user','Required parameter user was null or undefined when calling updateUserUsingPUT.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            localVarUrlObj.search = null;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditUserDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(user || {}) : (user || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         *
         * @summary addAuthority
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuthorityUsingPOST(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).addAuthorityUsingPOST(authority, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary addTag
         * @param {AddTagDTO} addTagDTO addTagDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagUsingPOST(addTagDTO: AddTagDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).addTagUsingPOST(addTagDTO, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary approveKvkk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveKvkkUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).approveKvkkUsingPUT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary changeAvatar
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAvatarUsingPUT(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).changeAvatarUsingPUT(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary changeOwnPassword
         * @param {ChangeOwnPasswordDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOwnPasswordUsingPUT(dto: ChangeOwnPasswordDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).changeOwnPasswordUsingPUT(dto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary changePassword
         * @param {ChangePasswordDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPUT(dto: ChangePasswordDTO, id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).changePasswordUsingPUT(dto, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthorityUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteAuthorityUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteTag
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteTagUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary deleteUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteUserUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllAuthorityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorityTypeUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListstring> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getAllAuthorityTypeUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllUsersByBloodType
         * @param {'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB'} bloodType bloodType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersByBloodTypeUsingGET(bloodType: 'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListUserDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getAllUsersByBloodTypeUsingGET(bloodType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAllUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAllUsersDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getAllUsersUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getAuthorityUsers
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityUsersUsingGET(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAuthorityUsersDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getAuthorityUsersUsingGET(authority, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getBirthdayUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthdayUsersUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAllUsersDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getBirthdayUsersUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getUserAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthorityUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOListAuthorityDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getUserAuthorityUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getUserNotifications
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotificationsUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListUserNotificationDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getUserNotificationsUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOUserDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getUserUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary getUsers
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsingGET(pageNumber: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOPagedEntityListUserDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getUsersUsingGET(pageNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary lockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUserUsingPUT(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).lockUserUsingPUT(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary registerUser
         * @param {SignupRequestDTO} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST(signUpRequest: SignupRequestDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTOSignupResponse> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).registerUserUsingPOST(signUpRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary removeAvatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAvatarUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).removeAvatarUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary unlockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUserUsingPUT(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).unlockUserUsingPUT(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateUserAsSelf
         * @param {EditSelfUserDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSelfUsingPUT(dto: EditSelfUserDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).updateUserAsSelfUsingPUT(dto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         *
         * @summary updateUser
         * @param {number} id id
         * @param {EditUserDTO} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPUT(id: number, user: EditUserDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ResponseDTO> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).updateUserUsingPUT(id, user, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @summary addAuthority
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAuthorityUsingPOST(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', id: number, options?: any) {
            return UserControllerApiFp(configuration).addAuthorityUsingPOST(authority, id, options)(fetch, basePath);
        },
        /**
         *
         * @summary addTag
         * @param {AddTagDTO} addTagDTO addTagDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTagUsingPOST(addTagDTO: AddTagDTO, options?: any) {
            return UserControllerApiFp(configuration).addTagUsingPOST(addTagDTO, options)(fetch, basePath);
        },
        /**
         *
         * @summary approveKvkk
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveKvkkUsingPUT(options?: any) {
            return UserControllerApiFp(configuration).approveKvkkUsingPUT(options)(fetch, basePath);
        },
        /**
         *
         * @summary changeAvatar
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeAvatarUsingPUT(file?: any, options?: any) {
            return UserControllerApiFp(configuration).changeAvatarUsingPUT(file, options)(fetch, basePath);
        },
        /**
         *
         * @summary changeOwnPassword
         * @param {ChangeOwnPasswordDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeOwnPasswordUsingPUT(dto: ChangeOwnPasswordDTO, options?: any) {
            return UserControllerApiFp(configuration).changeOwnPasswordUsingPUT(dto, options)(fetch, basePath);
        },
        /**
         *
         * @summary changePassword
         * @param {ChangePasswordDTO} dto dto
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPUT(dto: ChangePasswordDTO, id: number, options?: any) {
            return UserControllerApiFp(configuration).changePasswordUsingPUT(dto, id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthorityUsingDELETE(id: number, options?: any) {
            return UserControllerApiFp(configuration).deleteAuthorityUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteTag
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTagUsingDELETE(id: number, options?: any) {
            return UserControllerApiFp(configuration).deleteTagUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary deleteUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: number, options?: any) {
            return UserControllerApiFp(configuration).deleteUserUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllAuthorityType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAuthorityTypeUsingGET(options?: any) {
            return UserControllerApiFp(configuration).getAllAuthorityTypeUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllUsersByBloodType
         * @param {'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB'} bloodType bloodType
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersByBloodTypeUsingGET(bloodType: 'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB', options?: any) {
            return UserControllerApiFp(configuration).getAllUsersByBloodTypeUsingGET(bloodType, options)(fetch, basePath);
        },
        /**
         *
         * @summary getAllUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(options?: any) {
            return UserControllerApiFp(configuration).getAllUsersUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary getAuthorityUsers
         * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorityUsersUsingGET(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', options?: any) {
            return UserControllerApiFp(configuration).getAuthorityUsersUsingGET(authority, options)(fetch, basePath);
        },
        /**
         *
         * @summary getBirthdayUsers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBirthdayUsersUsingGET(options?: any) {
            return UserControllerApiFp(configuration).getBirthdayUsersUsingGET(options)(fetch, basePath);
        },
        /**
         *
         * @summary getUserAuthority
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserAuthorityUsingGET(id: number, options?: any) {
            return UserControllerApiFp(configuration).getUserAuthorityUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getUserNotifications
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotificationsUsingGET(pageNumber: number, options?: any) {
            return UserControllerApiFp(configuration).getUserNotificationsUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary getUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: number, options?: any) {
            return UserControllerApiFp(configuration).getUserUsingGET(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary getUsers
         * @param {number} pageNumber pageNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsersUsingGET(pageNumber: number, options?: any) {
            return UserControllerApiFp(configuration).getUsersUsingGET(pageNumber, options)(fetch, basePath);
        },
        /**
         *
         * @summary lockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lockUserUsingPUT(id: number, options?: any) {
            return UserControllerApiFp(configuration).lockUserUsingPUT(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary registerUser
         * @param {SignupRequestDTO} signUpRequest signUpRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserUsingPOST(signUpRequest: SignupRequestDTO, options?: any) {
            return UserControllerApiFp(configuration).registerUserUsingPOST(signUpRequest, options)(fetch, basePath);
        },
        /**
         *
         * @summary removeAvatar
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeAvatarUsingDELETE(options?: any) {
            return UserControllerApiFp(configuration).removeAvatarUsingDELETE(options)(fetch, basePath);
        },
        /**
         *
         * @summary unlockUser
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlockUserUsingPUT(id: number, options?: any) {
            return UserControllerApiFp(configuration).unlockUserUsingPUT(id, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateUserAsSelf
         * @param {EditSelfUserDTO} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserAsSelfUsingPUT(dto: EditSelfUserDTO, options?: any) {
            return UserControllerApiFp(configuration).updateUserAsSelfUsingPUT(dto, options)(fetch, basePath);
        },
        /**
         *
         * @summary updateUser
         * @param {number} id id
         * @param {EditUserDTO} user user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUsingPUT(id: number, user: EditUserDTO, options?: any) {
            return UserControllerApiFp(configuration).updateUserUsingPUT(id, user, options)(fetch, basePath);
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     *
     * @summary addAuthority
     * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public addAuthorityUsingPOST(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', id: number, options?: any) {
        return UserControllerApiFp(this.configuration).addAuthorityUsingPOST(authority, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary addTag
     * @param {AddTagDTO} addTagDTO addTagDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public addTagUsingPOST(addTagDTO: AddTagDTO, options?: any) {
        return UserControllerApiFp(this.configuration).addTagUsingPOST(addTagDTO, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary approveKvkk
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public approveKvkkUsingPUT(options?: any) {
        return UserControllerApiFp(this.configuration).approveKvkkUsingPUT(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary changeAvatar
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public changeAvatarUsingPUT(file?: any, options?: any) {
        return UserControllerApiFp(this.configuration).changeAvatarUsingPUT(file, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary changeOwnPassword
     * @param {ChangeOwnPasswordDTO} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public changeOwnPasswordUsingPUT(dto: ChangeOwnPasswordDTO, options?: any) {
        return UserControllerApiFp(this.configuration).changeOwnPasswordUsingPUT(dto, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary changePassword
     * @param {ChangePasswordDTO} dto dto
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public changePasswordUsingPUT(dto: ChangePasswordDTO, id: number, options?: any) {
        return UserControllerApiFp(this.configuration).changePasswordUsingPUT(dto, id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteAuthority
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteAuthorityUsingDELETE(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).deleteAuthorityUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteTag
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteTagUsingDELETE(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).deleteTagUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary deleteUser
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUserUsingDELETE(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).deleteUserUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllAuthorityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllAuthorityTypeUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getAllAuthorityTypeUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllUsersByBloodType
     * @param {'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB'} bloodType bloodType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllUsersByBloodTypeUsingGET(bloodType: 'RhN0' | 'RhNA' | 'RhNAB' | 'RhNB' | 'RhP0' | 'RhPA' | 'RhPAB' | 'RhPB', options?: any) {
        return UserControllerApiFp(this.configuration).getAllUsersByBloodTypeUsingGET(bloodType, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAllUsers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllUsersUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getAllUsersUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getAuthorityUsers
     * @param {'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji'} authority authority
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAuthorityUsersUsingGET(authority: 'Anket' | 'Duyuru' | 'Etkinlik' | 'GonderiSilme' | 'KullaniciIslemleri' | 'KullaniciKilitleme' | 'Mac' | 'Promosyon' | 'Sikayet' | 'UygulamaMesaji', options?: any) {
        return UserControllerApiFp(this.configuration).getAuthorityUsersUsingGET(authority, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getBirthdayUsers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getBirthdayUsersUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getBirthdayUsersUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getUserAuthority
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserAuthorityUsingGET(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).getUserAuthorityUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getUserNotifications
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserNotificationsUsingGET(pageNumber: number, options?: any) {
        return UserControllerApiFp(this.configuration).getUserNotificationsUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getUser
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserUsingGET(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).getUserUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary getUsers
     * @param {number} pageNumber pageNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUsersUsingGET(pageNumber: number, options?: any) {
        return UserControllerApiFp(this.configuration).getUsersUsingGET(pageNumber, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary lockUser
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public lockUserUsingPUT(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).lockUserUsingPUT(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary registerUser
     * @param {SignupRequestDTO} signUpRequest signUpRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public registerUserUsingPOST(signUpRequest: SignupRequestDTO, options?: any) {
        return UserControllerApiFp(this.configuration).registerUserUsingPOST(signUpRequest, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary removeAvatar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeAvatarUsingDELETE(options?: any) {
        return UserControllerApiFp(this.configuration).removeAvatarUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary unlockUser
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public unlockUserUsingPUT(id: number, options?: any) {
        return UserControllerApiFp(this.configuration).unlockUserUsingPUT(id, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateUserAsSelf
     * @param {EditSelfUserDTO} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUserAsSelfUsingPUT(dto: EditSelfUserDTO, options?: any) {
        return UserControllerApiFp(this.configuration).updateUserAsSelfUsingPUT(dto, options)(this.fetch, this.basePath);
    }

    /**
     *
     * @summary updateUser
     * @param {number} id id
     * @param {EditUserDTO} user user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateUserUsingPUT(id: number, user: EditUserDTO, options?: any) {
        return UserControllerApiFp(this.configuration).updateUserUsingPUT(id, user, options)(this.fetch, this.basePath);
    }

}

