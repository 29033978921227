import {LogDTO, UserDTO} from "../api/swagger/api";

export const ALL_ROLES: UserDTO.RoleEnum[] = [
    UserDTO.RoleEnum.User,
    UserDTO.RoleEnum.Admin,
    UserDTO.RoleEnum.Moderator,
    UserDTO.RoleEnum.Subscriber
];

export const getPresentableRole = (role: UserDTO.RoleEnum): string => {
    switch (role) {
        case UserDTO.RoleEnum.User:
            return "Kullanıcı";
        case UserDTO.RoleEnum.Admin:
            return "Admin";
        case UserDTO.RoleEnum.Moderator:
            return "Moderatör";
        case UserDTO.RoleEnum.Subscriber:
            return "İzleyici";
    }
}

export const ALL_MEMBERSHIP_TYPES: UserDTO.MembershipTypeEnum[] = [
    UserDTO.MembershipTypeEnum.DernekUyesi,
    UserDTO.MembershipTypeEnum.DenetimKurulu,
    UserDTO.MembershipTypeEnum.YonetimKurulu,
    UserDTO.MembershipTypeEnum.SistemYoneticisi,
    UserDTO.MembershipTypeEnum.DisiplinKurulu,
    UserDTO.MembershipTypeEnum.YonetimKuruluBaskani
];

export const getPresentableMembershipType = (role: UserDTO.MembershipTypeEnum): string => {
    switch (role) {
        case UserDTO.MembershipTypeEnum.DernekUyesi:
            return "Dernek Üyesi";
        case UserDTO.MembershipTypeEnum.DenetimKurulu:
            return "Denetim Kurulu";
        case UserDTO.MembershipTypeEnum.YonetimKurulu:
            return "Yönetim Kurulu";
        case UserDTO.MembershipTypeEnum.SistemYoneticisi:
            return "Sistem Yöneticisi";
        case UserDTO.MembershipTypeEnum.DisiplinKurulu:
            return "Disiplin Kurulu";
        case UserDTO.MembershipTypeEnum.YonetimKuruluBaskani:
            return "Yönetim Kurulu Başkanı";
    }
}

export const getPresentableLogType = (role: LogDTO.TypeEnum): string => {
    switch (role) {
        case LogDTO.TypeEnum.AddAnnouncement:
            return "Duyuru Ekle";
        case LogDTO.TypeEnum.AddAppMessage:
            return "Uygulama Mesajı Ekle";
        case LogDTO.TypeEnum.AddBloodAnnouncement:
            return "Kan Duyurusu Ekle";
        case LogDTO.TypeEnum.AddEventUser:
            return "Etkinlik Kullanıcısı Ekle";
        case LogDTO.TypeEnum.AddEvents:
            return "Etkinlik Ekle";
        case LogDTO.TypeEnum.AddMatch:
            return "Maç Ekle";
        case LogDTO.TypeEnum.AddPoll:
            return "Anket Ekle";
        case LogDTO.TypeEnum.AddPost:
            return "Gönderi Ekle";
        case LogDTO.TypeEnum.AddPromotion:
            return "Promosyon Ekle";
        case LogDTO.TypeEnum.AddScorePrediction:
            return "Skor Tahmini Ekle";
        case LogDTO.TypeEnum.AddTag:
            return "Etiket Ekle";
        case LogDTO.TypeEnum.AddVote:
            return "Oy Ekle";
        case LogDTO.TypeEnum.ApproveBloodTypeConsent:
            return "Kan Tipi Onayı";
        case LogDTO.TypeEnum.ApproveKvkk:
            return "KVKK Onayı";
        case LogDTO.TypeEnum.ChangeAvatar:
            return "Profil Resmi Değiştir";
        case LogDTO.TypeEnum.ChangePassword:
            return "Şifre Değiştir";
        case LogDTO.TypeEnum.CommentPost:
            return "Gönderiye Yorum Yap";
        case LogDTO.TypeEnum.DeleteAnnouncement:
            return "Duyuru Sil";
        case LogDTO.TypeEnum.DeleteAppMessage:
            return "Uygulama Mesajını Sil";
        case LogDTO.TypeEnum.DeleteBloodAnnouncement:
            return "Kan Duyurusunu Sil";
        case LogDTO.TypeEnum.DeleteEvent:
            return "Etkinliği Sil";
        case LogDTO.TypeEnum.DeleteEventUser:
            return "Etkinlik Kullanıcısını Sil";
        case LogDTO.TypeEnum.DeleteMatch:
            return "Maçı Sil";
        case LogDTO.TypeEnum.DeletePoll:
            return "Anketi Sil";
        case LogDTO.TypeEnum.DeletePost:
            return "Gönderiyi Sil";
        case LogDTO.TypeEnum.DeletePromotion:
            return "Promosyonu Sil";
        case LogDTO.TypeEnum.DeleteScorePrediction:
            return "Skor Tahminini Sil";
        case LogDTO.TypeEnum.DeleteTag:
            return "Etiketi Sil";
        case LogDTO.TypeEnum.DeleteUser:
            return "Kullanıcıyı Sil";
        case LogDTO.TypeEnum.DeleteVote:
            return "Oyu Sil";
        case LogDTO.TypeEnum.LikeAnnouncement:
            return "Duyuruyu Beğen";
        case LogDTO.TypeEnum.LikePost:
            return "Gönderiyi Beğen";
        case LogDTO.TypeEnum.LikePromotion:
            return "Promosyonu Beğen";
        case LogDTO.TypeEnum.LockUser:
            return "Kullanıcıyı Kilit";
        case LogDTO.TypeEnum.RegisterUser:
            return "Kullanıcı Kaydı";
        case LogDTO.TypeEnum.RemoveAvatar:
            return "Profil Resmini Kaldır";
        case LogDTO.TypeEnum.SetMatchScore:
            return "Maç Skorunu Belirle";
        case LogDTO.TypeEnum.UnlockUser:
            return "Kullanıcı Kilidini Aç";
        case LogDTO.TypeEnum.UpdateAnnouncement:
            return "Duyuruyu Güncelle";
        case LogDTO.TypeEnum.UpdateAppMessage:
            return "Uygulama Mesajını Güncelle";
        case LogDTO.TypeEnum.UpdateBloodAnnouncement:
            return "Kan Duyurusunu Güncelle";
        case LogDTO.TypeEnum.UpdateEvent:
            return "Etkinliği Güncelle";
        case LogDTO.TypeEnum.UpdateEventUser:
            return "Etkinlik Kullanıcısını Güncelle";
        case LogDTO.TypeEnum.UpdateMatch:
            return "Maçı Güncelle";
        case LogDTO.TypeEnum.UpdatePoll:
            return "Anketi Güncelle";
        case LogDTO.TypeEnum.UpdatePost:
            return "Gönderiyi Güncelle";
        case LogDTO.TypeEnum.UpdatePromotion:
            return "Promosyonu Güncelle";
        case LogDTO.TypeEnum.UpdateScorePrediction:
            return "Skor Tahminini Güncelle";
        case LogDTO.TypeEnum.UpdateUser:
            return "Kullanıcıyı Güncelle";
        case LogDTO.TypeEnum.UpdateUserAsSelf:
            return "Kendi Bilgilerini Güncelle";
        case LogDTO.TypeEnum.UpdateVote:
            return "Oyu Güncelle";
        default:
            return "Bilinmeyen Tür";
    }
}

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
