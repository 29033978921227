import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {getJsonResponse} from "./fetchUtils";
import {EditUserDTO, ResponseDTO, UserDTO} from "../api/swagger/api";
import {RootState} from "../store/Store";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";

export interface UsersState {
    loadUsersTaskStatus: AsyncTaskStatus | null;
    editUserTaskStatus: AsyncTaskStatus | null;
    changePasswordTaskStatus: AsyncTaskStatus | null;
    lockUnlockUserTaskStatus: AsyncTaskStatus | null;
    pageUserObject: PagedEntityList<UserDTO> | null;
}

const initialState: UsersState = {
    loadUsersTaskStatus: null,
    editUserTaskStatus: null,
    changePasswordTaskStatus: null,
    lockUnlockUserTaskStatus: null,
    pageUserObject: null
};

const endPoint = EndPoints.users;

export const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setLoadUsersTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadUsersTaskStatus = action.payload;
        },
        setEditUserTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.editUserTaskStatus = action.payload;
        },
        setChangePasswordTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.changePasswordTaskStatus = action.payload;
        },
        setLockUnlockUserTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.lockUnlockUserTaskStatus = action.payload;
        },
        setGetAllUsersResult: (state, action: PayloadAction<any | null>) => {
            state.pageUserObject = action.payload;
        }
    },
});

export const {
    setGetAllUsersResult,
    setLoadUsersTaskStatus,
    setEditUserTaskStatus,
    setChangePasswordTaskStatus,
    setLockUnlockUserTaskStatus
} = userSlice.actions;

const fetchUsers = async (pageNumber: number) => {
    const token = localStorage.getItem('token');
    return fetch(`${endPoint}?pageNumber=${pageNumber}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
};

export const updatePasswordAction = (id: number, password: string, callback?: () => void) => async (dispatch: any) => {
    dispatch(setChangePasswordTaskStatus({type: AsyncTaskStatusType.Loading}));
    const token = localStorage.getItem('token');

    fetch(`${endPoint}/${id}/changePassword`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify({password: password}),
    }).then((response) => getJsonResponse(response, dispatch))
        .then((response: ResponseDTO) => {
            if (response.code === ResponseDTO.CodeEnum.Success) {
                if (callback) {
                    callback();
                }
                dispatch(setChangePasswordTaskStatus({type: AsyncTaskStatusType.Success}));
            } else {
                dispatch(setChangePasswordTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: response.message,
                    fieldErrors: response.fieldErrors,
                    errorDetails: response.errorDetails
                }));
            }
        }).catch((error) => {
        dispatch(setChangePasswordTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
    })
}

export const lockUnlockUserAction = (id: number, lock: boolean, pageNumber: number, callback?: () => void) => async (dispatch: any) => {
    const token = localStorage.getItem('token');
    const endpoint = lock
        ? `${endPoint}/${id}/lock`
        : `${endPoint}/${id}/unlock`;
    dispatch(setLockUnlockUserTaskStatus({type: AsyncTaskStatusType.Loading}));
    fetch(endpoint, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
        .then((response) => getJsonResponse(response, dispatch))
        .then((response: ResponseDTO) => {
            if (response.code === ResponseDTO.CodeEnum.Success) {
                if (callback) {
                    callback();
                }
                dispatch(setLockUnlockUserTaskStatus({type: AsyncTaskStatusType.Success}));
                dispatch(getAllUsersAction(pageNumber));
            } else {
                dispatch(setLockUnlockUserTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: response.message,
                    errorDetails: response.errorDetails
                }));
            }
        }).catch((error) => {
        dispatch(setLockUnlockUserTaskStatus({type: AsyncTaskStatusType.Loading}));
    })
}

export const updateUserAction = (id: number, user: EditUserDTO, callback ?: () => void) => async (dispatch: any) => {
    const token = localStorage.getItem('token');
    dispatch(setEditUserTaskStatus({type: AsyncTaskStatusType.Loading}));
    fetch(`${endPoint}/${id}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(user),
    }).then(response => getJsonResponse(response, dispatch))
        .then((response: ResponseDTO) => {
            if (response.code === ResponseDTO.CodeEnum.Success) {
                if (callback) {
                    callback()
                }
                dispatch(setEditUserTaskStatus({type: AsyncTaskStatusType.Success}));
            } else {
                dispatch(setEditUserTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: response.message,
                    fieldErrors: response.fieldErrors,
                    errorDetails: response.errorDetails
                }));
            }
        }).catch((error) => {
        dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
    });
}

export const getAllUsersAction =
    (pageNumber: number) => async (dispatch: any) => {
        dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Loading}));
        fetchUsers(pageNumber)
            .then((response) => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setGetAllUsersResult(response.resultValue as UserDTO[]));
                    dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Success}));
                } else {
                    dispatch(setLoadUsersTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            })
            .catch((error) => {
                dispatch(setLoadUsersTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            });
    };


export const pageUserObjectSelector = (state: RootState) => state.users.pageUserObject;

export const loadUsersTaskStatusSelector = (state: RootState) => state.users.loadUsersTaskStatus;

export const editUserTaskStatusSelector = (state: RootState) => state.users.editUserTaskStatus;

export const changePasswordTaskStatusSelector = (state: RootState) => state.users.changePasswordTaskStatus;

export default userSlice.reducer;
