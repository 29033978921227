import React, {useEffect, useState} from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from 'primereact/button';
import {Ripple} from 'primereact/ripple';
import {InputText} from 'primereact/inputtext';
import {classNames} from 'primereact/utils';
import {useDispatch, useSelector} from "react-redux";
import {getAllLogsAction, pageLogObjectSelector} from "../../slices/logSlice";
import moment from "moment";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import DatePicker, {registerLocale} from "react-datepicker";
import tr from "date-fns/locale/tr";
import {
    PaginatorCurrentPageReportOptions,
    PaginatorNextPageLinkOptions,
    PaginatorPageLinksOptions,
    PaginatorPrevPageLinkOptions,
    PaginatorTemplate
} from "primereact/paginator";
import "react-datepicker/dist/react-datepicker.css";
import {PagedEntityList} from "../../shared/dtos";
import {LogDTO} from "../../api/swagger/api";
import {getPresentableLogType} from "../../shared/utils";

registerLocale("tr", tr);

export const Logs = () => {
    const dispatch: any = useDispatch();
    const pageLogObjectState = useSelector(pageLogObjectSelector);
    const [pageLogObject, setPageLogObject] = useState<PagedEntityList<LogDTO>>({
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    });
    const [first, setFirst] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageInputTooltip, setPageInputTooltip] = useState('Bu sayfaya gitmek için \'Enter\' tuşuna basın.');
    const defaultPageNumber = 1;
    const defaultStartDate = new Date();
    defaultStartDate.setDate(defaultStartDate.getDate() - 7);
    const [rangeStart, setRangeStart] = React.useState(defaultStartDate);
    const [rangeEnd, setRangeEnd] = React.useState(new Date());

    const getAllLogs = (startDate: Date, endDate: Date, pageNumber?: number) => {
        dispatch(getAllLogsAction(pageNumber || defaultPageNumber, convertDateToMoment(startDate), convertDateToMoment(endDate)));
    }

    useEffect(() => {
        getAllLogs(rangeStart, rangeEnd, null);
    }, [dispatch]);

    useEffect(() => {
        setPageLogObject(pageLogObjectState)
    }, [pageLogObjectState]);

    const selectStartDate = (date: Date) => {
        setRangeStart(date);
    }

    const selectEndDate = (date: Date) => {
        setRangeEnd(date);
    }

    const onCustomPage = (event: any) => {
        setFirst(event.first);
        setCurrentPage(event.page + 1);
        getAllLogs(rangeStart, rangeEnd, event.page + 1);
    }

    const onPageInputKeyDown = (event: any, options: any) => {
        if (event.key === 'Enter') {
            const pageNumber = currentPage;
            if (pageNumber < 1 || pageNumber > options.totalPages) {
                setPageInputTooltip(`Değer 1 ile ${options.totalPages} arasında olmalıdır.`);
            } else {
                const first = currentPage ? options.rows * (pageNumber - 1) : 0;
                setFirst(first);
                setPageInputTooltip('Bu sayfaya gitmek için \'Enter\' tuşuna basın.');
                getAllLogs(rangeStart, rangeEnd, pageNumber);
            }
        }
    }

    const onPageInputChange = (event: any) => {
        setCurrentPage(event.target.value);
    }

    const convertDateToMoment = (date: Date): string => {
        return moment(date).utc().format();
    }

    const dateBodyTemplate = (log: LogDTO) => {
        return moment.unix(Number(log.date)).format("DD-MM-YYYY HH:mm:ss");
    };

    const paginatorTemplate: PaginatorTemplate | any = {
        layout: 'PrevPageLink PageLinks NextPageLink CurrentPageReport',
        'PrevPageLink': (options: PaginatorPrevPageLinkOptions) => {
            return (
                <button type="button" {...options}>
                    <span className="p-3">Önceki</span>
                    <Ripple/>
                </button>
            )
        },
        'NextPageLink': (options: PaginatorNextPageLinkOptions) => {
            return (
                <button type="button" {...options}>
                    <span className="p-3">Sonraki</span>
                    <Ripple/>
                </button>
            )
        },
        'PageLinks': (options: PaginatorPageLinksOptions) => {
            if ((options.view.startPage === options.page && options.view.startPage !== 0)
                || (options.view.endPage === options.page && options.page + 1 !== options.totalPages)) {
                const className = classNames(options.className, {'p-disabled': true});
                return <span className={className} style={{userSelect: 'none'}}>...</span>;
            }

            return (
                <button type="button" {...options}>
                    {options.page + 1}
                    <Ripple/>
                </button>
            )
        },
        'CurrentPageReport': (options: PaginatorCurrentPageReportOptions) => {
            return (
                <span className="mx-3" style={{color: 'var(--text-color)', userSelect: 'none'}}>
                    Git <InputText style={{maxWidth: '50px'}} size={pageLogObject.totalNumberPages} className="ml-1"
                                   value={currentPage.toString()}
                                   tooltip={pageInputTooltip}
                                   onKeyDown={(e) => onPageInputKeyDown(e, options)} onChange={onPageInputChange}/>
                </span>
            )
        }
    };

    const header = (
        <div className="flex flex-column md:flex-row md:align-items-center justify-content-start">
            <div className="mt-3 md:mt-0 flex justify-content-start m-2">
                <DatePicker
                    className="m-1 p-1"
                    locale="tr"
                    selectsStart
                    selected={rangeStart}
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    onChange={selectStartDate}/>
                <DatePicker
                    className="m-1 p-1"
                    locale="tr"
                    selectsEnd
                    selected={rangeEnd}
                    startDate={rangeStart}
                    endDate={rangeEnd}
                    onChange={selectEndDate}/>
            </div>
            <Button icon="pi pi-filter" className="p-button-rounded p-button-success"
                    onClick={() => getAllLogs(rangeStart, rangeEnd, null)}/>
        </div>
    );

    function renderLogsTable() {
        return (
            <DataTable
                value={pageLogObject.entity}
                paginator
                paginatorTemplate={paginatorTemplate}
                first={first}
                rows={6}
                lazy={true}
                totalRecords={pageLogObject.totalElements}
                onPage={onCustomPage}
                responsiveLayout="scroll"
                header={header}
                emptyMessage="Kayıtlar bulunamadı.">
                <Column field="date" header="Tarih" style={{minWidth: '16rem'}}
                        body={(props) => dateBodyTemplate(props)}/>
                <Column field="logMessage" header="Kayıt Mesajı" style={{minWidth: '16rem'}}
                        body={(props) => (
                            <div dangerouslySetInnerHTML={{__html: props.logMessage}}></div>
                        )}/>
                <Column field="operationSuccessful" header="İşlem" style={{minWidth: '16rem'}} body={(props) =>
                    props.operationSuccessful ? "Başarılı" : "Başarısız"
                }/>
                <Column field="type" header="Türü" style={{minWidth: '16rem'}}
                        body={(props) => getPresentableLogType(props.type)}/>
                <Column field="user" header="İşlem Yapan" style={{minWidth: '16rem'}} body={(props) => {
                    return props.user.firstName + " " + props.user.surname;
                }}/>
            </DataTable>
        );
    }

    return (
        <div className="datatable-crud-demo surface-card p-4 border-round shadow-2">
            <Container style={{justifyContent: 'center'}}>
                <Box pb={5} style={{background: 'white'}}>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Kayıtlar
                    </Typography>
                    <Divider/>
                </Box>
            </Container>
            {renderLogsTable()}
        </div>
    );
}
