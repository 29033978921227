import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Password} from 'primereact/password';
import {Dialog} from 'primereact/dialog';
import {Divider} from 'primereact/divider';
import {classNames} from 'primereact/utils';
import {SignupRequestDTO, UserDTO} from "../../api/swagger/api";
import './AddEditUserDialog.css';
import {RadioButton} from "primereact/radiobutton";
import {
    ALL_MEMBERSHIP_TYPES,
    ALL_ROLES,
    EMAIL_REGEX,
    getPresentableMembershipType,
    getPresentableRole
} from "../../shared/utils";
import {useSelector} from 'react-redux';
import {registerTaskStatusSelector} from "../../slices/authSlice";

interface SignupRequestFormData {
    email: string;
    firstName: string;
    password: string;
    role: SignupRequestDTO.RoleEnum;
    surname: string;
    username: string;
    membershipType: SignupRequestDTO.MembershipTypeEnum;
}

export const NewUserDialog = (props: any) => {
    const registerTaskStatus = useSelector(registerTaskStatusSelector);
    const defaultValues: SignupRequestFormData = {
        firstName: '',
        username: '',
        surname: '',
        email: '',
        password: '',
        role: UserDTO.RoleEnum.User,
        membershipType: UserDTO.MembershipTypeEnum.DernekUyesi
    }

    const [newUserDialog, setNewUserDialog] = useState(true);
    const {
        control, setError, reset, formState: {errors}, handleSubmit
    }
        = useForm({defaultValues});
    const hideNewUserDialog = () => {
        setNewUserDialog(false);
        props.hideNewUserDialog();
    }

    useEffect(() => {
        if (registerTaskStatus?.fieldErrors) {
            registerTaskStatus?.fieldErrors.forEach((it) =>
                // @ts-ignore
                setError(it.field, {type: 'custom', message: it.message}))
        }
    }, [registerTaskStatus]);

    useEffect(() => {
        return () => {
            reset();
        }
    }, []);

    const onSubmit = (data: SignupRequestFormData) => {
        const dto: SignupRequestDTO = {
            email: data.email,
            firstName: data.firstName,
            password: data.password,
            role: data.role,
            surname: data.surname,
            username: data.username,
            membershipType: data.membershipType,
            membershipNumber: 1
        }

        props.onSubmit(dto)
    };

    const renderNewUserDialogFooter = (
        <React.Fragment>
            <React.Fragment>
                <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideNewUserDialog}/>
                <Button type="submit" icon="pi pi-check" label="Kaydet" onClick={handleSubmit(onSubmit)}/>
            </React.Fragment>
        </React.Fragment>
    );

    const passwordHeader = <h6>Şifre oluştur</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider/>
            <p className="mt-2">Önerilen</p>
            <ul className="pl-2 ml-2 mt-0" style={{lineHeight: '1.5'}}>
                <li>En az bir küçük harf</li>
                <li>En az bir büyük harf</li>
                <li>En az bir sayısal</li>
                <li>En az 6 karakter</li>
            </ul>
        </React.Fragment>
    );

    return (
        <Dialog visible={newUserDialog}
                breakpoints={{'960px': '75vw', '640px': '100vw'}}
                style={{width: '40vw'}}
                header="Kullanıcı Detayları"
                modal className="p-fluid"
                onHide={hideNewUserDialog}
                footer={renderNewUserDialogFooter}>
            <div className="form-demo">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <div className="field">
                            <span className="p-float-label">
                                <Controller name="firstName" control={control} rules={{required: 'Ad boş geçilemez.'}}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field} autoFocus
                                                           className={classNames({'p-invalid': fieldState.invalid})}/>
                                            )}/>
                                <label htmlFor="firstName" className={classNames({'p-error': errors.firstName})}>
                                    Ad*
                                </label>
                            </span>
                                {errors.firstName && (
                                    <small className="p-error">{errors.firstName.message}</small>
                                )}
                            </div>

                            <div className="field">
                            <span className="p-float-label">
                                <Controller name="surname" control={control} rules={{required: 'Soyad boş geçilemez.'}}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field}
                                                           className={classNames({'p-invalid': fieldState.invalid})}/>
                                            )}/>
                                <label htmlFor="surname" className={classNames({'p-error': errors.surname})}>
                                    Soyad*
                                </label>
                            </span>
                                {errors.surname && (
                                    <small className="p-error">{errors.surname.message}</small>
                                )}
                            </div>

                            <div className="field">
                            <span className="p-float-label">
                                <Controller name="username" control={control}
                                            rules={{required: 'Kullanıcı adı boş geçilemez.'}}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field}
                                                           className={classNames({'p-invalid': fieldState.invalid})}/>
                                            )}/>
                                <label htmlFor="username"
                                       className={classNames({'p-error': errors.username})}>Kullanıcı Adı*</label>
                            </span>
                                {errors.username && (
                                    <small className="p-error">{errors.username.message}</small>
                                )}
                            </div>

                            <div className="field">
                            <span className="p-float-label p-input-icon-right">
                                <i className="pi pi-envelope"/>
                                <Controller name="email" control={control}
                                            rules={{
                                                required: 'E-Posta boş geçilemez.',
                                                pattern: {
                                                    value: EMAIL_REGEX,
                                                    message: 'Geçersiz email adresi. Ör: abcdef@gg.com'
                                                }
                                            }}
                                            render={({field, fieldState}) => (
                                                <InputText id={field.name} {...field}
                                                           className={classNames({'p-invalid': fieldState.invalid})}/>
                                            )}/>
                                <label htmlFor="email" className={classNames({'p-error': errors.email})}>
                                    E-Posta*
                                </label>
                            </span>
                                {errors.email && (
                                    <small className="p-error">{errors.email.message}</small>
                                )}
                            </div>

                            <div className="field">
                            <span className="p-float-label">
                                <Controller name="password" control={control}
                                            rules={{
                                                required: 'Şifre boş geçilemez.',
                                                minLength: {value: 6, message: 'Şifre en az 6 karakter olmalı'}
                                            }}
                                            render={({field, fieldState}) => (
                                                <Password id={field.name} {...field} toggleMask
                                                          className={classNames({'p-invalid': fieldState.invalid})}
                                                          header={passwordHeader} footer={passwordFooter}/>
                                            )}/>
                                <label htmlFor="password" className={classNames({'p-error': errors.password})}>
                                    Şifre*
                                </label>
                            </span>
                                {errors.password && (
                                    <small className="p-error">{errors.password.message}</small>
                                )}
                            </div>

                            <div className="field">
                                <label className="mb-3">Rol</label>
                                <div className="formgrid grid">
                                    {ALL_ROLES.map((roleValue) => (
                                        <div key={roleValue} className="field-radiobutton col-6">
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({field}) => (
                                                    <RadioButton
                                                        {...field}
                                                        inputId={`role-${roleValue}`}
                                                        value={roleValue}
                                                        checked={field.value === roleValue}/>
                                                )}/>
                                            <label htmlFor={`role-${roleValue}`}>
                                                {getPresentableRole(roleValue)}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                {errors.role && (
                                    <small className="p-error">{errors.role.message}</small>
                                )}
                            </div>
                            <div className="field">
                                <label className="mb-3">Üye Tipi</label>
                                <div className="formgrid grid">
                                    {ALL_MEMBERSHIP_TYPES.map((membershipTypeValue) => (
                                        <div key={membershipTypeValue} className="field-radiobutton col-6">
                                            <Controller
                                                name="membershipType"
                                                control={control}
                                                render={({field}) => (
                                                    <RadioButton
                                                        {...field}
                                                        inputId={`membershipType-${membershipTypeValue}`}
                                                        value={membershipTypeValue}
                                                        checked={field.value === membershipTypeValue}/>
                                                )}/>
                                            <label htmlFor={`membershipType-${membershipTypeValue}`}>
                                                {getPresentableMembershipType(membershipTypeValue)}
                                            </label>
                                        </div>
                                    ))}

                                </div>
                                {errors.membershipType && (
                                    <small className="p-error">{errors.membershipType.message}</small>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
