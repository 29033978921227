// export const RootURL = 'http://localhost:8080'
export const RootURL = 'https://afider.sigun.com.tr'
export const DevRootURL = RootURL
export const EndPoints = {
    announcements: `${DevRootURL}/api/announcements`,
    promotions: `${DevRootURL}/api/promotions`,
    users: `${DevRootURL}/api/users`,
    auth: `${DevRootURL}/api/auth`,
    signup: `${DevRootURL}/api/signup`,
    posts: `${DevRootURL}/api/post`,
    logs: `${DevRootURL}/api/logs`,
    matches: `${DevRootURL}/api/matches`,
    scorePredictions: `${DevRootURL}/api/matches/score-predictions`
}
