import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {EndPoints} from '../api/EndPoints';
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {PagedEntityListPromotionDTO, PromotionDTO, ResponseDTO} from "../api/swagger/api";
import {AsyncTaskStatus, AsyncTaskStatusType, PagedEntityList} from "../shared/dtos";
import {fetchWithTimeout} from "./fetchWithTimeout";

const endPoint = EndPoints.promotions

export interface PromotionState {
    pagePromotionObject: PagedEntityListPromotionDTO;
    allPromotionsInPage: PromotionDTO[];
    currentPage: number;
    loadPromotionsTaskStatus: AsyncTaskStatus | null;
}

const initialState: PromotionState = {
    pagePromotionObject: {
        totalNumberPages: 0,
        totalElements: 0,
        numberOfElements: 0,
        entity: []
    } as PagedEntityListPromotionDTO,
    allPromotionsInPage: [],
    currentPage: 1,
    loadPromotionsTaskStatus: null
}


export const promotionsSlice = createSlice({
    name: 'promotions',
    initialState,
    reducers: {
        setLoadPromotionsTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loadPromotionsTaskStatus = action.payload;
        },
        setGetAllPromotionsResult: (
            state,
            action: PayloadAction<PagedEntityListPromotionDTO>,
        ) => {
            state.pagePromotionObject = action.payload;
        },
        setCurrentPage: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentPage = action.payload;
        },
        setAllPromotionsInPage: (
            state,
            action: PayloadAction<PromotionDTO[]>
        ) => {
            state.allPromotionsInPage = action.payload;
        },
    },
});

export const {
    setLoadPromotionsTaskStatus,
    setGetAllPromotionsResult,
    setAllPromotionsInPage,
    setCurrentPage
} = promotionsSlice.actions;

const fetchPromotions = async (pageNumber: number) => {
    const token = localStorage.getItem('token');
    return fetchWithTimeout(`${endPoint}?pageNumber=${pageNumber}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    })
}

export const getAllPromotionsAction = (callback?: () => void, clear?: boolean) =>
    async (dispatch: any, getState: () => RootState) => {
        dispatch(setLoadPromotionsTaskStatus({type: AsyncTaskStatusType.Loading}));
        const currentPage = clear
            ? 1
            : getState().promotions.currentPage;
        fetchPromotions(currentPage)
            .then(response => getJsonResponse(response, dispatch))
            .then((response: ResponseDTO) => {
                if (response.code === ResponseDTO.CodeEnum.Success) {
                    dispatch(setCurrentPage(currentPage + 1));
                    const resultValue = response.resultValue as PagedEntityList<PromotionDTO>;
                    dispatch(setGetAllPromotionsResult(resultValue));
                    let currentPromotions: PromotionDTO[] = clear
                        ? []
                        : getState().promotions.allPromotionsInPage;
                    currentPromotions = [...currentPromotions, ...resultValue.entity];
                    dispatch(setAllPromotionsInPage(currentPromotions));
                    dispatch(setLoadPromotionsTaskStatus({type: AsyncTaskStatusType.Success}));
                    if (callback) {
                        callback();
                    }
                } else {
                    dispatch(setLoadPromotionsTaskStatus({
                        type: AsyncTaskStatusType.Error,
                        errorMessage: response.message,
                        errorDetails: response.errorDetails
                    }));
                }
            })
            .catch((error) => {
                dispatch(setLoadPromotionsTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            });
    };

export const loadPromotionsTaskStatusSelector = (state: RootState) => state.promotions.loadPromotionsTaskStatus;
export const lastPromotionInPageInfoSelector = (state: RootState) => state.promotions.pagePromotionObject;

export default promotionsSlice.reducer;
