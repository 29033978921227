import React, {useEffect} from "react";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {useDispatch, useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";
import {changePasswordTaskStatusSelector, updatePasswordAction} from "../../slices/userSlice";
import {classNames} from "primereact/utils";

interface ChangePasswordDialogProps {
    userId: number;
    hideChangePasswordDialog: () => void;
}

interface ChangePasswordData {
    newPassword: string;
    confirmNewPassword: string;
}

const MIN_PASSWORD_LENGTH = 6;

export const ChangePasswordDialog = ({userId, hideChangePasswordDialog}: ChangePasswordDialogProps) => {
    const changePasswordTaskStatus = useSelector(changePasswordTaskStatusSelector);
    const dispatch: any = useDispatch();
    const {
        control,
        watch,
        reset,
        formState: {errors, dirtyFields, touchedFields},
        trigger,
        handleSubmit,
    } = useForm<ChangePasswordData>({
        defaultValues: {
            newPassword: "",
            confirmNewPassword: "",
        }
    });

    useEffect(() => {
        if (changePasswordTaskStatus?.fieldErrors) {
            changePasswordTaskStatus?.fieldErrors.forEach((it) =>
                // @ts-ignore
                setError(it.field, {type: 'custom', message: it.message}))
        }
    }, [changePasswordTaskStatus]);

    useEffect(() => {
        return () => {
            reset();
        }
    }, []);

    const changePasswordSubmit = (data: ChangePasswordData) => {
        dispatch(updatePasswordAction(userId, data.newPassword, hideChangePasswordDialog));
    };

    const changePasswordDialogFooter = (
        <React.Fragment>
            <Button label="İptal" icon="pi pi-times" className="p-button-text" onClick={hideChangePasswordDialog}/>
            <Button label="Kaydet" icon="pi pi-check" onClick={handleSubmit(changePasswordSubmit)}/>
        </React.Fragment>
    );

    const newPassword = watch("newPassword");

    useEffect(() => {
        if (dirtyFields.confirmNewPassword && touchedFields.confirmNewPassword) {
            trigger("confirmNewPassword");
        }
    }, [dirtyFields.confirmNewPassword, newPassword, touchedFields.confirmNewPassword, trigger]);

    return (
        <Dialog
            visible={true}
            breakpoints={{'960px': '75vw', '640px': '100vw'}}
            style={{width: '40vw'}}
            header="Şifre Değiştir"
            modal
            className="p-fluid"
            footer={changePasswordDialogFooter}
            onHide={hideChangePasswordDialog}>
            <div className="form-demo">
                <div className="flex justify-content-center">
                    <div className="card">
                        <form onSubmit={handleSubmit(changePasswordSubmit)} className="p-fluid">
                            <div className="field">
                                <span className="p-float-label">
                                  <Controller
                                      name="newPassword"
                                      control={control}
                                      rules={{
                                          required: 'Şifre boş geçilemez.',
                                          minLength: {
                                              value: MIN_PASSWORD_LENGTH,
                                              message: `En az ${MIN_PASSWORD_LENGTH} karakter girilebilir.`,
                                          }
                                      }}
                                      render={({field, fieldState}) => (
                                          <InputText
                                              id={field.name}
                                              {...field}
                                              autoFocus
                                              className={classNames({'p-invalid': fieldState.invalid})}/>
                                      )}/>
                                  <label htmlFor="newPassword" className={classNames({'p-error': errors.newPassword})}>
                                    Yeni Şifre*
                                  </label>
                                </span>
                                {errors.newPassword && <small className="p-error">{errors.newPassword.message}</small>}
                            </div>

                            <div className="field">
                                <span className="p-float-label">
                                  <Controller
                                      name="confirmNewPassword"
                                      control={control}
                                      rules={{
                                          required: 'Şifre tekrarı boş geçilemez.',
                                          minLength: {
                                              value: MIN_PASSWORD_LENGTH,
                                              message: `En az ${MIN_PASSWORD_LENGTH} karakter girilebilir.`,
                                          },
                                          validate: (value) => value === newPassword || 'Şifreler uyuşmuyor'
                                      }}
                                      render={({field, fieldState}) => (
                                          <InputText id={field.name} {...field}
                                                     className={classNames({'p-invalid': fieldState.invalid})}/>
                                      )}
                                  />
                                  <label htmlFor="confirmNewPassword"
                                         className={classNames({'p-error': errors.confirmNewPassword})}>
                                    Yeni Şifre tekrar*
                                  </label>
                                </span>
                                {errors.confirmNewPassword && (
                                    <small className="p-error">{errors.confirmNewPassword.message}</small>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};
