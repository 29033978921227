import {configureStore} from '@reduxjs/toolkit';
import announcementSlice from '../slices/announcementSlice';
import authSlice from '../slices/authSlice';
import promotionSlice from '../slices/promotionSlice';
import userSlice from '../slices/userSlice';
import postSlice from '../slices/postSlice';
import profileSlice from '../slices/profileSlice';
import logSlice from '../slices/logSlice';
import matchSlice from '../slices/matchSlice';

export const Store = configureStore({
    reducer: {
        auth: authSlice,
        announcements: announcementSlice,
        users: userSlice,
        promotions: promotionSlice,
        posts: postSlice,
        logs: logSlice,
        matches: matchSlice,
        profile: profileSlice
    },
})

export type RootState = ReturnType<typeof Store.getState>

export type AppDispatch = typeof Store.dispatch
