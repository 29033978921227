import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {Store} from './store/Store';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const rootElement = document.getElementById('root');
// Looks like @types/react-dom was not updated with react-dom@18 type definitions yet. So we ignore the error for now.
// @ts-ignore
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <Provider store={Store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
