import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {LoginRequest} from '../api/ViewModels/LoginRequest';
import {LoginResponse} from '../api/ViewModels/LoginResponse';
import {EndPoints} from "../api/EndPoints";
import {ResponseDTO, SignupRequestDTO, UserDTO} from "../api/swagger/api";
import {RootState} from "../store/Store";
import {getJsonResponse} from "./fetchUtils";
import {AsyncTaskStatus, AsyncTaskStatusType} from "../shared/dtos";

// TODO: use taskStatuses

export interface AuthState {
    authenticationResult: LoginResponse | null;
    loginTaskStatus: AsyncTaskStatus | null;
    registerTaskStatus: AsyncTaskStatus | null;
}

const initialState: AuthState = {
    authenticationResult: null,
    loginTaskStatus: null,
    registerTaskStatus: null
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.loginTaskStatus = action.payload;
        },
        setRegisterTaskStatus: (state, action: PayloadAction<AsyncTaskStatus | null>) => {
            state.registerTaskStatus = action.payload;
        },
        setAuthenticationResult: (
            state,
            action: PayloadAction<LoginResponse | null>,
        ) => {
            state.authenticationResult = action.payload
        },
        loginFailure: state => {
            state.authenticationResult = null
        },
        registerFailure: state => {
            state.authenticationResult = null
        }
    },
});

export const signupAction = (data: SignupRequestDTO, callback ?: (message: string, id?: number,) => void) => async (dispatch: any) => {
    dispatch(setRegisterTaskStatus({type: AsyncTaskStatusType.Loading}));
    const token = localStorage.getItem('token');
    fetch(EndPoints.signup, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => getJsonResponse(response, dispatch))
        .then((response: ResponseDTO) => {
            if (response.code === ResponseDTO.CodeEnum.Success) {
                const result = response.resultValue as { message: string; registeredUserId?: number };
                if (result.registeredUserId) {
                    dispatch(setRegisterTaskStatus({type: AsyncTaskStatusType.Success}));
                    if (callback) {
                        callback(result.message, result.registeredUserId);
                    }
                } else if (callback) {
                    callback(result.message, undefined);
                }
            } else {
                dispatch(setRegisterTaskStatus({
                    type: AsyncTaskStatusType.Error,
                    errorMessage: response.message,
                    fieldErrors: response.fieldErrors,
                    errorDetails: response.errorDetails
                }));
            }
        })
        .catch((error: any) => {
            dispatch(setRegisterTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
        });
};

export const loginAction =
    (data: LoginRequest) => async (dispatch: any) => {
        dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Loading}));
        fetch(`${EndPoints.auth}/signin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((result) => {
                if (result && result.accessToken) {
                    if (result.role === UserDTO.RoleEnum.Admin) {
                        dispatch(setAuthenticationResult(result.accessToken));
                        dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Success}));
                        localStorage.setItem("token", result.accessToken)
                        localStorage.setItem("userId", result.id)
                    } else {
                        alert('You are not authorized to access this page');
                        dispatch(setLoginTaskStatus({
                            type: AsyncTaskStatusType.Error,
                            errorMessage: "You are not authorized to access this page"
                        }));
                    }
                } else {
                    const error = result as unknown as Error;
                    dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
                }
            })
            .catch((error) => {
                dispatch(setLoginTaskStatus({type: AsyncTaskStatusType.Error, errorMessage: error.message}));
            });
    };

export const logoutAction = () => async (dispatch: any) => {
    dispatch(setAuthenticationResult(null));
    localStorage.removeItem("token");
    localStorage.removeItem("userId")
};

export const {setAuthenticationResult, setLoginTaskStatus, setRegisterTaskStatus} = authSlice.actions;

export const loginTaskStatusSelector = (state: RootState) => state.auth.loginTaskStatus
export const registerTaskStatusSelector = (state: RootState) => state.auth.registerTaskStatus

export const authenticationResult = (state: any) => state.auth.authenticationResult;

export default authSlice.reducer;
