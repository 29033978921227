export const convertToLocalTime = (date: any) => {
    //@ts-ignore
    const event = new Date(Date(date));
    return event.toLocaleDateString('en-US');
};

export const fetchAuthToken = async () => {
    return localStorage.getItem("token");
}

export const isValidImageFile = (file: any) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"]
    return allowedTypes.includes(file.type) && file.size < 10000000 ? true : false
}
